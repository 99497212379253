/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Component } from "react";

import { withFirebase } from "../Firebase";

import DeleteIcon from "../../image/svg/dashboard/delete.svg";

import Dots from "../../image/svg/dashboard/dots.svg";
import DropDown from "./DropDown";

const ButtonDropDown = css`
  padding: 7.5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  transition: 0.2s;
  cursor: pointer;
  color: #383b3f;

  :hover {
    background: rgba(85, 153, 255, 0.3);
  }
`;

const IconDropDown = css`
  height: 1em;
  width: 1em;
  margin-right: 10px;
`;

class StoreCity extends Component {
  state = {
    click: this.props.checked,
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.checked !== nextState.click) {
      return {
        click: nextProps.checked,
      };
    } else return null;
  }

  handleClickCheck = () => {
    this.props.handleId(this.props.c.id);
    this.setState({
      click: !this.state.click,
    });
  };

  handleDeleteVideo = () => {
    this.props.firebase
      .videoStatRef()
      .doc(this.props.c.id)
      .delete()
      .then(() => {
        this.props.handleDelete(this.props.c.id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const { click } = this.state;
    const {
      c: { city, store },
      mobile,
    } = this.props;

    return (
      <li
        css={css`
          position: relative;
          display: flex;
          flex-direction: row;
          align-items: center;
        `}
      >
        <svg
          onClick={this.handleClickCheck}
          role="button"
          tabIndex={0}
          viewBox="0 0 16 16"
          fill="#47B2FF"
          xmlns="http://www.w3.org/2000/svg"
          css={css`
            border: 2px solid #383b3f;
            border-radius: 4px;
            margin: 0 1vw;
            height: 1em;
            width: 1em;
            background: ${click ? `#47b2ff` : `transparent`};
            box-sizing: border-box;
            display: flex;
            justify-content: center;
            align-items: center;
            content-visibility: ${click ? `visible` : `hidden`};
            transition: 0.1s;
          `}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10.9706 4.97028C11.1106 4.83155 11.2995 4.75348 11.4966 4.75293C11.6936 4.75239 11.883 4.82942 12.0237 4.96737C12.1644 5.10533 12.2452 5.29312 12.2485 5.49015C12.2519 5.68718 12.1776 5.87761 12.0416 6.02028L8.04965 11.0103C7.98104 11.0842 7.89823 11.1435 7.80618 11.1846C7.71413 11.2258 7.61472 11.248 7.5139 11.2499C7.41308 11.2517 7.31292 11.2332 7.2194 11.1955C7.12589 11.1578 7.04094 11.1016 6.96965 11.0303L4.32465 8.38428C4.25096 8.31562 4.19186 8.23282 4.15086 8.14082C4.10987 8.04882 4.08783 7.94951 4.08605 7.8488C4.08428 7.7481 4.1028 7.64807 4.14052 7.55468C4.17824 7.46129 4.23439 7.37646 4.30561 7.30524C4.37683 7.23402 4.46166 7.17788 4.55505 7.14016C4.64844 7.10244 4.74847 7.08391 4.84917 7.08569C4.94987 7.08747 5.04918 7.10951 5.14118 7.1505C5.23318 7.19149 5.31598 7.25059 5.38465 7.32428L7.47865 9.41728L10.9516 4.99228C10.9578 4.98453 10.9645 4.97719 10.9716 4.97028H10.9706Z"
            fill="white"
          />
        </svg>
        <span
          css={css`
            width: ${mobile ? `180px` : `200px`};
            margin-right: 1vw;
            text-align: center;
          `}
        >
          {store}
        </span>
        <span
          css={css`
            width: ${mobile ? `180px` : `200px`};
            margin-right: 1vw;
            text-align: center;
          `}
        >
          {city}
        </span>
        <div
          css={css`
            flex: 1;
          `}
        />

        <DropDown
          margin="0 calc(1vw + 15px) 0 0"
          list={
            <li
              role="button"
              tabIndex="0"
              onClick={this.handleDeleteVideo}
              css={css`
                ${ButtonDropDown};
                color: #ff3c3c;
                :hover {
                  background: rgba(255, 85, 85, 0.3);
                }
              `}
            >
              <img alt="Delete Icon" src={DeleteIcon} css={IconDropDown} />
              Delete
            </li>
          }
        >
          <img
            alt="More"
            src={Dots}
            css={css`
              height: 1em;
              width: 1em;
              opacity: 0.7;
            `}
          />
        </DropDown>
      </li>
    );
  }
}

const VideoStatCom = ({
  videoStat,
  checkAll,
  handleDelete,
  firebase,
  chooseVideoStatId,
  handleId,
  mobile,
}) => {
  return (
    <ul
      css={css`
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: ${mobile ? `100%` : `55vh`};
        overflow: overlay;
        font-size: ${mobile ? `0.9em` : `1em`};

        li {
          margin: 2vh 0;
        }

        li:first-of-type {
          margin: 0 0 2vh 0;
        }

        li:last-child {
          margin: 2vh 0 0 0;
        }

        &::-webkit-scrollbar-button {
          display: none;
        }
      `}
    >
      {videoStat.map((c, i) => (
        <StoreCity
          c={c}
          key={i}
          checkAll={checkAll}
          index={i}
          firebase={firebase}
          handleDelete={handleDelete}
          handleId={handleId}
          checked={chooseVideoStatId.indexOf(c.id) >= 0}
          mobile={mobile}
        />
      ))}
    </ul>
  );
};

export default withFirebase(VideoStatCom);
