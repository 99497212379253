/** @jsx jsx */
import { Fragment, useState, useEffect } from "react";
import { css, jsx } from "@emotion/core";
import Nav from "./Nav";

import { withFirebase } from "../Firebase";
import { Link } from "react-router-dom";
import Logo from "../../image/logo.webp";
import User from "../../image/svg/user.svg";
import DropDown from "../../components/Dashboard/DropDown";

import { useMediaQuery } from "react-responsive";

const ButtonLogOut = css`
  color: #fff;
  text-transform: uppercase;
  margin-bottom: 5vh;
  padding: 10px 0;
  width: 100%;
  transition: 0.3s;
  text-align: center;

  &:hover {
    padding: calc(10px + 10px) 0;
    margin-bottom: calc(5vh - 10px);
    margin-top: -10px;
  }

  &:active {
    padding: calc(10px + 5px) 0;
    margin-bottom: calc(5vh - 5px);
    margin-top: -5px;
  }
`;

const Button = css`
  color: #fff;
  text-transform: uppercase;
  padding: 10px 0;
  width: 100%;
  transition: 0.3s;
  text-align: center;
  margin: 2vh 0;
  position: relative;

  :before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(255, 255, 255, 0.2);
    top: 0;
    left: -100%;
    z-index: -1;
    transition: 0.5s;
  }

  :hover:before {
    left: 0;
  }

  :active:before {
    top: -10%;
    height: 120%;
  }
`;

const NavbarDashboard = (props) => {
  const [state, setState] = useState({
    open: false,
  });

  const { open } = state;

  var email = props.authUser.email;
  var regExpEmail = /^(.+)@(.+)$/;
  var matchEmail = email.match(regExpEmail);
  var name = matchEmail[1];
  var ext = `@${matchEmail[2]}`;
  // var nav = document.querySelector("#NavAdmin").style;

  var url = window.location.pathname;
  var regExpUrl = /\/admin\/(.+)\s*/;
  var matchUrl = url.match(regExpUrl);
  var checkedUrl = matchUrl[1];

  useEffect(() => {
    if (open === true) {
      // var prevScrollpos = window.pageYOffset;
      // nav.top = "0";

      window.onscroll = function () {
        _closeMenu();
        // if (!props.noAnimation) {
        //   var currentScrollPos = window.pageYOffset;
        //   if (prevScrollpos > currentScrollPos) {
        //     nav.transition = "all 0.5s, top 0.2s, width 0s";
        //     nav.top = "0";
        //   } else {
        //     nav.transition = "all 0.5s, top 0.8s, width 0s";
        //     nav.top = "-100%";
        //   }
        //   if (currentScrollPos > 100) {
        //     nav.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.15)";
        //   } else {
        //     nav.top = "0";
        //   }
        //   prevScrollpos = currentScrollPos;
        //   console.log(prevScrollpos, currentScrollPos);
        // }
      };
    }
  });

  const _closeMenu = () => {
    if (open === true) {
      setState({
        ...state,
        open: false,
      });
      document.removeEventListener("keydown", _escPress, false);
      document.removeEventListener("click", _closeMenu, false);
    }
  };

  const _handleClick = () => {
    setState({
      ...state,
      open: true,
    });
    document.addEventListener("keydown", _escPress, false);
    document.addEventListener("click", _closeMenu, false);
  };

  const _escPress = (e) => {
    if (e.keyCode === 27 && open === true) {
      setState({
        ...state,
        open: false,
      });
      document.removeEventListener("keydown", _escPress, false);
      document.removeEventListener("click", _closeMenu, false);
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter" && open === false) {
      setState({
        ...state,
        open: true,
      });
      document.addEventListener("keydown", _escPress, false);
      document.addEventListener("click", _closeMenu, false);
    }
  };

  const _handleKeyDownContent = (e) => {
    if (e.key === "Enter" && open === true) {
      setState({
        ...state,
        open: false,
      });
      document.removeEventListener("keydown", _escPress, false);
      document.removeEventListener("click", _closeMenu, false);
    }
  };

  const _handleClickContent = (e) => {
    if (open === true) {
      setState({
        ...state,
        open: false,
      });
      document.removeEventListener("keydown", _escPress, false);
      document.removeEventListener("click", _closeMenu, false);
    }
  };

  document.addEventListener("click", _closeMenu, false);
  document.addEventListener("keydown", _escPress, false);

  const Responsive = useMediaQuery({
    query: "(max-width: 1025px)",
  });

  const LinkAdmin = (
    <Fragment>
      <Link
        to="/admin/video"
        css={css`
          ${Button};
          ${checkedUrl === "video" && "border-left: 2px solid #fff;"}
        `}
        onKeyDown={_handleKeyDownContent}
        onClick={_handleClickContent}
      >
        Video
      </Link>
      <h2
        css={css`
          color: #fff;
          text-transform: uppercase;
          padding: 10px 0;
          width: 100%;
          transition: 0.3s;
          text-align: center;
          border-bottom: 2px solid #fff;
          margin-bottom: 2vh;
        `}
      >
        Test Site
      </h2>
      <Link
        to="/admin/test/video"
        css={css`
          ${Button};
        `}
        onKeyDown={_handleKeyDownContent}
        onClick={_handleClickContent}
      >
        Video
      </Link>
      <Link
        to="/admin/test/redeem"
        css={css`
          ${Button};
        `}
        onKeyDown={_handleKeyDownContent}
        onClick={_handleClickContent}
      >
        Redeem
      </Link>
    </Fragment>
  );

  const Profile = (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin-left: 10px;
      `}
    >
      <h1
        css={css`
          font-size: 1em;
        `}
      >
        {name}
      </h1>
      <span
        css={css`
          font-size: 0.7em;
        `}
      >
        {ext}
      </span>
    </div>
  );

  const LogOut = (
    <Fragment>
      <div
        css={css`
          flex: 1;
        `}
      />
      <button
        onClick={() => props.firebase.doSignOut()}
        css={css`
          ${ButtonLogOut};
          background: #f83e3e;
        `}
      >
        Logout
      </button>
    </Fragment>
  );

  const LogoTitle = (
    <div
      css={css`
        background: #5076b2;
        position: relative;
        top: 0;
        right: 0;
        display: flex;
        flex-direction: row;
        padding: 2vh 0;
        width: 100%;
        align-items: center;
      `}
    >
      <img
        src={Logo}
        alt="Logo Kurniasindo"
        css={css`
          height: 33px;
          margin-left: 10px;
        `}
      />
      <div
        css={css`
          margin-left: 10px;
        `}
      >
        <h1
          css={css`
            font-size: 1.1em;
            font-weight: 400;
          `}
        >
          KURNIASINDO
        </h1>
        <span
          css={css`
            font-size: 0.75em;
            font-weight: 400;
          `}
        >
          Knitting & Garment
        </span>
      </div>
    </div>
  );

  const Drawer = (
    <Nav open={open} onClick={_handleClick} left={true}>
      {LogoTitle}
      {LinkAdmin}
      {LogOut}
    </Nav>
  );

  const NavDrawer = (
    <Fragment>
      <div
        css={css`
          height: calc(2vh + 40px);
        `}
      />
      <section
        // id="NavAdmin"
        css={css`
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;

          width: 100vw;
          box-sizing: border-box;
          z-index: 99;
          background: #383b3f;
          padding: 1vh 0 1vh 20px;
          transition: all 0.5s, top 0.8s, width 0s;
          height: calc(2vh + 40px);
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
        `}
      >
        <svg
          onClick={_handleClick}
          onKeyDown={_handleKeyDown}
          focusable="false"
          className="navBars"
          role="button"
          alt="Pages"
          viewBox="0 0 448 512"
          xmlns="http://www.w3.org/2000/svg"
          tabIndex="1"
          css={css`
            height: 40px;
            color: #fff;
          `}
        >
          <path
            fill="currentColor"
            d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
          />
        </svg>
        <h1
          css={css`
            font-size: 25px;
            color: #fff;
            margin: 0 auto 0 20px;
            text-transform: capitalize;
          `}
        >
          {checkedUrl}
        </h1>
        <DropDown
          margin="0 20px 0 0"
          list={
            <Fragment>
              <div
                css={css`
                  margin: 1vh;
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                `}
              >
                <svg
                  aria-hidden="true"
                  focusable="false"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 496 512"
                  alt="Profile"
                  css={css`
                    height: 40px;
                    width: 40px;
                    border-radius: 25px;
                  `}
                >
                  <path
                    fill="rgba(0, 0, 0, 0.5)"
                    d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm0 96c48.6 0 88 39.4 88 88s-39.4 88-88 88-88-39.4-88-88 39.4-88 88-88zm0 344c-58.7 0-111.3-26.6-146.5-68.2 18.8-35.4 55.6-59.8 98.5-59.8 2.4 0 4.8.4 7.1 1.1 13 4.2 26.6 6.9 40.9 6.9 14.3 0 28-2.7 40.9-6.9 2.3-.7 4.7-1.1 7.1-1.1 42.9 0 79.7 24.4 98.5 59.8C359.3 421.4 306.7 448 248 448z"
                  ></path>
                </svg>
                {Profile}
              </div>
            </Fragment>
          }
        >
          <img
            src={User}
            alt="Profile"
            css={css`
              height: 40px;
              width: 40px;
              border-radius: 25px;
            `}
          />
        </DropDown>
      </section>
      {Drawer}
    </Fragment>
  );

  const compView = (
    <section
      css={css`
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        position: sticky;
        top: 0;
        bottom: 0;
        height: 100vh;
        min-width: 200px;
        width: 200px;
        background: #21395f;
        color: #fff;
      `}
    >
      {LogoTitle}
      <div
        css={css`
          padding: 10px;
          margin-top: 10px;
        `}
      >
        <span
          css={css`
            font-size: 0.8em;
          `}
        >
          Logged in as
        </span>
        <div
          css={css`
            margin-top: 1vh;
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <img
            src={User}
            alt="Profile"
            css={css`
              height: 40px;
              width: 40px;
              border-radius: 25px;
            `}
          />
          {Profile}
        </div>
      </div>

      {LinkAdmin}

      {LogOut}
    </section>
  );

  return !Responsive ? compView : NavDrawer;
};

export default withFirebase(NavbarDashboard);
