/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Component, Fragment } from "react";

import dayjs from "dayjs";

import Dots from "../../image/svg/dashboard/dots.svg";
import EnableIcon from "../../image/svg/dashboard/enable.svg";
import DisableIcon from "../../image/svg/dashboard/disable.svg";
import DeleteIcon from "../../image/svg/dashboard/delete.svg";
import ChecklistIcon from "../../image/svg/dashboard/checklist.svg";
import Logo from "../../image/LogoQR.webp";

import { withFirebase } from "../Firebase";

import DropDown from "./DropDown";
import QRCode from "qrcode.react";

const ButtonDropDown = css`
  padding: 7.5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  transition: 0.2s;
  cursor: pointer;
  color: #383b3f;

  :hover {
    background: rgba(85, 153, 255, 0.3);
  }
`;

const IconDropDown = css`
  height: 1em;
  width: 1em;
  margin-right: 10px;
`;

class Token extends Component {
  state = {
    click: this.props.checked,
    open: false,
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.checked !== nextState.click) {
      return {
        click: nextProps.checked,
      };
    } else return null;
  }

  closePopUp = () => {
    if (this.state.open === true) {
      this.setState({
        open: false,
      });
      document.removeEventListener("click", this.closePopUp);
      document.body.style.overflow = "overlay";
    }
  };

  handlePopUp = () => {
    if (this.state.open === false) {
      this.setState({
        open: true,
      });
      document.addEventListener("click", this.closePopUp);
      document.body.style.overflow = "hidden";
    }
  };

  handleClickCheck = () => {
    this.props.handleId(this.props.c.id);
    this.setState({
      click: !this.state.click,
    });
  };

  handleDeleteToken = () => {
    const {
      c: { disable, used },
    } = this.props;
    this.props.firebase
      .tokenRef()
      .doc(this.props.c.id)
      .delete()
      .then(() => {
        this.props.firebase
          .StatRef()
          .doc("Token")
          .get()
          .then((doc) => {
            if (disable) {
              doc.ref.update({
                size: doc.data().size - 1,
                disable: doc.data().disable - 1,
              });
            } else if (used) {
              doc.ref.update({
                size: doc.data().size - 1,
                used: doc.data().used - 1,
              });
            } else {
              doc.ref.update({
                size: doc.data().size - 1,
                avaliable: doc.data().avaliable - 1,
              });
            }
          });
      })
      .then(() => {
        this.props.handleDelete(this.props.c.id, disable, used);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleDisableToken = () => {
    this.props.firebase
      .tokenRef()
      .doc(this.props.c.id)
      .update({ disable: true })
      .then(() => {
        this.props.firebase
          .StatRef()
          .doc("Token")
          .get()
          .then((doc) => {
            doc.ref.update({
              avaliable: doc.data().avaliable - 1,
              disable: doc.data().disable + 1,
            });
          });
      })
      .then(() => {
        this.props.handleDisable(this.props.c.id);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleEnableToken = (a) => {
    this.props.firebase
      .tokenRef()
      .doc(this.props.c.id)
      .update({ disable: false, used: false })
      .then(() => {
        this.props.firebase
          .StatRef()
          .doc("VideoReq")
          .get()
          .then((doc) => {
            if (a) {
              doc.ref.update({
                avaliable: doc.data().avaliable + 1,
                used: doc.data().used - 1,
              });
            } else {
              doc.ref.update({
                avaliable: doc.data().avaliable + 1,
                disable: doc.data().disable - 1,
              });
            }
          });
      })
      .then(() => {
        this.props.handleEnable(this.props.c.id, a);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleDownloadQR = () => {
    const {
      c: { token },
    } = this.props;
    const canvas = document.getElementById(token);
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${token}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  render() {
    const { click, open } = this.state;
    const {
      c: { token, disable, used, CreatedAt },
      mobile,
    } = this.props;
    return (
      <Fragment>
        <li
          css={css`
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 15px;
          `}
        >
          <svg
            onClick={this.handleClickCheck}
            role="button"
            tabIndex={0}
            viewBox="0 0 16 16"
            fill="#47B2FF"
            xmlns="http://www.w3.org/2000/svg"
            css={css`
              border: 2px solid #383b3f;
              border-radius: 4px;
              margin: 0 1vw;
              height: 1em;
              width: 1em;
              background: ${click ? `#47b2ff` : `transparent`};
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              align-items: center;
              content-visibility: ${click ? `visible` : `hidden`};
              transition: 0.1s;
            `}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.9706 4.97028C11.1106 4.83155 11.2995 4.75348 11.4966 4.75293C11.6936 4.75239 11.883 4.82942 12.0237 4.96737C12.1644 5.10533 12.2452 5.29312 12.2485 5.49015C12.2519 5.68718 12.1776 5.87761 12.0416 6.02028L8.04965 11.0103C7.98104 11.0842 7.89823 11.1435 7.80618 11.1846C7.71413 11.2258 7.61472 11.248 7.5139 11.2499C7.41308 11.2517 7.31292 11.2332 7.2194 11.1955C7.12589 11.1578 7.04094 11.1016 6.96965 11.0303L4.32465 8.38428C4.25096 8.31562 4.19186 8.23282 4.15086 8.14082C4.10987 8.04882 4.08783 7.94951 4.08605 7.8488C4.08428 7.7481 4.1028 7.64807 4.14052 7.55468C4.17824 7.46129 4.23439 7.37646 4.30561 7.30524C4.37683 7.23402 4.46166 7.17788 4.55505 7.14016C4.64844 7.10244 4.74847 7.08391 4.84917 7.08569C4.94987 7.08747 5.04918 7.10951 5.14118 7.1505C5.23318 7.19149 5.31598 7.25059 5.38465 7.32428L7.47865 9.41728L10.9516 4.99228C10.9578 4.98453 10.9645 4.97719 10.9716 4.97028H10.9706Z"
              fill="white"
            />
          </svg>
          <span
            css={css`
              width: ${mobile ? `150px` : `100px`};
              margin-right: 1vw;
              text-align: center;
              text-transform: uppercase;
            `}
          >
            {token}
          </span>
          {!mobile && (
            <Fragment>
              <span
                css={css`
                  width: 120px;
                  margin-right: 1vw;
                  text-align: center;
                `}
              >
                {dayjs(CreatedAt).format("DD MMM YYYY")}
              </span>
            </Fragment>
          )}
          <div
            css={css`
              flex: 1;
            `}
          />
          <span
            css={css`
              width: ${mobile ? `150px` : `100px`};
              height: 1em;
              margin-right: 16px;
              text-align: center;
              color: #fff;
              padding: 4px 0;
              border-radius: 4px;
              text-transform: uppercase;
              font-size: 0.9em;
              font-weight: 600;
              transition: 0.5s;
              background: #${disable ? "878787" : used ? "FB4343" : "46D35C"};
            `}
          >
            {disable ? "disabled" : used ? "used" : "avaliable"}
          </span>
          <DropDown
            margin="0 calc(1vw + 15px) 0 0"
            list={
              <Fragment>
                {disable ? (
                  <li
                    role="button"
                    tabIndex="0"
                    onClick={() => this.handleEnableToken(false)}
                    css={css`
                      ${ButtonDropDown}
                    `}
                  >
                    <img
                      alt="Delete Icon"
                      src={EnableIcon}
                      css={IconDropDown}
                    />
                    Enable Token
                  </li>
                ) : used ? (
                  <li
                    role="button"
                    tabIndex="0"
                    onClick={() => this.handleEnableToken(true)}
                    css={css`
                      ${ButtonDropDown}
                    `}
                  >
                    <img
                      alt="Delete Icon"
                      src={ChecklistIcon}
                      css={IconDropDown}
                    />
                    Make Available
                  </li>
                ) : (
                  <Fragment>
                    <li
                      role="button"
                      tabIndex="0"
                      onClick={this.handleDisableToken}
                      css={css`
                        ${ButtonDropDown}
                      `}
                    >
                      <img
                        alt="Delete Icon"
                        src={DisableIcon}
                        css={IconDropDown}
                      />
                      Disable Token
                    </li>
                    <li
                      role="button"
                      tabIndex="0"
                      onClick={this.handleDownloadQR}
                      css={css`
                        ${ButtonDropDown}
                      `}
                    >
                      <QRCode
                        value={"Generate"}
                        size={"50"}
                        css={IconDropDown}
                        bgColor="transparent"
                        fgColor="#383b3f"
                      />
                      Generate QR Code
                    </li>
                    <div style={{ display: "none" }}>
                      <QRCode
                        id={token}
                        value={`https://kurniasindo.com/admin/test/redeem/${token}`}
                        size={290}
                        level={"H"}
                        includeMargin={true}
                        imageSettings={{
                          src: `${Logo}`,
                          excavate: true,
                          height: 70,
                          width: 70,
                        }}
                      />
                    </div>
                  </Fragment>
                )}
                {mobile && (
                  <li
                    role="button"
                    tabIndex="0"
                    css={ButtonDropDown}
                    onClick={this.handlePopUp}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      alt="More Info Icon"
                      role="img"
                      css={IconDropDown}
                    >
                      <path
                        d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125Z"
                        fill="#383b3f"
                      />
                      <path
                        d="M6.25 4.25C6.25 4.44891 6.32902 4.63968 6.46967 4.78033C6.61032 4.92098 6.80109 5 7 5C7.19891 5 7.38968 4.92098 7.53033 4.78033C7.67098 4.63968 7.75 4.44891 7.75 4.25C7.75 4.05109 7.67098 3.86032 7.53033 3.71967C7.38968 3.57902 7.19891 3.5 7 3.5C6.80109 3.5 6.61032 3.57902 6.46967 3.71967C6.32902 3.86032 6.25 4.05109 6.25 4.25ZM7.375 6H6.625C6.55625 6 6.5 6.05625 6.5 6.125V10.375C6.5 10.4437 6.55625 10.5 6.625 10.5H7.375C7.44375 10.5 7.5 10.4437 7.5 10.375V6.125C7.5 6.05625 7.44375 6 7.375 6Z"
                        fill="#383b3f"
                      />
                    </svg>
                    More Info
                  </li>
                )}
                <div
                  css={css`
                    width: 100%;
                    height: 2px;
                    background: #383b3f;
                    opacity: 0.5;
                  `}
                />
                <li
                  role="button"
                  tabIndex="0"
                  onClick={this.handleDeleteToken}
                  css={css`
                    ${ButtonDropDown};
                    color: #ff3c3c;
                    :hover {
                      background: rgba(255, 85, 85, 0.3);
                    }
                  `}
                >
                  <img alt="Delete Icon" src={DeleteIcon} css={IconDropDown} />
                  Delete Token
                </li>
              </Fragment>
            }
          >
            <img
              alt="More"
              src={Dots}
              css={css`
                height: 1em;
                width: 1em;
                opacity: 0.7;
              `}
            />
          </DropDown>
        </li>
        {open && mobile && (
          <div
            css={css`
              position: fixed;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(0, 0, 0, 0.25);
              z-index: 100;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            `}
          >
            <section
              css={css`
                background: #fff;
                box-shadow: 0px 1px 6px rgba(41, 46, 52, 0.25);
                border-radius: 8px;
                z-index: 101;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                padding: 20px;
                padding-right: 20vw;
                position: relative;

                h1 {
                  font-size: 0.9em;
                  color: #838383;
                  margin-bottom: 4px;
                }

                h2 {
                  font-size: 1.1em;
                  margin-bottom: 15px;
                  color: #383b3f;
                }

                h2:last-child {
                  margin-bottom: 0;
                }
              `}
            >
              <h1>Token</h1>
              <h2>{token}</h2>
              <h1>Date Created</h1>
              <h2>{dayjs(CreatedAt).format("DD MMM YYYY, HH:mm:ss")}</h2>
              <h1>Status</h1>
              <span
                css={css`
                  width: ${mobile ? `150px` : `100px`};
                  height: 1em;
                  margin-right: 1vw;
                  text-align: center;
                  color: #fff;
                  padding: 4px 0;
                  border-radius: 4px;
                  text-transform: uppercase;
                  font-size: 0.9em;
                  font-weight: 600;
                  transition: 0.5s;
                  background: #${disable ? "878787" : used ? "FB4343" : "46D35C"};
                `}
              >
                {disable ? "disabled" : used ? "used" : "avaliable"}
              </span>
            </section>
          </div>
        )}
      </Fragment>
    );
  }
}

const TokenList = ({
  tokens,
  handleDelete,
  firebase,
  handleDisable,
  handleEnable,
  handleId,
  chooseTokenId,
  mobile,
}) => {
  return (
    <ul
      css={css`
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: ${mobile ? `100%` : `20vh`};
        overflow-y: overlay;
        overflow-x: visible;
        z-index: 2;

        li {
          margin: 1vh 0;
        }

        li:first-of-type {
          margin: 0 0 1vh 0;
        }

        li:last-child {
          margin: 1vh 0 0 0;
        }

        &::-webkit-scrollbar-button {
          display: none;
        }
      `}
    >
      {tokens.map((c, i) => (
        <Token
          c={c}
          key={i}
          index={i}
          firebase={firebase}
          handleDelete={handleDelete}
          handleDisable={handleDisable}
          handleEnable={handleEnable}
          handleId={handleId}
          checked={chooseTokenId.indexOf(c.id) >= 0}
          mobile={mobile}
        />
      ))}
    </ul>
  );
};

export default withFirebase(TokenList);
