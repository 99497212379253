import React from "react";
import "./Footer.css";

import Logo from "../../image/logo.webp";

import { useMediaQuery } from "react-responsive";

const Footer = ({ visible }) => {
  const isComputer = useMediaQuery({
    query: "(min-width: 769px)",
  });

  // const socialInfo = <h4>social</h4>;

  const styleContact = {
    flexDirection: "column",
    margin: `${isComputer ? "3vh 0 0 5vw" : "3vh 0"}`,
  };

  return (
    visible && (
      <section className="ContainerFooter">
        <div className="contentFooter">
          <img src={Logo} alt="Logo Kurniasindo" className="logo logoFooter" />
          <div className="TitleFooterBox">
            <h1 className="TitleNav">CV.Kurnia Multi Kreasindo</h1>
            <span className="SubtitleNav">Knitting & Garment</span>
            <h2 className="Location">
              Jl. Terusan Jakarta Utara No.11 <br />
              Bandung, Jawa Barat, 40282
            </h2>
          </div>
          <div style={styleContact}>
            <h2 className="phone">+62 22 7215018</h2>
            <h2 className="email">
              <span>cvkurniamultikreasindo</span>
              <span>@gmail.com</span>
            </h2>
          </div>
          {/* {!isComputer && <div>{socialInfo}</div>} */}
        </div>
        <div className="copyright">
          Copyright © 2020. CV. Kurnia Multi Kreasindo. All Rights Reserved.
        </div>
        {/* {isComputer && <div className="social">{socialInfo}</div>} */}
      </section>
    )
  );
};

export default Footer;
