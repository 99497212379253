export const SET_AUTHENTICATED = "SET_AUTHENTICATED";
export const SET_UNAUTHENTICATED = "SET_UNAUTHENTICATED";

export const SET_ERRORS = "SET_ERRORS";
export const LOADING_UI = "LOADING_UI";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const LOADING_DATA = "LOADING_DATA";
export const STOP_LOADING_UI = "STOP_LOADING_UI";

export const SET_JOBS = "SET_JOBS";
export const SET_JOB = "SET_JOB";
export const ADD_JOB = "ADD_JOB";
export const DELETE_JOB = "DELETE_JOB";
export const UPDATE_JOB = "UPDATE_JOB";

export const SET_APPLY_JOB = "SET_APPLY_JOB";
export const SET_APPLY_FORM = "SET_APPLY_FORM";

export const SET_NAVBAR = "SET_NAVBAR";
export const RESET_NAVBAR = "SET_NAVBAR";

export const SET_FOOTER = "SET_FOOTER";

//DB
export const FIRST_LOAD = "FIRST_LOAD";

export const LOADING_STAT = "LOADING_STAT";
export const LOADING_VID_REQ = "LOADING_VID_REQ";
export const LOADING_VID = "LOADING_VID";
export const LOADING_TOKEN = "LOADING_TOKEN";
export const LOADING_VIDEO_STAT = "LOADING_VIDEO_STAT";

export const FETCH_STAT_VID_REQ = "FETCH_STAT_VID_REQ";
export const FETCH_STAT_VID = "FETCH_STAT_VID";
export const FETCH_STAT_TOKEN = "FETCH_STAT_TOKEN";
export const FETCH_VID_REQ = "FETCH_VID_REQ";
export const FETCH_VID = "FETCH_VID";
export const FETCH_TOKEN = "FETCH_TOKEN";
export const FETCH_VIDEO_STAT = "FETCH_VIDEO_STAT";

export const UPDATE_STATE = "UPDATE_STATE";

export const SET_TOKEN_ID = "SET_TOKEN_ID";
export const SET_VID_ID = "SET_VID_ID";
export const SET_VID_STAT_ID = "SET_VID_STAT_ID";
