/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import React from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import { connect } from "react-redux";
import { setFooter, setNavbar } from "../../redux/actions/dataActions";

import AuthUserContext from "./context";
import { withFirebase } from "../Firebase";

const withAuthorization = (condition) => (Component) => {
  class WithAuthorization extends React.Component {
    componentDidMount() {
      this.props.setNavbar({
        hidden: true,
      });
      this.props.setFooter(false);
      this.listener = this.props.firebase.auth.onAuthStateChanged(
        (authUser) => {
          if (!condition(authUser)) {
            this.props.history.push("/admin/login");
          }
        }
      );
    }

    componentWillUnmount() {
      this.listener();
    }

    render() {
      return (
        <AuthUserContext.Consumer>
          {(authUser) =>
            condition(authUser) ? (
              <Component {...this.props} authUser={authUser} />
            ) : (
              <section
                css={css`
                  min-height: 100vh;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                Loading...
              </section>
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return compose(
    connect(null, { setFooter, setNavbar }),
    withRouter,
    withFirebase
  )(WithAuthorization);
};

export default withAuthorization;
