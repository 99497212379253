/** @jsx jsx */
import PropTypes from "prop-types";
import { css, jsx } from "@emotion/core";

import Section from "../../components/Dashboard/Section";
import WhiteDoc from "../../components/Dashboard/WhiteDoc";

import { connect } from "react-redux";

import { FlexRow, FlexColumn } from "./essentials";
import { useMediaQuery } from "react-responsive";
import { Fragment } from "react";

const TokenWDs = ({
  Responsive,
  mobile,
  tokenAvaliable,
  tokenAll,
  tokenUsed,
}) => {
  return (
    <Fragment>
      <WhiteDoc
        title={tokenAll}
        subtitle="All Tokens"
        width={mobile ? undefined : "100%"}
        margin={mobile ? "0 0 1vh 0" : !Responsive ? "0 1vw 0 0" : "0 2vw 0 0"}
        height={mobile ? undefined : "auto"}
        noContent={!mobile}
        mobile={mobile}
      />
      <WhiteDoc
        bgColor="#56CE66"
        color="#fff"
        title={tokenAvaliable}
        subtitle="Tokens Available"
        width={mobile ? undefined : "100%"}
        margin={mobile ? "0 0 1vh 0" : !Responsive ? "0 1vw 0 0" : "0 2vw 0 0"}
        height={mobile ? undefined : "auto"}
        noContent={!mobile}
        mobile={mobile}
      />
      <WhiteDoc
        bgColor="#FA4368"
        color="#fff"
        title={tokenUsed}
        subtitle="Tokens Used"
        width={mobile ? undefined : "100%"}
        margin={mobile ? "0 0 1vh 0" : !Responsive ? "0 1vw 0 0" : "0"}
        height={mobile ? undefined : "auto"}
        noContent={!mobile}
        mobile={mobile}
      />
    </Fragment>
  );
};

const TokenStats = ({
  db: { tokenAll, tokenAvaliable, tokenUsed, tokenDisabled },
  mobile,
}) => {
  const Responsive = useMediaQuery({
    query: "(max-width: 1440px)",
  });

  const WD = (
    <TokenWDs
      Responsive={Responsive}
      mobile={mobile}
      tokenAvaliable={tokenAvaliable}
      tokenAll={tokenAll}
      tokenUsed={tokenUsed}
    />
  );

  return (
    <Section
      title="Token Stats"
      style={
        mobile ? { margin: "0 10px 4vh 5px", width: "initial" } : undefined
      }
    >
      {/* <button>Today</button>
        <button>Week</button>
        <button>Month</button>
        <button>All</button> */}

      <div
        css={css`
          ${mobile ? FlexColumn : !Responsive ? FlexRow : FlexColumn};
          margin-top: 2vh;
        `}
      >
        {!mobile || !Responsive ? (
          <div
            css={css`
              ${FlexRow};
              margin-bottom: 2vh;
            `}
          >
            {WD}
          </div>
        ) : (
          WD
        )}
        <WhiteDoc
          bgColor="#878787"
          color="#fff"
          title={tokenDisabled}
          subtitle="Tokens Disabled"
          height={mobile ? undefined : "100%"}
          margin="0"
          noContent={!mobile}
          mobile={mobile}
        />
      </div>
    </Section>
  );
};

TokenStats.propTypes = {
  db: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  db: state.db,
});

export default connect(mapStateToProps)(TokenStats);
