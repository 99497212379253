/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import PropTypes from "prop-types";

import Section from "../../components/Dashboard/Section";
import WhiteDoc from "../../components/Dashboard/WhiteDoc";
import Percentage from "../../components/Dashboard/Percentage";

import { connect } from "react-redux";

const VideoStatsWD = ({
  db: { newVideo, videoReqUnreview, videoReqAccepted, videoReqDecline },
  mobile,
  mobileClick,
}) => {
  return (
    <WhiteDoc
      title="Video Acceptance Statistics"
      subtitle="Overall progress of video reviewing status"
      mobile={mobile}
      mobileClick={mobileClick}
    >
      <h3
        css={css`
          font-size: 2em;
        `}
      >
        {newVideo}
      </h3>
      <span>Total New Videos</span>

      <Percentage
        number={videoReqUnreview}
        desc="Unreviewed"
        total={newVideo}
        color="#ffc700"
      />
      <Percentage
        number={videoReqAccepted}
        desc="Accepted Today"
        total={newVideo}
      />
      <Percentage
        number={videoReqDecline}
        desc="Declined Today"
        total={newVideo}
        color="#EB4F4F"
      />
    </WhiteDoc>
  );
};

const VideoStats = ({ db, mobile, mobileClick }) => {
  return !mobile ? (
    <Section title="Video Acceptance Statistics" style={{ width: "320px" }}>
      <VideoStatsWD db={db} mobile={mobile} mobileClick={mobileClick} />
    </Section>
  ) : (
    <VideoStatsWD db={db} mobile={mobile} mobileClick={mobileClick} />
  );
};

VideoStats.propTypes = {
  db: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  db: state.db,
});

export default connect(mapStateToProps)(VideoStats);
