/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Fragment } from "react";

/**
 * @function NumberedTitle
 */

const NumberedTitle = ({
  items,
  unClickable,
  handleReqChange,
  currentIndex,
  margin,
  padding,
}) => {
  return (
    <ol
      css={css`
        counter-reset: item;
        list-style-type: none;
        font-size: 1.5em;
        padding: ${padding ? padding : "0 5vw 0 40px"};
        margin: ${margin ? margin : "0"};
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      `}
    >
      {items.map((c, i, a) => (
        <List
          key={i}
          index={i}
          length={a.length}
          currentIndex={currentIndex}
          handleReqChange={handleReqChange}
          unClickable={unClickable}
          {...c}
        />
      ))}
    </ol>
  );
};

const List = ({
  title,
  desc,
  index,
  length,
  currentIndex,
  handleReqChange,
  unClickable,
}) => {
  return (
    <Fragment>
      <li
        role={unClickable ? "" : "button"}
        tabIndex={unClickable ? "" : "0"}
        aria-label={title}
        onClick={
          !unClickable ? () => handleReqChange(index + 1) : function () {}
        }
        css={css`
          display: flex;
          align-items: center;
          margin: 10px 0;
          color: #383b3f;
          opacity: ${!unClickable
            ? index <= currentIndex - 1
              ? "1"
              : "0.8"
            : "1"};

          :before {
            content: counter(item);
            counter-increment: item;
            display: flex;
            background-color: ${index <= currentIndex - 1
              ? "#0074e0"
              : "transparent"};
            color: ${index <= currentIndex - 1 ? "#fff" : "#383b3f"};
            border: 2px solid
              ${index <= currentIndex - 1 ? "transparent" : "#383b3f"};
            opacity: ${!unClickable
              ? index <= currentIndex - 1
                ? "1"
                : "0.8"
              : "1"};
            transition: 0.2s;
            align-items: center;
            justify-content: center;
            margin-right: 12px;
            border-radius: 50%;
            height: 1.5em !important;
            width: 1.5em !important;
          }
        `}
      >
        {title}
      </li>
      <div
        css={css`
          display: flex;
          flex-direction: row;
        `}
      >
        {index + 1 !== length && (
          <div
            css={css`
              width: 2px !important;
              height: ${!desc ? "40px" : "auto"};
              background: ${index <= currentIndex - 2 ? "#0074e0" : "#383b3f"};
              opacity: ${index <= currentIndex - 2 ? "1" : "0.5"};
              position: relative;
              left: calc(1.5em / 2);
            `}
          />
        )}
        {desc && (
          <p
            css={css`
              position: relative;
              font-size: 0.7em;
              margin: 0;
              width: 80%;
              left: calc(1.5em + 25px);
              margin-bottom: 20px;
            `}
          >
            {desc}
          </p>
        )}
      </div>
    </Fragment>
  );
};

export default NumberedTitle;
