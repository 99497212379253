/** @jsx jsx */
import { useState } from "react";
import { css, jsx } from "@emotion/core";
import { Fragment } from "react";

import { FlexRow, FlexColumn } from "../../pages/dashboard/essentials";

const WhiteDoc = ({
  children,
  color,
  title,
  subtitle,
  rightside,
  bgColor,
  margin,
  style,
  styleContent,
  direction,
  minWidth,
  maxWidth,
  width,
  height,
  mobile,
  mobileClick,
  noContent,
}) => {
  const [state, setState] = useState({
    open: false,
  });

  const { open } = state;

  const _closeMenu = () => {
    if (open === true) {
      setState({
        ...state,
        open: false,
      });
      document.body.style.overflow = "overlay";
    }
  };

  const _handleClick = () => {
    setState({
      ...state,
      open: true,
    });
    document.body.style.overflow = "hidden";
  };

  const _handleKeyDownOpen = (e) => {
    if (e.key === "Enter" && open === false) {
      setState({
        ...state,
        open: true,
      });
      document.body.style.overflow = "hidden";
    }
  };

  const _handleKeyDownClose = (e) => {
    if (e.key === "Enter" && open === true) {
      setState({
        ...state,
        open: false,
      });
      document.body.style.overflow = "overlay";
    }
  };

  const FontCss = css`
    h2 {
      font-size: ${mobile ? `1.35em` : `1.5em`};
    }

    span {
      font-size: ${mobile ? `0.65em` : `0.8em`};
    }
  `;

  return (
    <Fragment>
      <div
        style={style}
        onClick={mobileClick ? _handleClick : undefined}
        onKeyDown={mobileClick ? _handleKeyDownOpen : undefined}
        tabIndex={mobileClick ? `0` : undefined}
        css={css`
          ${FlexColumn};
          bottom: 0;
          height: ${height ? height : `calc(100% - 1.5em - 6vh)`};
          background: ${bgColor ? bgColor : "#fff"};
          color: ${color ? color : "#383b3f"};
          padding: ${mobile ? `2vh` : `2vh 1vw`};
          box-shadow: 0px 1px 6px rgba(41, 46, 52, 0.25);
          border-radius: ${mobile ? "5px" : "10px"};
          text-align: ${title !== undefined && children === undefined
            ? `center`
            : mobileClick
            ? `left`
            : `inherit`};
          align-items: ${title !== undefined && children === undefined
            ? `center`
            : mobileClick
            ? `initial`
            : `inherit`};
          justify-content: ${title !== undefined && children === undefined
            ? `center`
            : mobileClick
            ? `initial`
            : `inherit`};
          margin: ${margin ? margin : mobile ? "0 10px 4vh 5px" : "0"};
          z-index: 1;
          min-width: ${mobile ? "calc(100vw - 5vh - 15px)" : minWidth};
          width: ${mobile ? "calc(100vw - 5vh - 15px)" : width};
          max-width: ${maxWidth};
        `}
      >
        {title !== undefined &&
          (mobileClick ? (
            <div
              css={css`
                ${FlexRow};
                align-items: center;
              `}
            >
              <div css={FontCss}>
                <h2>{title}</h2>
                <span>{subtitle}</span>
              </div>
              <div
                css={css`
                  flex: 1;
                `}
              />
              <svg
                width="20"
                height="20"
                viewBox="0 0 11 16"
                xmlns="http://www.w3.org/2000/svg"
                role="img"
                alt="Go to Details"
                onClick={mobileClick ? _handleClick : undefined}
                onKeyDown={mobileClick ? _handleKeyDownOpen : undefined}
              >
                <path
                  d="M10.2513 7.99858L2.82606 15.4238L0.351562 12.9476L5.30231 7.99858L0.351562 3.04958L2.82606 0.573328L10.2513 7.99858Z"
                  fill="#383b3f"
                />
              </svg>
            </div>
          ) : children !== undefined ? (
            rightside !== undefined ? (
              <div
                css={css`
                  ${FlexRow};
                  align-items: center;
                  margin-bottom: ${mobileClick ? `0` : `2vh`};
                `}
              >
                <div
                  css={css`
                    ${FontCss};
                    margin-right: 1vw;
                  `}
                >
                  <h2>{title}</h2>
                  <span>{subtitle}</span>
                </div>
                <div
                  css={css`
                    flex: 1;
                  `}
                />
                {rightside}
              </div>
            ) : (
              <div
                css={css`
                  ${FontCss};
                  margin-bottom: ${mobileClick ? `0` : `2vh`};
                `}
              >
                <h2>{title}</h2>
                <span>{subtitle}</span>
              </div>
            )
          ) : (
            <Fragment>
              <h2>{title}</h2>
              <span>{subtitle}</span>
            </Fragment>
          ))}

        {!mobileClick && !noContent ? (
          <div
            style={styleContent}
            css={css`
              display: flex;
              justify-content: center;
              flex-direction: ${direction ? direction : "column"};
              text-align: center;
              height: 100%;
            `}
          >
            {children && children}
          </div>
        ) : undefined}
      </div>
      {mobileClick && open ? (
        <section
          css={css`
            position: fixed;
            top: calc(2vh + 40px);
            bottom: 0;
            background: #fff;
            z-index: 2;
            padding: 2vh 2vw;
            height: calc(94vh - 40px);
            width: 96vw;
          `}
        >
          <div
            css={css`
              ${FlexRow};
              align-items: center;
              margin-bottom: 2vh;
            `}
          >
            <svg
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              onClick={_closeMenu}
              onKeyDown={_handleKeyDownClose}
              tabIndex="0"
              css={css`
                height: 20px;
                width: 20px;
                margin: 0 20px 0 10px;
              `}
            >
              <path
                d="M15.0004 7.00065H3.83041L8.71041 2.12065C9.10041 1.73065 9.10041 1.09065 8.71041 0.700647C8.6179 0.607943 8.50801 0.534396 8.38704 0.484214C8.26606 0.434033 8.13638 0.408203 8.00541 0.408203C7.87444 0.408203 7.74476 0.434033 7.62379 0.484214C7.50282 0.534396 7.39293 0.607943 7.30041 0.700647L0.710413 7.29065C0.617709 7.38316 0.544162 7.49305 0.49398 7.61402C0.443799 7.735 0.417969 7.86468 0.417969 7.99565C0.417969 8.12662 0.443799 8.2563 0.49398 8.37727C0.544162 8.49824 0.617709 8.60813 0.710413 8.70065L7.30041 15.2906C7.39299 15.3832 7.50291 15.4567 7.62387 15.5068C7.74483 15.5569 7.87448 15.5827 8.00541 15.5827C8.13634 15.5827 8.26599 15.5569 8.38696 15.5068C8.50792 15.4567 8.61783 15.3832 8.71041 15.2906C8.80299 15.1981 8.87643 15.0882 8.92654 14.9672C8.97664 14.8462 9.00243 14.7166 9.00243 14.5856C9.00243 14.4547 8.97664 14.3251 8.92654 14.2041C8.87643 14.0831 8.80299 13.9732 8.71041 13.8806L3.83041 9.00065H15.0004C15.5504 9.00065 16.0004 8.55065 16.0004 8.00065C16.0004 7.45065 15.5504 7.00065 15.0004 7.00065Z"
                fill="#383b3f"
              />
            </svg>
            {rightside !== undefined ? (
              <div
                css={css`
                  ${FlexRow};
                  align-items: center;
                  margin-bottom: ${mobileClick ? `0` : `2vh`};
                `}
              >
                <div
                  css={css`
                    ${FontCss};
                    margin-right: 2vw;
                  `}
                >
                  <h2>{title}</h2>
                  <span>{subtitle}</span>
                </div>
                <div
                  css={css`
                    flex: 1;
                  `}
                />
                {rightside}
              </div>
            ) : (
              <div css={FontCss}>
                <h2>{title}</h2>
                <span>{subtitle}</span>
              </div>
            )}
          </div>

          <div
            style={styleContent}
            css={css`
              display: flex;
              justify-content: center;
              flex-direction: ${direction ? direction : "column"};
              text-align: center;
              height: calc(91vh - 40px - 2.15em);
            `}
          >
            {children && children}
          </div>
        </section>
      ) : undefined}
    </Fragment>
  );
};

export default WhiteDoc;
