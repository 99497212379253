/** @jsx jsx */
import { useState, useEffect, lazy, Fragment } from "react";
import { css, jsx } from "@emotion/core";
import { useMediaQuery } from "react-responsive";

const Arrow = lazy(() => import("./Arrow"));
const Dots = lazy(() => import("./Dots"));

/**
 * @function Slider
 */

const Slider = ({ images, handleCurrentIndex }) => {
  const isComputer = useMediaQuery({
    query: "(min-device-width: 1024px)",
  });
  const isHp = useMediaQuery({
    query: "(min-device-width: 769px)",
  });

  const [state, setState] = useState({
    nextIndex: 0,
    currentIndex: 0,
    transition: false,
    transitionL: false,
    clicked: false,
  });

  const { currentIndex, nextIndex, transition, transitionL, clicked } = state;

  const time = 400 + images.length * 100;
  const transtime = 400 + images.length * 50;

  useEffect(() => {
    if (images.length !== 1) {
      const timer = setInterval(() => {
        if (!clicked) HandleNextSlide();
      }, 10000);
      return () => clearInterval(timer);
    }
  });

  const HandleEnterSlide = (e, index) => {
    if (e.key === "Enter") HandleSlide(index);
  };

  const HandleSlide = (Index) => {
    if (!transition && !transitionL && Index !== currentIndex) {
      if (currentIndex > Index) {
        setState({
          ...state,
          nextIndex: Index,
          transitionL: true,
          clicked: true,
        });
        handleCurrentIndex(currentIndex, Index);
        setTimeout(function () {
          setState({
            ...state,
            nextIndex: Index,
            currentIndex: Index,
            transitionL: false,
            clicked: false,
          });
        }, time);
      } else {
        setState({
          ...state,
          nextIndex: Index,
          transition: true,
          clicked: true,
        });
        handleCurrentIndex(currentIndex, Index);
        setTimeout(function () {
          setState({
            ...state,
            nextIndex: Index,
            currentIndex: Index,
            transition: false,
            clicked: false,
          });
        }, time);
      }
    }
  };

  const HandleEnterPrev = (e) => {
    if (e.key === "Enter" || e.key === "ArrowLeft") HandlePrevSlide();
  };

  const HandlePrevSlide = () => {
    if (currentIndex === 0) {
      HandleSlide(images.length - 1);
    } else {
      HandleSlide(currentIndex - 1);
    }
  };

  const HandleEnterNext = (e) => {
    if (e.key === "Enter" || e.key === "ArrowRight") HandleNextSlide();
  };

  const HandleNextSlide = () => {
    if (currentIndex === images.length - 1) {
      HandleSlide(0);
    } else {
      HandleSlide(currentIndex + 1);
    }
  };

  return (
    <div
      css={css`
        position: relative;
        ${isComputer
          ? `
            height: 35vw !important;
            min-height: 35vw !important;
            width: 30vw !important;
            min-width: 30vw !important;
        `
          : isHp
          ? `
          height: 60vw !important;
          min-height: 60vw !important;
          width: 35vw !important;
          min-width: 35vw !important;
          `
          : `
            height: 60vw !important;
            min-height: 60vw !important;
            width: 75vw !important;
            min-width: 75vw !important;
        `}
        margin: 5vh 5vw 10vh 0;
        display: flex;
        flex-direction: ${transitionL
          ? `${transition ? "row" : "row-reverse"}`
          : "row"};
        overflow: hidden;
      `}
    >
      {images.length !== 1 && (
        <Fragment>
          <Arrow
            direction="left"
            handleClick={HandlePrevSlide}
            handleKeyDown={HandleEnterPrev}
            aria-label="Previous news"
          />
          <Arrow
            direction="right"
            handleClick={HandleNextSlide}
            handleKeyDown={HandleEnterNext}
            aria-label="Next news"
          />
          <Dots
            slides={images}
            activeIndex={nextIndex}
            HandleClickDots={HandleSlide}
            HandleEnterDots={HandleEnterSlide}
            time={transtime}
          />
        </Fragment>
      )}
      <img
        src={images[currentIndex].image}
        alt={images[currentIndex].title}
        css={css`
          min-height: inherit;
          min-width: inherit;
          object-fit: cover;
          object-position: center;
          transition ${
            transition || transitionL ? `${transtime / 1000}` : "0"
          }s;
          transition-timing-function: ease-out;
          position: relative;
          left: ${transition ? "-100%" : `${transitionL ? "100%" : "0"}`};
        `}
      />
      <img
        src={images[nextIndex].image}
        alt={images[nextIndex].title}
        css={css`
        min-height: inherit;
          min-width: inherit;
          object-fit: cover;
          object-position: center;
          transition ${
            transition || transitionL ? `${transtime / 1000}` : "0"
          }s;
          transition-timing-function: ease-out;
          position: relative;
          left: ${transition ? "-100%" : `${transitionL ? "100%" : "0"}`};
        `}
      />
    </div>
  );
};

export default Slider;
