/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Component, Fragment } from "react";

import dayjs from "dayjs";

import { withFirebase } from "../Firebase";

import YoutubeIcon from "../../image/svg/dashboard/youtube.svg";
import DeclineIcon from "../../image/svg/dashboard/decline.svg";
import EnableIcon from "../../image/svg/dashboard/checklist.svg";
import DeleteIcon from "../../image/svg/dashboard/delete.svg";
import linkIcon from "../../image/svg/dashboard/link.svg";

import Dots from "../../image/svg/dashboard/dots.svg";
import DropDown from "./DropDown";

const ButtonDropDown = css`
  padding: 7.5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px 0;
  transition: 0.2s;
  cursor: pointer;
  color: #383b3f;

  :hover {
    background: rgba(85, 153, 255, 0.3);
  }
`;

const IconDropDown = css`
  height: 1em;
  width: 1em;
  margin-right: 10px;
`;

class Video extends Component {
  state = {
    click: this.props.checked,
    open: false,
  };

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.checked !== nextState.click) {
      return {
        click: nextProps.checked,
      };
    } else return null;
  }

  closePopUp = () => {
    if (this.state.open === true) {
      this.setState({
        open: false,
      });
      document.removeEventListener("click", this.closePopUp);
      document.body.style.overflow = "overlay";
    }
  };

  handlePopUp = () => {
    if (this.state.open === false) {
      this.setState({
        open: true,
      });
      document.addEventListener("click", this.closePopUp);
      document.body.style.overflow = "hidden";
    }
  };

  handleClickCheck = () => {
    this.props.handleId(this.props.c.id);
    this.setState({
      click: !this.state.click,
    });
  };

  handleDeleteVideo = () => {
    this.props.firebase
      .videoRef()
      .doc(this.props.c.id)
      .delete()
      .then(() => {
        this.props.firebase
          .StatRef()
          .doc("Video")
          .get()
          .then((doc) => {
            if (this.props.distributor) {
              doc.ref.update({
                distributor: doc.data().distributor - 1,
              });
            }
            if (this.props.aprove) {
              doc.ref.update({
                size: doc.data().size - 1,
                accepted: doc.data().accepted - 1,
              });
            } else {
              doc.ref.update({
                size: doc.data().size - 1,
                decline: doc.data().decline - 1,
              });
            }
          });
      })
      .then(() => {
        this.props.handleDelete(
          this.props.c.id,
          this.props.aprove,
          this.props.distributor
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleStatusVideo = (a) => {
    const Time = new Date().toISOString();
    this.props.firebase
      .videoRef()
      .doc(this.props.c.id)
      .update({ aprove: a, CreatedAt: Time })
      .then(() => {
        this.props.firebase
          .StatRef()
          .doc("Video")
          .get()
          .then((doc) => {
            if (a) {
              doc.ref.update({
                accepted: doc.data().accepted + 1,
                decline: doc.data().decline - 1,
              });
            } else {
              doc.ref.update({
                accepted: doc.data().accepted - 1,
                decline: doc.data().decline + 1,
              });
            }
          });
      })
      .then(() => {
        this.props.handleStatus(this.props.c.id, a, Time);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleChangeDis = (a) => {
    this.props.firebase
      .videoRef()
      .doc(this.props.c.id)
      .update({ distributor: a })
      .then(() => {
        this.props.firebase
          .StatRef()
          .doc("Video")
          .get()
          .then((doc) => {
            if (a) {
              doc.ref.update({
                distributor: doc.data().distributor + 1,
              });
            } else {
              doc.ref.update({
                distributor: doc.data().distributor - 1,
              });
            }
          });
      })
      .then(() => {
        this.props.handleDis(this.props.c.id, a);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const { click, open } = this.state;
    const {
      c: { title, token, videoId, aprove, CreatedAt, distributor },
      mobile,
    } = this.props;

    return (
      <Fragment>
        <li
          css={css`
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
          `}
        >
          <svg
            onClick={this.handleClickCheck}
            role="button"
            tabIndex={0}
            viewBox="0 0 16 16"
            fill="#47B2FF"
            xmlns="http://www.w3.org/2000/svg"
            css={css`
              border: 2px solid #383b3f;
              border-radius: 4px;
              margin: 0 1vw;
              height: 1em;
              width: 1em;
              background: ${click ? `#47b2ff` : `transparent`};
              box-sizing: border-box;
              display: flex;
              justify-content: center;
              align-items: center;
              content-visibility: ${click ? `visible` : `hidden`};
              transition: 0.1s;
            `}
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.9706 4.97028C11.1106 4.83155 11.2995 4.75348 11.4966 4.75293C11.6936 4.75239 11.883 4.82942 12.0237 4.96737C12.1644 5.10533 12.2452 5.29312 12.2485 5.49015C12.2519 5.68718 12.1776 5.87761 12.0416 6.02028L8.04965 11.0103C7.98104 11.0842 7.89823 11.1435 7.80618 11.1846C7.71413 11.2258 7.61472 11.248 7.5139 11.2499C7.41308 11.2517 7.31292 11.2332 7.2194 11.1955C7.12589 11.1578 7.04094 11.1016 6.96965 11.0303L4.32465 8.38428C4.25096 8.31562 4.19186 8.23282 4.15086 8.14082C4.10987 8.04882 4.08783 7.94951 4.08605 7.8488C4.08428 7.7481 4.1028 7.64807 4.14052 7.55468C4.17824 7.46129 4.23439 7.37646 4.30561 7.30524C4.37683 7.23402 4.46166 7.17788 4.55505 7.14016C4.64844 7.10244 4.74847 7.08391 4.84917 7.08569C4.94987 7.08747 5.04918 7.10951 5.14118 7.1505C5.23318 7.19149 5.31598 7.25059 5.38465 7.32428L7.47865 9.41728L10.9516 4.99228C10.9578 4.98453 10.9645 4.97719 10.9716 4.97028H10.9706Z"
              fill="white"
            />
          </svg>
          <div
            role="button"
            tabIndex="0"
            onClick={() => {
              window.open(`https://www.youtube.com/watch?v=${videoId}`);
            }}
            css={css`
              display: flex;
              justify-content: center;
              align-items: center;
              margin-bottom: 8px;
              width: ${mobile ? `100` : `160`}px;
              height: ${mobile ? `56.25` : `90`}px;
              background: url("https://img.youtube.com/vi/${videoId}/mqdefault.jpg");
              background-blend-mode: color;
              background-size: cover;
              background-repeat: no-repeat;
              transition: 0.2s;

              span {
                display: flex;
                align-items: center;
                font-size: 0.8em;
                color: #fff;
                opacity: 0;
              }

              &:hover {
                background-color: rgba(0, 0, 0, 0.75);
                cursor: pointer;
                span {
                  opacity: 1;
                }
              }
            `}
          >
            <span>
              <img src={linkIcon} alt="decline" />
              {videoId}
            </span>
          </div>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              margin: 0 1vw;
              height: ${mobile ? `56.25` : `90`}px;
              flex: 1;

              ${mobile &&
              `h3 {
                font-weight: 600;
                margin-bottom: 5px;
                font-size: 1em;
              }`}

              span {
                font-size: ${mobile ? `0.8` : `0.9`}em;
              }
            `}
          >
            <h3>{title}</h3>

            <span>
              {aprove ? "Accepted at" : "Declined at"}{" "}
              {dayjs(CreatedAt).format(
                mobile ? "DD MMM YYYY, HH:mm" : "DD MMM YYYY, HH:mm:ss"
              )}{" "}
              {distributor && (
                <svg
                  viewBox="0 0 15 14.5"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  css={css`
                    height: ${mobile ? `0.8` : `0.9`}em;
                    width: ${mobile ? `0.8` : `0.9`}em;
                  `}
                >
                  <path
                    d="M14.9633 5.20743L10.5002 4.5588L8.50509 0.514069C8.4506 0.403327 8.36095 0.313678 8.25021 0.259186C7.97247 0.122077 7.63497 0.236335 7.4961 0.514069L5.50099 4.5588L1.0379 5.20743C0.914855 5.22501 0.802355 5.28301 0.716223 5.3709C0.612093 5.47793 0.554712 5.62192 0.55669 5.77123C0.558668 5.92054 0.619841 6.06296 0.726769 6.16719L3.95587 9.31544L3.19298 13.7609C3.17509 13.8644 3.18653 13.9707 3.22601 14.068C3.26549 14.1652 3.33143 14.2494 3.41634 14.3111C3.50126 14.3728 3.60175 14.4094 3.70644 14.4169C3.81112 14.4243 3.9158 14.4023 4.00861 14.3533L8.0006 12.2545L11.9926 14.3533C12.1016 14.4113 12.2281 14.4307 12.3494 14.4096C12.6553 14.3568 12.8609 14.0668 12.8082 13.7609L12.0453 9.31544L15.2744 6.16719C15.3623 6.08106 15.4203 5.96856 15.4379 5.84551C15.4854 5.5379 15.2709 5.25313 14.9633 5.20743Z"
                    fill="#FFCE22"
                  />
                </svg>
              )}
            </span>
          </div>
          {!mobile && (
            <Fragment>
              <span
                css={css`
                  text-align: center;
                  width: 100px;
                  margin-right: 1vw;
                `}
              >
                {token}
              </span>
              <span
                css={css`
                  width: 100px;
                  height: 1em;
                  margin-right: 1vw;
                  text-align: center;
                  color: #fff;
                  padding: 4px 0;
                  border-radius: 4px;
                  text-transform: uppercase;
                  font-size: 0.9em;
                  font-weight: 600;
                  transition: 0.5s;
                  background: #${!aprove ? "FB4343" : "46D35C"};
                `}
              >
                {aprove ? "ACCEPTED" : "DECLINED"}
              </span>
            </Fragment>
          )}
          <DropDown
            margin="0 calc(1vw + 15px) 0 0"
            list={
              <Fragment>
                {aprove ? (
                  <li
                    role="button"
                    tabIndex="0"
                    onClick={() => this.handleStatusVideo(false)}
                    css={css`
                      ${ButtonDropDown}
                    `}
                  >
                    <img
                      alt="Decline Icon"
                      src={DeclineIcon}
                      css={IconDropDown}
                    />
                    Decline Video
                  </li>
                ) : (
                  <li
                    role="button"
                    tabIndex="0"
                    onClick={() => this.handleStatusVideo(true)}
                    css={css`
                      ${ButtonDropDown}
                    `}
                  >
                    <img
                      alt="Accept Icon"
                      src={EnableIcon}
                      css={IconDropDown}
                    />
                    Accept Video
                  </li>
                )}
                <li
                  role="button"
                  tabIndex="0"
                  onClick={() => {
                    window.open(`https://www.youtube.com/watch?v=${videoId}`);
                  }}
                  css={css`
                    ${ButtonDropDown}
                  `}
                >
                  <img alt="Delete Icon" src={YoutubeIcon} css={IconDropDown} />
                  Open on Youtube
                </li>
                {!distributor ? (
                  <li
                    role="button"
                    tabIndex="0"
                    onClick={() => this.handleChangeDis(true)}
                    css={css`
                      ${ButtonDropDown}
                    `}
                  >
                    <img
                      alt="Delete Icon"
                      src={YoutubeIcon}
                      css={IconDropDown}
                    />
                    Flag As Distributor
                  </li>
                ) : (
                  <li
                    role="button"
                    tabIndex="0"
                    onClick={() => this.handleChangeDis(false)}
                    css={css`
                      ${ButtonDropDown}
                    `}
                  >
                    <img
                      alt="Delete Icon"
                      src={YoutubeIcon}
                      css={IconDropDown}
                    />
                    Unflag As Distributor
                  </li>
                )}
                {mobile && (
                  <li
                    role="button"
                    tabIndex="0"
                    css={ButtonDropDown}
                    onClick={this.handlePopUp}
                  >
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      alt="More Info Icon"
                      role="img"
                      css={IconDropDown}
                    >
                      <path
                        d="M7 0C3.13438 0 0 3.13438 0 7C0 10.8656 3.13438 14 7 14C10.8656 14 14 10.8656 14 7C14 3.13438 10.8656 0 7 0ZM7 12.8125C3.79063 12.8125 1.1875 10.2094 1.1875 7C1.1875 3.79063 3.79063 1.1875 7 1.1875C10.2094 1.1875 12.8125 3.79063 12.8125 7C12.8125 10.2094 10.2094 12.8125 7 12.8125Z"
                        fill="#383b3f"
                      />
                      <path
                        d="M6.25 4.25C6.25 4.44891 6.32902 4.63968 6.46967 4.78033C6.61032 4.92098 6.80109 5 7 5C7.19891 5 7.38968 4.92098 7.53033 4.78033C7.67098 4.63968 7.75 4.44891 7.75 4.25C7.75 4.05109 7.67098 3.86032 7.53033 3.71967C7.38968 3.57902 7.19891 3.5 7 3.5C6.80109 3.5 6.61032 3.57902 6.46967 3.71967C6.32902 3.86032 6.25 4.05109 6.25 4.25ZM7.375 6H6.625C6.55625 6 6.5 6.05625 6.5 6.125V10.375C6.5 10.4437 6.55625 10.5 6.625 10.5H7.375C7.44375 10.5 7.5 10.4437 7.5 10.375V6.125C7.5 6.05625 7.44375 6 7.375 6Z"
                        fill="#383b3f"
                      />
                    </svg>
                    More Info
                  </li>
                )}
                <div
                  css={css`
                    width: 100%;
                    height: 2px;
                    background: #383b3f;
                    opacity: 0.5;
                  `}
                />
                <li
                  role="button"
                  tabIndex="0"
                  onClick={this.handleDeleteVideo}
                  css={css`
                    ${ButtonDropDown};
                    color: #ff3c3c;
                    :hover {
                      background: rgba(255, 85, 85, 0.3);
                    }
                  `}
                >
                  <img alt="Delete Icon" src={DeleteIcon} css={IconDropDown} />
                  Delete Video
                </li>
              </Fragment>
            }
          >
            <img
              alt="More"
              src={Dots}
              css={css`
                height: 1em;
                width: 1em;
                opacity: 0.7;
              `}
            />
          </DropDown>
        </li>
        {open && mobile && (
          <div
            css={css`
              position: fixed;
              display: flex;
              align-items: center;
              justify-content: center;
              background: rgba(0, 0, 0, 0.25);
              z-index: 100;
              top: 0;
              left: 0;
              right: 0;
              bottom: 0;
            `}
          >
            <section
              css={css`
                background: #fff;
                box-shadow: 0px 1px 6px rgba(41, 46, 52, 0.25);
                border-radius: 8px;
                z-index: 101;
                overflow: hidden;
                display: flex;
                flex-direction: column;
                padding: 20px;
                padding-right: 20vw;
                position: relative;

                h1 {
                  font-size: 0.9em;
                  color: #838383;
                  margin-bottom: 4px;
                }

                h2 {
                  font-size: 1.1em;
                  margin-bottom: 15px;
                  color: #383b3f;
                }

                h2:last-child {
                  margin-bottom: 0;
                }
              `}
            >
              <h1>Token</h1>
              <h2>{token}</h2>
              <h1>{aprove ? "Accepted at" : "Declined at"}</h1>
              <h2>{dayjs(CreatedAt).format("DD MMM YYYY, HH:mm:ss")}</h2>
              <h1>Status</h1>
              <h2
                css={css`
                  width: 100px;
                  height: 1.1m;
                  text-align: center;
                  color: #fff !important;
                  padding: 4px 15px;
                  border-radius: 4px;
                  text-transform: uppercase;
                  font-size: 1em !important;
                  font-weight: 600;
                  transition: 0.5s;
                  background: #${!aprove ? "FB4343" : "46D35C"};
                `}
              >
                {aprove ? "ACCEPTED" : "DECLINED"}
              </h2>
            </section>
          </div>
        )}
      </Fragment>
    );
  }
}

const VideoCheck = ({
  videos,
  handleDelete,
  handleStatus,
  firebase,
  chooseVideoId,
  handleId,
  handleDis,
  mobile,
  Responsive,
}) => {
  return (
    <ul
      css={css`
        list-style-type: none;
        padding: 0;
        margin: 0;
        height: ${mobile ? `100%` : Responsive ? `72vh` : `58vh`};
        overflow: overlay;

        li {
          margin: 2vh 0;
        }

        li:first-of-type {
          margin: 0 0 2vh 0;
        }

        li:last-child {
          margin: 2vh 0 0 0;
        }

        &::-webkit-scrollbar-button {
          display: none;
        }
      `}
    >
      {videos.map((c, i) => (
        <Video
          c={c}
          key={i}
          index={i}
          firebase={firebase}
          handleDelete={handleDelete}
          handleStatus={handleStatus}
          handleDis={handleDis}
          handleId={handleId}
          checked={chooseVideoId.indexOf(c.id) >= 0}
          mobile={mobile}
        />
      ))}
    </ul>
  );
};

export default withFirebase(VideoCheck);
