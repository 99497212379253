/** @jsx jsx */
import { useState } from "react";
import { css, jsx } from "@emotion/core";
import IconWA from "../../image/svg/whatsapp.svg";

const breakpoints = [768];

const mq = breakpoints.map(
  (bp) => `@media only screen and (max-width: ${bp}px)`
);

const ContactText = ({
  minHeight,
  maxHeight,
  Height,
  minWidth,
  maxWidth,
  Width,
  noLabel,
  color,
  start,
}) => {
  const [state, setState] = useState({ text: "" });
  const { text } = state;
  const PhoneNumber = "6282119930620";

  const HandleChangeInput = (e) => {
    setState({
      ...state,
      text: e.target.value,
    });
  };

  const HandleSumbitMsg = () => {
    if (text !== "") {
      var encodeTxt = encodeURI(text);
      window.open(`https://wa.me/${PhoneNumber}?text=${encodeTxt}`);
    }
  };
  const HandleSumbitEnter = (e) => {
    if (e.key === "Enter") {
      HandleSumbitMsg();
    }
  };
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        color: ${color ? color : `#fff`};
        align-items: ${start !== "end"
          ? start !== "center"
            ? `flex-start`
            : `center`
          : `flex-end`};
        padding: ${start === "center" ? "5vh 0" : "0"};
      `}
    >
      {!noLabel && (
        <label
          htmlFor="contact"
          css={css`
            font-size: 2em;
            cursor: text;
          `}
        >
          Contact Us
        </label>
      )}
      <textarea
        id="contact"
        wrap="soft"
        type="text"
        onChange={(e) => HandleChangeInput(e)}
        placeholder="Ask Your Question or Order Here..."
        css={css`
          color: #383b3f;
          padding: 2vh 8px;
          min-width: ${minWidth ? minWidth : "30vw"};
          width: ${Width ? Width : "40vw"};
          max-width: ${maxWidth ? maxWidth : "50vw"};
          min-height: ${minHeight ? minHeight : "15px"};
          height: ${Height ? Height : "15px"};
          max-height: ${maxHeight ? maxHeight : "300px"};
          border: none;
          word-wrap: break-word;
          word-break: break-all;
          margin: 2vh 0 6px 0;
          border-radius: 5px;
        `}
      />
      <span
        css={css`
          font-size: 0.9em;
          font-weight: 400;
          text-align: ${start !== "end"
            ? start !== "center"
              ? `left`
              : `center`
            : `right`};
          ${mq[0]} {
            font-size: 0.8em;
          }
        `}
      >
        This Message Will Be Sent to +62 821-1993-0620 via{` `}
        <a
          href={`https://wa.me/${PhoneNumber}`}
          target="_blank"
          rel="noopener noreferrer"
          css={css`
            color: ${color ? `#0f853c` : `#25D366`};
            font-weight: ${color ? `600` : `400`};
            :-webkit-any-link {
              text-decoration: underline;
            }
          `}
        >
          WhatsApp
        </a>
      </span>

      <button
        onClick={HandleSumbitMsg}
        onKeyDown={(e) => HandleSumbitEnter(e)}
        css={css`
          padding: 10px 30px;
          border-radius: 5vw;
          border: none;
          background: #0f853c;
          color: #fff;
          font-size: 1.3em;
          transition: 0.5s;
          margin-bottom: 2vh;
          margin-top: 2vh;
          text-shadow: 0 2px 1px rgba(0, 0, 0, 0.25),
            0 0 18px rgba(0, 0, 0, 0.25);
          cursor: pointer;
          :hover {
            transform: scale(1.1);
          }
          :active {
            transform: scale(1.05);
            transition: 0.5s, transform 0.1s;
          }
        `}
      >
        SEND
        <img
          src={IconWA}
          alt="WhatsApp Logo"
          css={css`
            height: 1em;
            margin-left: 6px;
            transform: translateY(2px);
            filter: drop-shadow(0 2px 1px rgba(0, 0, 0, 0.25));
          `}
        />
      </button>
    </div>
  );
};

export default ContactText;
