/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Fragment } from "react";

const Percentage = ({ number, desc, total, color }) => {
  return (
    <Fragment>
      <div
        css={css`
          margin-top: 2vh;
          position: relative;
        `}
      >
        <svg
          css={css`
            position: relative;
            width: 150px;
            height: 150px;
            z-index: 1;
            transform: rotate(-90deg);

            circle {
              width: 100%;
              height: 100%;
              fill: none;
              stroke: #c4c4c4;
              stroke-width: 7.5;
              stroke-linecap: round;
              transform: translate(5px, 5px);
              opacity: 0.5;
            }

            circle:nth-of-type(2) {
              opacity: 1;
              stroke: ${color ? color : `#4AEA77`};
              stroke-dasharray: 440;
              stroke-dashoffset: calc(
                440 - (440 * ${(number / total) * 100}) / 100
              );
              z-index: 2;
            }
          `}
        >
          <circle cx="70" cy="70" r="70" />
          <circle cx="70" cy="70" r="70" />
        </svg>
        <div
          css={css`
            position: absolute;
            font-size: 1.5em;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            z-index: 2;

            h3 {
              font-size: 1.5em;
            }

            span {
              font-size: 0.7em;
              color: #646464;
              margin-top: 4px;
            }
          `}
        >
          <h3>{number}</h3>
          {!isFinite(((number / total) * 100).toFixed(1)) ||
            (!isNaN(((number / total) * 100).toFixed(1)) &&
              ((number / total) * 100).toFixed(1) !== 0.0 && (
                <span>{((number / total) * 100).toFixed(1)}%</span>
              ))}
        </div>
      </div>
      <span
        css={css`
          font-size: 1em;
          margin-top: 1vh;
        `}
      >
        {desc}
      </span>
    </Fragment>
  );
};

export default Percentage;
