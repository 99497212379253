import { css } from "@emotion/core";

export const maxRandom = 100;

export const numPerPage = 20;

export const tokenLength = 6;

export const fileType =
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";

export const fileExtension = ".xlsx";

export const FlexRow = css`
  display: flex;
  flex-direction: row;
`;

export const FlexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const Splitter = css`
  height: 2px;
  width: 100%;
  background: #383b3f;
  margin: 1vh 0;
`;

export const ButtonAll = css`
  padding: 5px 15px;
  margin-right: 1vw;
  font-size: 1.2em;
  text-transform: uppercase;
  color: #fff;
  border-radius: 10px;
  transition: 0.2s;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(1.05);
  }

  &:disabled {
    transform: scale(1);
    background: #9c9c9c;
  }
`;

export const ButtonGenerate = css`
  ${ButtonAll};
  margin: 0 1vw;
  padding: 1vh 0;
  background: #004e7a;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  color: #fff;
`;

export const WrapperList = css`
  button {
    margin-right: 2vw;
  }
  button:last-child {
    margin-right: 0;
  }
`;

export const handleRandoming = (a, N) => {
  var set = new Set(a);

  const Static = a.length - set.size;

  while (set.size !== a.length) {
    var arr = [...Array(a.length - set.size)].map(() =>
      (Math.random().toString(36) + "00000000000000000")
        .slice(2, N + 2)
        .toUpperCase()
    );
    arr.forEach((c) => {
      set.add(c);
    });
  }

  console.log(`checked(${Static} items)...`);
  var array = [];
  set.forEach((c) => {
    array.push(c);
  });

  return new Set(array.sort());
};

// export const handleCheckIndex = (arr, ca) => {
//   var a = [];
//   ca.forEach((c) => {
//     var i = arr
//       .map(function (e) {
//         return e.id;
//       })
//       .indexOf(c);
//     if (i >= 0) {
//       a.push(i);
//     }
//   });
//   return a;
// };
