/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/core';


/**
 * @function LoadingData
 */

const ImageRotate = keyframes`
from,
to {
  background-position: 0 100%;
}
50% {
  background-position: 100% 100%;
}`

const LoadingSkeleton = props => {
  return (
    <div css={css`
            height: ${props.height};
            width: ${props.width};
            border-radius: ${props.borderRadius};
            background: ${props.background1 ? `${props.background1}` : `
            linear-gradient(141deg, #9fb8ad 0%, #1fc8db 51%, #2cb5e8 75%)
              `};
            background-size: 400% 400%;
            animation: ${ImageRotate} 10s ease infinite;
            margin: ${props.margin};
            opacity: ${props.opacity ? `${props.opacity}` : '10%'}
        `} />
  )
}

export default LoadingSkeleton;