/** @jsx jsx */
import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { css, jsx } from "@emotion/core";

import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";

import Section from "../../components/Dashboard/Section";
import WhiteDoc from "../../components/Dashboard/WhiteDoc";
import VideoStatCom from "../../components/Dashboard/VideoStatCom";

import {
  FlexRow,
  Splitter,
  ButtonAll,
  numPerPage,
  // handleCheckIndex,
  fileType,
  fileExtension,
} from "./essentials";

import { connect } from "react-redux";
import {
  updateState,
  loadingVideoStat,
  setVideoStat,
  setIdVidStat,
} from "../../redux/actions/dbActions";

import { withFirebase } from "../../components/Firebase";
import { compose } from "recompose";

const VidStatWD = ({
  videoDisplay,
  chooseVideoStatId,
  videoP,
  _exportDistributorToExcel,
  handleResetIdVideoStat,
  handleDeleteVS,
  handleIdVS,
  handleDeleteIdVS,
  handleBackPageVS,
  handleNextPageVS,
  mobile,
  mobileClick,
}) => {
  return (
    <WhiteDoc
      title="Distributor List"
      subtitle="Every distributors that have uploaded a video will be shown here"
      styleContent={{
        textAlign: videoDisplay.length === 0 ? `center` : "left",
        justifyContent: videoDisplay.length === 0 ? `center` : "unset",
        minHeight: "20vw",
      }}
      rightside={
        <button
          css={css`
            ${ButtonAll};
            border: 2px solid #878787;
            color: #878787;
            margin-right: 0;
          `}
          onClick={_exportDistributorToExcel}
        >
          Export
        </button>
      }
      mobile={mobile}
      mobileClick={mobileClick}
    >
      {videoDisplay.length === 0 ? (
        `No Information yet`
      ) : (
        <Fragment>
          <div css={FlexRow}>
            <svg
              onClick={() => {
                if (!(chooseVideoStatId.length === 0)) {
                  handleResetIdVideoStat();
                }
              }}
              role="button"
              tabIndex={0}
              viewBox="0 -6.5 10 16"
              fill="#47B2FF"
              xmlns="http://www.w3.org/2000/svg"
              css={css`
                border: 2px solid #383b3f;
                border-radius: 4px;
                margin: 0 1vw;
                height: 1em;
                width: 1em;
                background: ${!(chooseVideoStatId.length === 0)
                  ? `#646464`
                  : `transparent`};
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                content-visibility: ${!(chooseVideoStatId.length === 0)
                  ? `visible`
                  : `hidden`};
                transition: 0.1s;
              `}
            >
              <path
                d="M10 1C10 1.4424 9.76082 1.8002 9.31842 1.8002H0.680019C0.238419 1.8002 0 1.4424 0 1C0 0.5576 0.238419 0.200195 0.680019 0.200195H9.31922C9.76082 0.200195 10 0.5576 10 1Z"
                fill="white"
              />
            </svg>
            <span
              css={css`
                margin-right: 1vw;
                width: ${mobile ? `180px` : `200px`};
                text-align: center;
                font-size: ${mobile ? `0.9em` : `1em`};
              `}
            >
              DISTRIBUTOR NAME
            </span>
            <span
              css={css`
                margin-right: calc(2vw + 31px);
                width: ${mobile ? `180px` : `200px`};
                text-align: center;
                font-size: ${mobile ? `0.9em` : `1em`};
              `}
            >
              DISTRIBUTOR CITY
            </span>
          </div>
          <div css={Splitter} />
          <VideoStatCom
            videoStat={videoDisplay}
            handleDelete={handleDeleteVS}
            chooseVideoStatId={chooseVideoStatId}
            handleId={handleIdVS}
            mobile={mobile}
          />

          <div css={Splitter} />
          <div
            css={css`
              ${FlexRow};
              justify-content: center;
              align-items: center;
              font-size: 1em;
            `}
          >
            <svg
              tabIndex={0}
              onClick={() => {
                if (!(chooseVideoStatId.length === 0)) {
                  handleDeleteIdVS();
                }
              }}
              role="button"
              viewBox="0 0 20 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={css`
                margin: 0 1vw;
                height: 1.2em;
                width: 1.2em;
              `}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.75 1.5C1.35218 1.5 0.970644 1.65804 0.68934 1.93934C0.408035 2.22064 0.25 2.60218 0.25 3V4.5C0.25 4.89782 0.408035 5.27936 0.68934 5.56066C0.970644 5.84196 1.35218 6 1.75 6H2.5V19.5C2.5 20.2956 2.81607 21.0587 3.37868 21.6213C3.94129 22.1839 4.70435 22.5 5.5 22.5H14.5C15.2956 22.5 16.0587 22.1839 16.6213 21.6213C17.1839 21.0587 17.5 20.2956 17.5 19.5V6H18.25C18.6478 6 19.0294 5.84196 19.3107 5.56066C19.592 5.27936 19.75 4.89782 19.75 4.5V3C19.75 2.60218 19.592 2.22064 19.3107 1.93934C19.0294 1.65804 18.6478 1.5 18.25 1.5H13C13 1.10218 12.842 0.720644 12.5607 0.43934C12.2794 0.158035 11.8978 0 11.5 0L8.5 0C8.10218 0 7.72064 0.158035 7.43934 0.43934C7.15804 0.720644 7 1.10218 7 1.5H1.75ZM6.25 7.5C6.44891 7.5 6.63968 7.57902 6.78033 7.71967C6.92098 7.86032 7 8.05109 7 8.25V18.75C7 18.9489 6.92098 19.1397 6.78033 19.2803C6.63968 19.421 6.44891 19.5 6.25 19.5C6.05109 19.5 5.86032 19.421 5.71967 19.2803C5.57902 19.1397 5.5 18.9489 5.5 18.75V8.25C5.5 8.05109 5.57902 7.86032 5.71967 7.71967C5.86032 7.57902 6.05109 7.5 6.25 7.5ZM10 7.5C10.1989 7.5 10.3897 7.57902 10.5303 7.71967C10.671 7.86032 10.75 8.05109 10.75 8.25V18.75C10.75 18.9489 10.671 19.1397 10.5303 19.2803C10.3897 19.421 10.1989 19.5 10 19.5C9.80109 19.5 9.61032 19.421 9.46967 19.2803C9.32902 19.1397 9.25 18.9489 9.25 18.75V8.25C9.25 8.05109 9.32902 7.86032 9.46967 7.71967C9.61032 7.57902 9.80109 7.5 10 7.5ZM14.5 8.25C14.5 8.05109 14.421 7.86032 14.2803 7.71967C14.1397 7.57902 13.9489 7.5 13.75 7.5C13.5511 7.5 13.3603 7.57902 13.2197 7.71967C13.079 7.86032 13 8.05109 13 8.25V18.75C13 18.9489 13.079 19.1397 13.2197 19.2803C13.3603 19.421 13.5511 19.5 13.75 19.5C13.9489 19.5 14.1397 19.421 14.2803 19.2803C14.421 19.1397 14.5 18.9489 14.5 18.75V8.25Z"
                fill={chooseVideoStatId.length === 0 ? "#A7A7A7" : "#FF2424"}
              />
            </svg>
            <div
              css={css`
                flex: 1;
              `}
            />

            <svg
              tabIndex={0}
              role="button"
              onClick={handleBackPageVS}
              viewBox="0 0 5 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={css`
                height: 0.8em;
                width: 0.8em;
              `}
            >
              <path
                d="M0.708837 3.745L3.0655 0.449167C3.26384 0.169167 3.58467 0 3.92884 0C4.798 0 5.29967 0.98 4.798 1.68583L3.08884 4.08333L4.798 6.48084C5.3055 7.18667 4.798 8.16667 3.92884 8.16667C3.58467 8.16667 3.258 7.9975 3.05967 7.7175L0.703003 4.42167C0.563004 4.2175 0.563004 3.94917 0.708837 3.745Z"
                fill="#535353"
              />
            </svg>
            <span
              css={css`
                margin: 0 16px;
              `}
            >
              {videoP}/
              {Math.ceil(videoDisplay.length / numPerPage) === 0
                ? "1"
                : Math.ceil(videoDisplay.length / numPerPage)}
            </span>
            <svg
              tabIndex={0}
              role="button"
              onClick={handleNextPageVS}
              viewBox="0 0 5 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={css`
                height: 0.8em;
                width: 0.8em;
              `}
            >
              <path
                d="M4.29116 3.745L1.9345 0.449167C1.73616 0.169167 1.41533 0 1.07116 0C0.201999 0 -0.299668 0.98 0.201998 1.68583L1.91116 4.08333L0.201998 6.48084C-0.305502 7.18667 0.201999 8.16667 1.07116 8.16667C1.41533 8.16667 1.742 7.9975 1.94033 7.7175L4.297 4.42167C4.437 4.2175 4.437 3.94917 4.29116 3.745Z"
                fill="#535353"
              />
            </svg>
          </div>
        </Fragment>
      )}
    </WhiteDoc>
  );
};

class VideoStat extends Component {
  state = {
    videoP: 1,
    choose: "",
    done: false,
  };

  handleResetIdVideoStat = () => {
    this.props.setIdVidStat([]);
  };

  handleDeleteVS = (id) => {
    var arr = {};
    var array = [...this.props.db.videoStat];
    var i = array.findIndex((c) => c.id === id);
    array.splice(i, 1);
    arr = {
      videoStat: array,
    };
    this.props.updateState(arr);
  };

  handleBackPageVS = () => {
    if (this.state.videoP !== 1) {
      this.setState({
        videoP: this.state.videoP - 1,
      });
    }
  };

  handleNextPageVS = () => {
    const { videoStat } = this.props.db;
    const { videoP, done } = this.state;
    if (done !== true && videoStat.length > numPerPage * videoP) {
      this.fetchMoreDataVS(numPerPage - (videoStat.length % numPerPage));
      this.setState({
        videoP: videoP + 1,
      });
    }
  };

  fetchMoreDataVS = (n) => {
    this.props.loadingVideoStat();
    const { videoStat } = this.props.db;
    const lastVisible = videoStat[videoStat.length - 1];
    this.props.firebase
      .videoStatRef()
      .orderBy("CreatedAt", "desc")
      .startAfter(lastVisible.CreatedAt, lastVisible.title)
      .limit(n)
      .get()
      .then((data) => {
        if (!data.empty) {
          let video = videoStat;
          data.forEach((doc) => {
            video.push({
              id: doc.id,
              ...doc.data(),
            });
          });
          this.props.setVideoStat(video);
        } else {
          this.setState({
            done: true,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleIdVS = (c) => {
    var a = this.props.db.chooseVideoStatId;
    var i = a.indexOf(c);
    if (i < 0) {
      a.push(c);
    } else {
      a.splice(i, 1);
    }
    this.props.setIdVidStat(a);
  };

  handleDeleteIdVS = () => {
    const { videoStat, chooseVideoStatId } = this.props.db;
    const { videoStatRef } = this.props.firebase;
    const batch = this.props.firebase.batch();
    var a = chooseVideoStatId;
    var arr = {};
    var array = videoStat;

    a.forEach((c) => {
      var i = array
        .map((e) => {
          return e.id;
        })
        .indexOf(c);
      array.splice(i, 1);
      batch.delete(videoStatRef().doc(c));
    });

    arr = {
      videoStat: array,
    };

    batch.commit();

    this.props.updateState(arr);
    this.props.setIdVidStat([]);
  };

  _exportDistributorToExcel = () => {
    var a = [];
    const { videoStat, loading } = this.props.db;
    var n = videoStat.length;
    if (n !== 0) {
      a = videoStat;
      if (!loading.token) {
        const ws = XLSX.utils.json_to_sheet(a);
        const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
        const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, "Distrobutor-list" + fileExtension);
      }
    }
  };

  _handleDisplay = (c) => {
    this.setState({
      choose: c,
    });
  };

  render() {
    const {
      db: { videoStat, chooseVideoStatId },
      mobile,
      mobileClick,
    } = this.props;
    const { videoP } = this.state;

    const videoDisplay = videoStat
      .sort((a, b) => {
        return a.token < b.token ? -1 : a.token > b.token ? 1 : 0;
      })
      .sort((a, b) => {
        return a.city < b.city ? -1 : a.city > b.city ? 1 : 0;
      })
      .sort((a, b) => {
        return a.store < b.store ? -1 : a.store > b.store ? 1 : 0;
      })
      .sort((a, b) => {
        return a.CreatedAt > b.CreatedAt
          ? -1
          : a.CreatedAt < b.CreatedAt
          ? 1
          : 0;
      })
      .filter((_, i) => i >= (videoP - 1) * numPerPage)
      .filter((_, i) => i <= numPerPage - 1 + (videoP - 1) * numPerPage);

    const WD = (
      <VidStatWD
        chooseVideoStatId={chooseVideoStatId}
        videoDisplay={videoDisplay}
        videoP={videoP}
        _exportDistributorToExcel={this._exportDistributorToExcel}
        handleResetIdVideoStat={this.handleResetIdVideoStat}
        handleDeleteVS={this.handleDeleteVS}
        handleIdVS={this.handleIdVS}
        handleDeleteIdVS={this.handleDeleteIdVS}
        handleBackPageVS={this.handleBackPageVS}
        handleNextPageVS={this.handleNextPageVS}
        mobile={mobile}
        mobileClick={mobileClick}
      />
    );

    return !mobile ? (
      <Section title="Distributor List" style={{ width: "unset" }}>
        {WD}
      </Section>
    ) : (
      WD
    );
  }
}

VideoStat.propTypes = {
  updateState: PropTypes.func.isRequired,
  loadingVideoStat: PropTypes.func.isRequired,
  setVideoStat: PropTypes.func.isRequired,
  setIdVidStat: PropTypes.func.isRequired,
  db: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  db: state.db,
});

export default compose(
  withFirebase,
  connect(mapStateToProps, {
    updateState,
    loadingVideoStat,
    setVideoStat,
    setIdVidStat,
  })
)(VideoStat);
