/** @jsx jsx */
import { useState } from "react";
import { css, jsx } from "@emotion/core";
import DropDown from "./DropDown";

const DateInput = ({ zIndex, error, handleChange, theme }) => {
  const [state, setstate] = useState({
    Year: null,
    Month: null,
    Day: null,
  });
  const { Year, Month, Day } = state;

  let AYear = new Array(51).fill().map((_, i) => i + 1970);

  let AMonth = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  let ADay = new Array(10).fill().map((_, i) => i + 1);

  const handleBDayChangeM = (value) => {
    setstate({
      ...state,
      Month: value,
    });
    handleChange(`${value}-${Day}-${Year}`);
  };

  const handleBDayChangeD = (value) => {
    setstate({
      ...state,
      Day: value,
    });
    handleChange(`${Month}-${value}-${Year}`);
  };

  const handleBDayChangeY = (value) => {
    setstate({
      ...state,
      Year: value,
    });
    handleChange(`${Month}-${Day}-${value}`);
  };

  const DropDowns = [
    {
      list: AMonth,
      placeholder: "Month",
      width: "10vw",
      maxH: "100px",
      handleChange: handleBDayChangeM,
    },
    {
      list: ADay,
      placeholder: "Day",
      width: "5vw",
      maxH: "200px",
      handleChange: handleBDayChangeD,
    },
    {
      list: AYear,
      placeholder: "Year",
      width: "7.5vw",
      maxH: "150px",
      handleChange: handleBDayChangeY,
    },
  ];

  const HandleIndex = (i) => {
    const L = 6;
    if (i === 0) return L;
    return L - i * 2;
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        & > * {
          margin-right: 20px;
        }
      `}
    >
      {DropDowns.map((c, i) => (
        <DropDown
          key={i}
          zIndex={zIndex ? zIndex : HandleIndex(i)}
          error={error}
          {...c}
          theme={theme}
        />
      ))}
    </div>
  );
};
export default DateInput;
