/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Component } from "react";

import dayjs from "dayjs";

import { withFirebase } from "../Firebase";

import declineIcon from "../../image/svg/decline.svg";
import acceptIcon from "../../image/svg/accept.svg";
import linkIcon from "../../image/svg/dashboard/link.svg";

class Video extends Component {
  state = {
    width: 0,
  };

  componentDidMount() {
    this.setState({
      width: this.container.offsetWidth,
    });
  }

  handleVideoCheck = (a) => {
    const Time = new Date().toISOString();
    const { videoReqRef, videoRef } = this.props.firebase;
    const {
      c: { title, token, videoId, id },
    } = this.props;
    videoReqRef()
      .doc(id)
      .delete()
      .then(() => {
        videoRef().add({
          title: title,
          token,
          videoId,
          isVertical: false,
          aprove: a,
          CreatedAt: Time,
          distributor: false,
        });
        this.props.firebase
          .StatRef()
          .doc("VideoReq")
          .get()
          .then((doc) => {
            if (a) {
              doc.ref.update({
                unreview: doc.data().unreview - 1,
                accepted: doc.data().accepted + 1,
              });
            } else {
              doc.ref.update({
                unreview: doc.data().unreview - 1,
                decline: doc.data().decline + 1,
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
        this.props.firebase
          .StatRef()
          .doc("Video")
          .get()
          .then((doc) => {
            if (a) {
              doc.ref.update({
                size: doc.data().size + 1,
                accepted: doc.data().accepted + 1,
              });
            } else {
              doc.ref.update({
                size: doc.data().size + 1,
                decline: doc.data().decline + 1,
              });
            }
          });
      })
      .then(() => {
        this.props.handleChoose(this.props.c.id, a, Time);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const {
      c: { title, token, videoId, CreatedAt },
      mobile,
    } = this.props;

    const { width } = this.state;

    return (
      <li
        css={css`
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          overflow-wrap: anywhere;
          width: ${mobile ? `100%` : `40%`};
          margin: 1vh 1vw;
        `}
      >
        <div
          role="button"
          tabIndex="0"
          onClick={() => {
            window.open(`https://www.youtube.com/watch?v=${videoId}`);
          }}
          ref={(el) => (this.container = el)}
          css={css`
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: calc(${width}px * 9 / 16);
            margin-bottom: 8px;
            background: url("https://img.youtube.com/vi/${videoId}/mqdefault.jpg");
            background-blend-mode: color;
            background-size: cover;
            background-repeat: no-repeat;
            transition: 0.2s;
            padding: 10%;
            box-sizing: border-box;

            span {
              display: flex;
              font-size: 1em;
              color: #fff;
              opacity: 0;
            }

            &:hover {
              background-color: rgba(0, 0, 0, 0.75);
              cursor: pointer;
              span {
                opacity: 1;
              }
            }
          `}
        >
          <span>
            <img src={linkIcon} alt="decline" />
            {`youtube.com/watch?v=${videoId}`}
          </span>
        </div>
        <div
          css={css`
            min-height: 10vh;
            margin-bottom: 10px;
          `}
        >
          <h2
            css={css`
              font-size: 1em;
              font-weight: 600;
              margin-bottom: 4px;
            `}
          >
            {title}
          </h2>
          <span
            css={css`
              font-size: 0.9em;
            `}
          >
            {dayjs(CreatedAt).format("DD MMM YYYY • HH:mm:ss")}
            {` • `}
            {token}
          </span>
        </div>
        <div
          css={css`
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-evenly;

            button {
              text-transform: uppercase;
              color: #fff;
              border-radius: 20px;
              font-size: 1.1em;
              height: calc(1.1em + 20px);
              width: calc(1.1em + 20px);
              display: flex;
              flex-direction: row;
              align-items: center;
              overflow: hidden;
              position: relative;
              transition: 0.3s;

              img {
                position: absolute;
                left: 6px;
                height: 1.5em;
                width: 1.5em;
              }

              span {
                transition: 0.3s;
                overflow-wrap: normal;
                margin-left: calc(1.1em + 20px);
                opacity: 0;
              }
            }

            button:hover {
              width: calc(5.5em + 30px);
              margin: 0 calc(-2em - 8px);
              z-index: 2;
              span:last-of-type {
                opacity: 1;
              }
            }

            button:hover:first-of-type {
              margin: 0 calc(-2em - 3px);
            }

            button:active {
              transform: scale(0.9);
            }

            button:first-of-type {
              margin-right: 10px;
            }
          `}
        >
          <button
            css={css`
              background: #00c52b;
            `}
            onClick={() => this.handleVideoCheck(true)}
          >
            <img src={acceptIcon} alt="accept" />
            <span>Accept</span>
          </button>
          <button
            css={css`
              background: #f83e3e;
            `}
            onClick={() => this.handleVideoCheck(false)}
          >
            <img src={declineIcon} alt="decline" />
            <span>Decline</span>
          </button>
        </div>
      </li>
    );
  }
}

const VideoUncheck = ({
  videos,
  handleChoose,
  firebase,
  mobile,
  Responsive,
  Responsive2,
}) => {
  return (
    <ul
      css={css`
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: flex;
        flex-direction: ${Responsive2 ? `column` : `row`};
        flex-wrap: ${Responsive2 ? `unset` : `wrap`};
        justify-content: space-between;
        padding: 0 1vw;
        overflow: auto;
        // height: ${mobile ? `100vh` : `calc(100vh - 2vh - 12px - 3.5em)`};
        height: ${mobile
          ? `100%`
          : Responsive
          ? `calc(110vh + 2px + 1em)`
          : `calc(95vh - 2px - 1em)`};

        &::-webkit-scrollbar-button {
          display: none;
        }
      `}
    >
      {videos.map((c, i) => (
        <Video
          c={c}
          key={i}
          index={i}
          handleChoose={handleChoose}
          firebase={firebase}
          mobile={Responsive2}
        />
      ))}
    </ul>
  );
};

export default withFirebase(VideoUncheck);
