import React, { Component, Fragment, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import "../css/job.css";

import searchImg from "../image/searching.webm";

import { connect } from "react-redux";
import { loadingData, resetNavbar } from "../redux/actions/dataActions";

import { withFirebase } from "../components/Firebase";

const JobDetails = lazy(() => import("../components/JobDetails/JobDetails.js"));
const LoadingSkeleton = lazy(() => import("../components/LoadingSkeleton"));

class job extends Component {
  state = {
    jobs: [],
  };

  componentDidMount() {
    this.props.resetNavbar();
    this.props.loadingData();
    this.props.firebase
      .jobRef()
      .orderBy("CreatedAt", "desc")
      .get()
      .then((data) => {
        let Job = [];
        data.forEach((doc) => {
          Job.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.setState({
          ...this.state,
          jobs: Job,
        });
        this.props.loadingData();
      });
  }

  handleClickArrow() {
    document
      .getElementById("Jobs")
      .scrollIntoView({ block: "start", behavior: "smooth" });
  }

  render() {
    const { loading } = this.props.data;

    const { jobs } = this.state;

    const Skeleton = () => {
      return (
        <Fragment>
          <LoadingSkeleton
            height="5vh"
            width="30vw"
            borderRadius="5vw"
            margin="7vh 0 1vh 0"
          />
          <LoadingSkeleton
            height="0.5vh"
            width="100%"
            borderRadius="5vw"
            margin="2vh 0"
          />
          <LoadingSkeleton
            height="20px"
            width="40vw"
            borderRadius="5vw"
            margin="1.5vh 0"
          />
          <LoadingSkeleton
            height="20px"
            width="40vw"
            borderRadius="5vw"
            margin="1.5vh 0"
          />
          <LoadingSkeleton
            height="20px"
            width="40vw"
            borderRadius="5vw"
            margin="1.5vh 0 7vh 0"
          />
        </Fragment>
      );
    };

    const alljob = !loading ? (
      Object.keys(jobs).length !== 0 ? (
        jobs.map((c, i) => <JobDetails key={i} {...c} />)
      ) : (
        <div className="NoJob">
          <span>Sorry, there's no available job currently</span>
          <video
            src={searchImg}
            type="video/webm"
            alt="Seaching..."
            autoPlay
            loop
            className="searchImg"
          />
        </div>
      )
    ) : (
      <Fragment>
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Fragment>
    );
    return (
      <Fragment>
        <section className="JobHeader">
          <h1 className="TitleHeader">Let’s Join Our Team!</h1>
          <span className="SubtitleHeader">
            We invited you to join our team
          </span>
          <svg
            width="60"
            height="60"
            viewBox="0 0 98 98"
            data-icon="Down Arrow"
            aria-label="Go Down"
            fill="none"
            role="button"
            xmlns="http://www.w3.org/2000/svg"
            className="DownArrow"
            onClick={this.handleClickArrow}
          >
            <path
              d="M33.157 37.9344L49.0004 53.7777L64.8437 37.9344C65.2217 37.5563 65.6705 37.2564 66.1645 37.0518C66.6584 36.8472 67.1878 36.7419 67.7224 36.7419C68.2571 36.7419 68.7865 36.8473 69.2804 37.0518C69.7743 37.2564 70.2231 37.5563 70.6012 37.9344C70.9792 38.3124 71.2791 38.7612 71.4837 39.2551C71.6883 39.7491 71.7936 40.2785 71.7936 40.8131C71.7936 41.3477 71.6883 41.8771 71.4837 42.3711C71.2791 42.865 70.9792 43.3138 70.6012 43.6919L51.8587 62.4344C51.4809 62.8129 51.0322 63.1132 50.5382 63.3181C50.0443 63.523 49.5147 63.6285 48.9799 63.6285C48.4452 63.6285 47.9156 63.523 47.4216 63.3181C46.9277 63.1132 46.479 62.8129 46.1012 62.4344L27.3587 43.6919C26.9802 43.3141 26.6798 42.8654 26.4749 42.3714C26.27 41.8774 26.1646 41.3479 26.1646 40.8131C26.1646 40.2783 26.27 39.7488 26.4749 39.2548C26.6798 38.7608 26.9802 38.3121 27.3587 37.9344C28.9512 36.3827 31.5645 36.3419 33.157 37.9344Z"
              fill="white"
            />
          </svg>
        </section>
        <Suspense fallback={<div />}>
          <section id="Jobs" className="JobDescription">
            {alljob}
          </section>
        </Suspense>
      </Fragment>
    );
  }
}

job.propTypes = {
  loadingData: PropTypes.func.isRequired,
  resetNavbar: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, { loadingData, resetNavbar })(
  withFirebase(job)
);
