import {
  LOADING_STAT,
  LOADING_VID_REQ,
  LOADING_VID,
  LOADING_TOKEN,
  LOADING_VIDEO_STAT,
  FETCH_STAT_VID_REQ,
  FETCH_STAT_VID,
  FETCH_STAT_TOKEN,
  FETCH_VID_REQ,
  FETCH_VID,
  FETCH_TOKEN,
  FIRST_LOAD,
  FETCH_VIDEO_STAT,
  UPDATE_STATE,
  SET_TOKEN_ID,
  SET_VID_ID,
  SET_VID_STAT_ID,
} from "../types";

const VideoList = [
  {
    id: "0",
    title: "0",
    token: "------",
    videoId: "FgD911eOYfY",
    aprove: true,
    isVertical: false,
    CreatedAt: new Date().toISOString(),
    distributor: true,
  },
  {
    id: "1",
    title: "1",
    token: "AAAAAA",
    videoId: "IcrbM1l_BoI",
    aprove: false,
    isVertical: false,
    CreatedAt: new Date().toISOString(),
    distributor: false,
  },
  {
    id: "2",
    title: "2",
    token: "AAAAAB",
    videoId: "dXM-i__Oen8",
    aprove: false,
    isVertical: false,
    CreatedAt: "2020-07-17T14:00:00Z",
    distributor: false,
  },
  {
    id: "3",
    title: "3",
    token: "AAAABC",
    videoId: "Tfaq4UTH7P0",
    aprove: true,
    isVertical: false,
    CreatedAt: "2019-10-10T15:00:00Z",
    distributor: false,
  },
  {
    id: `4`,
    title: `4`,
    token: "AAABCD",
    videoId: "JgIIU2RLip4",
    aprove: true,
    isVertical: false,
    CreatedAt: "2019-03-15T15:00:00Z",
    distributor: false,
  },
  {
    id: `5`,
    title: `5`,
    token: "AABCDE",
    videoId: "ro76b6i0IfY",
    aprove: false,
    isVertical: false,
    CreatedAt: "2018-09-30T15:00:00Z",
    distributor: false,
  },
  {
    id: `6`,
    title: `6`,
    token: "ABCDEF",
    videoId: "csOT7oCKRmk",
    aprove: false,
    isVertical: false,
    CreatedAt: "2017-05-01T15:00:00Z",
    distributor: false,
  },
  {
    id: `7`,
    title: `7`,
    token: "ABCDEF",
    videoId: "lsrw_C7I0s4",
    aprove: false,
    isVertical: false,
    CreatedAt: "2025-12-25T15:00:00Z",
    distributor: false,
  },
];

const Tokens = [
  {
    id: "124G5R",
    token: "124G5R",
    used: true,
    disable: false,
    CreatedAt: new Date().toISOString(),
  },
  {
    id: "7OZLIO",
    token: "7OZLIO",
    used: false,
    disable: false,
    CreatedAt: "2025-12-25T15:00:00Z",
  },
  {
    id: "6HF3SS",
    token: "6HF3SS",
    used: false,
    disable: true,
    CreatedAt: "2018-09-30T15:00:00Z",
  },
  {
    id: "G2PU9E",
    token: "G2PU9E",
    used: true,
    disable: false,
    CreatedAt: new Date().toISOString(),
  },
  {
    id: "0JHY8T",
    token: "0JHY8T",
    used: false,
    disable: false,
    CreatedAt: "2025-12-25T15:00:00Z",
  },
];

const CityStore = [
  {
    store: "Kezia Store",
    city: "Kezia City",
  },
  {
    store: "Kezia Store",
    city: "Kezia City",
  },
];

const initialState = {
  newVideo: 0,
  videoReqUnreview: 0,
  videoReqAccepted: 0,
  videoReqDecline: 0,

  tokenAll: 0,
  tokenAvaliable: 0,
  tokenUsed: 0,
  tokenDisabled: 0,

  videoAll: 0,
  videoAccept: 0,
  videoDecline: 0,
  videoDis: 0,

  chooseTokenId: [],
  chooseVideoId: [],
  chooseVideoStatId: [],

  error: {
    videoReq: "",
    video: "",
    token: "",
  },

  tokens: Tokens,
  videos: VideoList,
  videoReqs: VideoList,
  videoStat: CityStore,

  loading: {
    stats: {
      videoReq: false,
      video: false,
      token: false,
    },
    videoReq: false,
    video: false,
    token: false,
    videoStat: false,
  },

  load: 7,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FIRST_LOAD:
      return {
        ...state,
        load: initialState.load,
      };

    case LOADING_STAT:
      return {
        ...state,
        loading: {
          ...state.loading,
          stats: {
            videoReq: true,
            video: true,
            token: true,
          },
        },
      };
    case LOADING_VID_REQ:
      return {
        ...state,
        loading: {
          ...state.loading,
          videoReq: true,
        },
      };
    case LOADING_VID:
      return {
        ...state,
        loading: {
          ...state.loading,
          video: true,
        },
      };
    case LOADING_TOKEN:
      return {
        ...state,
        loading: {
          ...state.loading,
          token: true,
        },
      };

    case LOADING_VIDEO_STAT:
      return {
        ...state,
        loading: {
          ...state.loading,
          videoStat: true,
        },
      };

    case FETCH_STAT_VID_REQ:
      return {
        ...state,
        newVideo: action.payload.request ? action.payload.request : 0,
        videoReqUnreview: action.payload.unreview ? action.payload.unreview : 0,
        videoReqAccepted: action.payload.accepted ? action.payload.accepted : 0,
        videoReqDecline: action.payload.decline ? action.payload.decline : 0,
        loading: {
          ...state.loading,
          stats: {
            ...state.loading.stats,
            videoReq: false,
          },
        },
        load: state.load + 1,
      };
    case FETCH_STAT_VID:
      return {
        ...state,
        videoAll: action.payload.size ? action.payload.size : 0,
        videoAccept: action.payload.accepted ? action.payload.accepted : 0,
        videoDecline: action.payload.decline ? action.payload.decline : 0,
        videoDis: action.payload.distributor ? action.payload.distributor : 0,
        loading: {
          ...state.loading,
          stats: {
            ...state.loading.stats,
            video: false,
          },
        },
        load: state.load + 1,
      };
    case FETCH_STAT_TOKEN:
      return {
        ...state,
        tokenAll: action.payload.size ? action.payload.size : 0,
        tokenAvaliable: action.payload.avaliable ? action.payload.avaliable : 0,
        tokenUsed: action.payload.used ? action.payload.used : 0,
        tokenDisabled: action.payload.disable ? action.payload.disable : 0,
        loading: {
          ...state.loading,
          stats: {
            ...state.loading.stats,
            token: false,
          },
        },
        load: state.load + 1,
      };

    case FETCH_VID_REQ:
      return {
        ...state,
        videoReqs: action.payload,
        loading: {
          ...state.loading,
          videoReq: false,
        },
        load: state.load + 1,
      };
    case FETCH_VID:
      return {
        ...state,
        videos: action.payload,
        loading: {
          ...state.loading,
          video: false,
        },
        load: state.load + 1,
      };
    case FETCH_TOKEN:
      return {
        ...state,
        tokens: action.payload,
        loading: {
          ...state.loading,
          token: false,
        },
        load: state.load + 1,
      };
    case FETCH_VIDEO_STAT:
      return {
        ...state,
        videoStat: action.payload,
        loading: {
          ...state.loading,
          videoStat: false,
        },
        load: state.load + 1,
      };

    case UPDATE_STATE:
      return {
        ...state,
        ...action.payload,
      };

    case SET_TOKEN_ID:
      return {
        ...state,
        chooseTokenId: action.payload,
      };
    case SET_VID_ID:
      return {
        ...state,
        chooseVideoId: action.payload,
      };
    case SET_VID_STAT_ID:
      return {
        ...state,
        chooseVideoStatId: action.payload,
      };

    default:
      return state;
  }
}
