/** @jsx jsx */
import { css, jsx, keyframes } from "@emotion/core";
import PropTypes from "prop-types";
import { Component, Fragment } from "react";

import { FlexRow, FlexColumn, numPerPage } from "./dashboard/essentials";

import Logo from "../image/logo.webp";

import { withAuthorization } from "../components/Sesion";

import { connect } from "react-redux";
import {
  loadingStat,
  loadingVidReq,
  loadingVid,
  loadingToken,
  loadingVideoStat,
  setStatVidReq,
  setStatVid,
  setStatToken,
  setVidReq,
  setVid,
  setToken,
  setVideoStat,
  firstLoad,
} from "../redux/actions/dbActions";

import { withFirebase } from "../components/Firebase";

import NavbarDashboard from "../components/Navbar/NavbarDashboard";

import VideoReq from "./dashboard/VideoReq";
import TokenStats from "./dashboard/TokenStats";
import ManageToken from "./dashboard/ManageToken";
import GenTokenSec from "./dashboard/GenTokenSec";
import VideoStats from "./dashboard/VideoStats";
import ManageVideo from "./dashboard/ManageVideo";
import VideoStat from "./dashboard/VideoStat";

import { compose } from "recompose";

import MediaQuery from "react-responsive";

const titleMobile = css`
  width: calc(100vw - 20px);
  font-size: 1.4em;
  padding-left: 20px;
  border-bottom: 2px #383b3f solid;
  margin: 4vh 0;
`;

class dashboard extends Component {
  state = {
    authUser: this.props.authUser,
  };

  FecthStats = () => {
    this.props.loadingStat();
    this.props.firebase
      .StatRef()
      .doc("VideoReq")
      .get()
      .then((doc) => {
        this.props.setStatVidReq(
          doc.data().request,
          doc.data().unreview,
          doc.data().accepted,
          doc.data().decline
        );
      })
      .catch((err) => {
        console.error(err);
      });
    this.props.firebase
      .StatRef()
      .doc("Video")
      .get()
      .then((doc) => {
        this.props.setStatVid(
          doc.data().size,
          doc.data().accepted,
          doc.data().decline,
          doc.data().distributor
        );
      })
      .catch((err) => {
        console.error(err);
      });
    this.props.firebase
      .StatRef()
      .doc("Token")
      .get()
      .then((doc) => {
        this.props.setStatToken(
          doc.data().size,
          doc.data().avaliable,
          doc.data().used,
          doc.data().disable
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  FecthVideoReq = () => {
    this.props.loadingVidReq();
    this.props.firebase
      .videoReqRef()
      .orderBy("CreatedAt", "asc")
      .orderBy("title", "asc")
      .limit(numPerPage * 2)
      .get()
      .then((data) => {
        let video = [];
        data.forEach((doc) => {
          video.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.props.setVidReq(video);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  FecthVideo = () => {
    this.props.loadingVid();
    this.props.firebase
      .videoRef()
      .orderBy("CreatedAt", "desc")
      .orderBy("title", "asc")
      .limit(numPerPage * 2)
      .get()
      .then((data) => {
        let video = [];
        data.forEach((doc) => {
          video.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.props.setVid(
          video.sort((a, b) => {
            return b.distributor - a.distributor;
          })
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  FecthToken = () => {
    this.props.loadingToken();
    this.props.firebase
      .tokenRef()
      .orderBy("CreatedAt", "asc")
      .orderBy("token", "asc")
      .limit(numPerPage * 2)
      .get()
      .then((data) => {
        let token = [];
        data.forEach((doc) => {
          token.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.props.setToken(token);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  FecthVideoStat = () => {
    this.props.loadingVideoStat();
    this.props.firebase
      .videoStatRef()
      .orderBy("CreatedAt", "desc")
      .get()
      .then((data) => {
        let VideoStat = [];
        data.forEach((doc) => {
          VideoStat.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.props.setVideoStat(VideoStat);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  componentDidMount() {
    this.props.firstLoad();
    this.FecthStats();
    this.FecthVideoReq();
    this.FecthVideo();
    this.FecthToken();
    this.FecthVideoStat();
    window.addEventListener("resize", function () {
      document.body.style.overflow = "overlay";
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", function () {
      document.body.style.overflow = "overlay";
    });
  }

  render() {
    const { authUser } = this.state;

    const {
      loading: { stats, videoReq, video, token },
      load,
    } = this.props.db;

    const Loading =
      !(
        stats.videoReq ||
        stats.video ||
        stats.token ||
        videoReq ||
        video ||
        token
      ) && load >= 7;

    const VideoDashboard = Loading ? (
      <Fragment>
        <MediaQuery query="(min-width: 1026px)">
          {/* Comp View */}
          <div
            css={css`
              ${FlexColumn};
              padding: 0 15px 20vh 0;
              width: 100vw;
              overflow: hidden;

              animation: ${keyframes`
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
              `} 0.5s;
            `}
          >
            <div css={FlexRow}>
              <VideoReq />
              <div css={FlexColumn}>
                <TokenStats />
                <ManageToken />
                <GenTokenSec />
              </div>
            </div>
            <div css={FlexRow}>
              <VideoStats />
              <ManageVideo />
            </div>
            <div css={FlexRow}>
              <VideoStat />
            </div>
          </div>
        </MediaQuery>

        <MediaQuery query="(max-width: 1025px)">
          {/* Mobile View */}
          <div
            css={css`
              ${FlexColumn};
              padding-bottom: 20vh;
              width: 100vw;
              overflow: hidden;

              animation: ${keyframes`
                from {
                  opacity: 0;
                }
                to {
                  opacity: 1;
                }
              `} 0.5s;
            `}
          >
            <div
              css={css`
                ${FlexColumn};
                align-items: center;
                position: relative;
              `}
            >
              <h1 css={titleMobile}>Video</h1>
              <VideoStats mobile={true} />
              <VideoReq mobile={true} mobileClick={true} />
              <ManageVideo mobile={true} mobileClick={true} />
              <VideoStat mobile={true} mobileClick={true} />
              <h1 css={titleMobile}>Token</h1>
              <TokenStats mobile={true} />
              <ManageToken mobile={true} mobileClick={true} />
              <GenTokenSec mobile={true} mobileClick={true} />
            </div>
          </div>
        </MediaQuery>
      </Fragment>
    ) : (
      <div
        css={css`
          width: 100%;
          height: 100vh;
          display: flex;
          justify-content: center;
          align-items: center;
        `}
      >
        <div
          css={css`
            ${FlexColumn};
            position: relative;
            justify-content: center;
            align-items: center;
            background: #fff;
            height: 300px;
            width: 300px;
            padding: 50px;
            border-radius: 50%;
            z-index: 1;

            img,
            h1,
            span {
              z-index: 3;
            }

            h1 {
              font-size: 1.3em;
              font-weight: 600;
              margin-bottom: 4px;
            }
            span {
              font-size: 0.9em;
            }
          `}
        >
          <img
            src={Logo}
            alt="Logo Kurniasindo"
            css={css`
              height: 75px;
              margin-bottom: 8px;
            `}
          />
          <svg
            css={css`
              position: absolute;
              top: 0;
              bottom: 0;
              z-index: 2;
              overflow: visible;
              animation: ${keyframes`
                  from {
                    transform: rotate(360deg);
                  }
                  to {
                    transform: rotate(0deg);
                  }
                `} 2s infinite linear;

              circle {
                width: 100%;
                height: 100%;
                fill: none;
                stroke: #5599ff;
                stroke-width: 9;
                stroke-linecap: revert;
              }

              circle:nth-of-type(2) {
                stroke-width: 10;
                stroke: #fff;
                stroke-dashoffset: 500;
                animation: ${keyframes`
                  from,to {
                    stroke-dasharray: 1700;
                  }
                  50% {
                    stroke-dasharray: 1000;
                  }
                `} 5.39s infinite;
              }
            `}
            viewBox="0 0 400 400"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="200" cy="200" r="200" />
            <circle cx="200" cy="200" r="200" />
          </svg>
          <h1>Warming Up the Dashboard</h1>
          <span>Please Wait...</span>
        </div>
      </div>
    );
    return (
      <Fragment>
        <MediaQuery query="(min-width: 1026px)">
          <div
            css={css`
              ${FlexRow};
              align-items: flex-start;
              background: #f3f5f9;
              min-height: 100vh;
            `}
          >
            <NavbarDashboard authUser={authUser} loading={Loading} />
            {VideoDashboard}
          </div>
        </MediaQuery>
        <MediaQuery query="(max-width: 1025px)">
          <div
            css={css`
              ${FlexColumn};
              align-items: center;
              background: #f3f5f9;
              min-height: 100vh;
              max-width: 100vw;
              width: 100vw;
              position: relative;
            `}
          >
            <NavbarDashboard authUser={authUser} loading={Loading} />
            {VideoDashboard}
          </div>
        </MediaQuery>
      </Fragment>
    );
  }
}

dashboard.propTypes = {
  setFooter: PropTypes.func.isRequired,
  setNavbar: PropTypes.func.isRequired,

  loadingStat: PropTypes.func.isRequired,
  loadingVidReq: PropTypes.func.isRequired,
  loadingVid: PropTypes.func.isRequired,
  loadingToken: PropTypes.func.isRequired,
  loadingVideoStat: PropTypes.func.isRequired,

  setStatVidReq: PropTypes.func.isRequired,
  setStatVid: PropTypes.func.isRequired,
  setStatToken: PropTypes.func.isRequired,

  setVidReq: PropTypes.func.isRequired,
  setVid: PropTypes.func.isRequired,
  setToken: PropTypes.func.isRequired,

  setVideoStat: PropTypes.func.isRequired,

  db: PropTypes.object.isRequired,
};

const condition = (authUser) => !!authUser;

const mapStateToProps = (state) => ({
  db: state.db,
});

const mapActionsToProps = {
  loadingStat,
  loadingVidReq,
  loadingVid,
  loadingToken,
  loadingVideoStat,
  setStatVidReq,
  setStatVid,
  setStatToken,
  setVidReq,
  setVid,
  setToken,
  setVideoStat,
  firstLoad,
};

export default compose(
  withAuthorization(condition),
  withFirebase,
  connect(mapStateToProps, mapActionsToProps)
)(dashboard);
