/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const VideoYT = ({ videoId, isVertical, title, notComputer, fullScreen }) => (
  <iframe
    title={title}
    width="100%"
    height="100%"
    src={`https://www.youtube.com/embed/${videoId}`}
    frameBorder="0"
    allowFullScreen={true}
    css={css`
      margin-bottom: 5vh;
      width: ${notComputer ? `40` : `${fullScreen ? `90` : `28`}`}vw;
      height: ${isVertical
        ? `${notComputer ? `71.11` : `${fullScreen ? `160` : `49.78`}`}`
        : `${notComputer ? `22.50` : `${fullScreen ? `50.63` : `15.75`}`}`}vw;
    `}
  />
);

export default VideoYT;
