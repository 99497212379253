import React, { Component, Suspense, lazy } from "react";
import PropTypes from "prop-types";
import "./App.css";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import login from "./pages/login";

import { connect } from "react-redux";

import ScrollToTop from "./components/ScrollToTop";

import Navbar from "./components/Navbar/Navbar.js";
import Footer from "./components/Footer/Footer.js";

import { withAuthentication } from "./components/Sesion";

import home from "./pages/home";
import video from "./pages/video";
import about from "./pages/about";
import product from "./pages/product";
import job from "./pages/job";
import apply from "./pages/apply";
import develop from "./pages/develop";
import redeem from "./pages/redeem";
// import dashboard from "./pages/dashboard";
import dashboard from "./pages/newdashboard";
const error = lazy(() => import("./pages/error"));

class App extends Component {
  state = {
    authUser: null,
  };

  render() {
    const { navbar, footer } = this.props.data;
    return (
      <Router>
        <div className="container">
          <ScrollToTop>
            <Navbar {...navbar} />
            <Switch>
              <Route exact path="/" component={home} />
              <Route path="/about" component={about} />
              <Route path="/product" component={product} />
              <Route path="/job" component={job} />
              <Route path="/apply/:jobId/:dataId" component={apply} />

              <Route path="/under-develop" component={develop} />
              <Redirect from="/video" to="/under-develop" />
              <Redirect from="/redeem" to="/under-develop" />

              <Route path="/admin/test/video" component={video} />
              <Route exact path="/admin/test/redeem" component={redeem} />
              <Route exact path="/admin/redeem/:tokenId" component={redeem} />

              <Route path="/admin/login" component={login} />
              <Route path="/admin/video" component={dashboard} />
              <Suspense fallback={<div />}>
                <Route path="/404" component={error} />
                <Redirect from="*" to="/404" />
              </Suspense>
            </Switch>
            <Footer visible={footer} />
          </ScrollToTop>
        </div>
      </Router>
    );
  }
}

Navbar.protoType = {
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps)(withAuthentication(App));
