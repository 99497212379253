import React, { Component, lazy, Suspense, Fragment } from "react";
import "../css/home.css";

import { connect } from "react-redux";
import { resetNavbar } from "../redux/actions/dataActions";

import whoWeAre from "../image/Home/who-we-are.webp";
import bestTech from "../image/Home/best-tech.webp";
import jobVacancy from "../image/Home/job-vacancy.webp";

import product1 from "../image/Home/New Product/product1.jpg";
import New from "../components/New Product/New";

const VideoBanner = lazy(() => import("../components/VideoBanner/Banner"));
const Info = lazy(() => import("../components/InfoText/Info"));
const Maps = lazy(() => import("./home/Maps"));

const imageNew = [
  {
    image: product1,
    title: "Ombre Rasfur",
    points: [
      `uniqe`,
      `premium quality`,
      `natural color`,
      `combined with the best printing color`,
      `only at kurniasindo`,
    ],
  },
];

const Infos = [
  {
    type: "left",
    title: "Who We Are?",
    text:
      "one of the companies engaged in the textile field by supplying the best textile products",
    button: "About Us",
    background: whoWeAre,
    link: "/about",
    anchorX: "20",
    alt: "City",
  },
  {
    type: "right",
    title: "Meet the Best Technology",
    text: "supported by sophisticated machines and high-grade materials",
    background: bestTech,
    button: "Explore",
    link: "/product",
    anchorY: "40",
    alt: "Colorful yarn",
  },
  {
    type: "left",
    title: "We're hiring!",
    text: "we are pleased to invite you to join our team",
    background: jobVacancy,
    button: "Job Vacancy",
    link: "/job",
    anchorY: "100",
    alt: "Empty chair",
  },
];

class home extends Component {
  componentDidMount() {
    this.props.resetNavbar();
  }

  handleClickIntro() {
    document
      .getElementById("Top")
      .scrollIntoView({ block: "start", behavior: "smooth" });
  }

  render() {
    return (
      <Fragment>
        <section className="Intro">
          <div className="IntroBox">
            <h1 className="title">THE BEST QUALITY TEXTILE IS ON YOUR HAND</h1>
            <div className="seperatorTitle" />
            <button className="ButtonIntro" onClick={this.handleClickIntro}>
              Learn More
            </button>
          </div>
        </section>

        <Suspense fallback={<div />}>
          <div className="titleNew" id="Top">
            New Product
          </div>
          <New images={imageNew} />
        </Suspense>

        <Suspense fallback={<div />}>
          <VideoBanner />
        </Suspense>

        <Suspense fallback={<div />}>
          {Infos.map((c, i) => (
            <Info key={i} {...c} />
          ))}

          <Maps />
        </Suspense>
      </Fragment>
    );
  }
}

export default connect(null, { resetNavbar })(home);
