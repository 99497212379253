import React, { Component, Fragment, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import "../css/product.css";

import { connect } from "react-redux";
import { setNavbar } from "../redux/actions/dataActions";

import { useMediaQuery } from "react-responsive";

import AboutImg from "../image/Product/WhyUs.webp";

//Icon
import star from "../image/svg/star.svg";
import tag from "../image/svg/tag.svg";
import badge from "../image/svg/badge.svg";
import chat from "../image/svg/chat.svg";

//Brands
import brand1 from "../image/Product/Brands/brand1.webp";
import brand2 from "../image/Product/Brands/brand2.webp";
import brand3 from "../image/Product/Brands/brand3.webp";
import brand4 from "../image/Product/Brands/brand4.webp";
import brand5 from "../image/Product/Brands/brand5.webp";

//Client
import client1 from "../image/Product/client/client1.webp";
import client2 from "../image/Product/client/client2.webp";
import client3 from "../image/Product/client/client3.webp";
import client4 from "../image/Product/client/client4.webp";
import client5 from "../image/Product/client/client5.webp";
import client6 from "../image/Product/client/client6.webp";
import client7 from "../image/Product/client/client7.webp";

//Balmut
import Balmut1 from "../image/Product/Balmut/Balmut1.webp";
import Balmut2 from "../image/Product/Balmut/Balmut2.webp";
import Balmut3 from "../image/Product/Balmut/Balmut3.webp";
import Balmut4 from "../image/Product/Balmut/Balmut4.webp";

//Kurnia Blanket
import KBlanket1 from "../image/Product/Kurnia Blanket/KBlanket1.webp";
import KBlanket2 from "../image/Product/Kurnia Blanket/KBlanket2.webp";
import KBlanket3 from "../image/Product/Kurnia Blanket/KBlanket3.webp";
import KBlanket4 from "../image/Product/Kurnia Blanket/KBlanket4.webp";

//Surpet
import Surpet1 from "../image/Product/Surpet/Surpet1.webp";
import Surpet2 from "../image/Product/Surpet/Surpet2.webp";
import Surpet3 from "../image/Product/Surpet/Surpet3.webp";
import Surpet4 from "../image/Product/Surpet/Surpet4.webp";

//Rasfur
import Rasfur1 from "../image/Product/Rasfur/Rasfur1.webp";
import Rasfur2 from "../image/Product/Rasfur/Rasfur2.webp";
import Rasfur3 from "../image/Product/Rasfur/Rasfur3.webp";
import Rasfur4 from "../image/Product/Rasfur/Rasfur4.webp";

//Fleece Blanket
import FBlanket1 from "../image/Product/Fleece Blanket/FBlanket1.webp";
import FBlanket2 from "../image/Product/Fleece Blanket/FBlanket2.webp";
import FBlanket3 from "../image/Product/Fleece Blanket/FBlanket3.webp";
import FBlanket4 from "../image/Product/Fleece Blanket/FBlanket4.webp";

//Baby Blanket
import BBlanket1 from "../image/Product/Baby Blanket/BBlanket1.webp";
import BBlanket2 from "../image/Product/Baby Blanket/BBlanket2.webp";
import BBlanket3 from "../image/Product/Baby Blanket/BBlanket3.webp";
import BBlanket4 from "../image/Product/Baby Blanket/BBlanket4.webp";

const Top = lazy(() => import("../components/Product/Top"));
const WhyUs = lazy(() => import("../components/WhyUs/WhyUs"));
const MoreProduct = lazy(() => import("../components/MoreProduct/MoreProduct"));
const InfoProduct = lazy(() => import("../components/InfoProduct/InfoProduct"));
const ImageProduct = lazy(() => import("../components/ImageProduct"));
const ContactUs = lazy(() => import("../components/Contact/ContactUs"));

const ClientImage = [
  { Image: client1, name: "PT. EKA SARI LORENA TRANSPORT" },
  { Image: client2, name: "PO.Qitarabu Trans" },
  { Image: client3, name: "PO.Semeru Jogja" },
  { Image: client4, name: "Kementerian Sosial Republik Indonesia" },
  { Image: client5, name: "Badan Nasional Penanggulangan Bencana" },
  { Image: client6, name: "PO. AGUNG MULIA TRAVEL & CARGO PALANGKARAYA" },
  { Image: client7, name: "PO.Sumba Putra Lestari" },
];

const BrandsImage = [
  { Image: brand1, name: "Daily" },
  { Image: brand2, name: "Hachiko" },
  { Image: brand3, name: "Butterfly" },
  { Image: brand4, name: "Kurniasindo" },
  { Image: brand5, name: "Kurnia" },
];

const WhyUsInfo = [
  {
    icon: star,
    alt: "star",
    title: "Experienced and Trusted",
    subtitle: "We continue to innovate to increase our customer satisfaction",
  },
  {
    icon: tag,
    alt: "tag",
    title: "Competitive Price",
    subtitle: "We produce the best products at competitive prices",
  },
  {
    icon: badge,
    alt: "badge",
    title: "Quality Guaranteed",
    subtitle:
      "We always carry out regular checks to maintain the quality of our products",
  },
  {
    icon: chat,
    alt: "chat",
    title: "Good Communication",
    subtitle:
      "We recognize the importance of being committed. for that we customer satisfaction is our main commitment",
  },
];

const Products = [
  {
    ranking: 1,
    title: "Kurnia Blankets",
    points: ["Soft and Comfortable", "Excellent Grade Quality"],
    description: `We have long been in creating and innovating our products, especially for blankets. Quality and warmth
    also very guaranteed. And it has been widely used by people in Indonesia.`,
    image: [KBlanket1, KBlanket2, KBlanket3, KBlanket4],
  },
  {
    ranking: 2,
    title: "Balmut",
    points: ["Modern Style", "Custom Character or Design as you want"],
    description: `Utilizing our two top products, we have innovated.
    Named Balmut because it is equipped 
    with a pillow that can also be a blanket.
    We are also the first pioneer of Balmut in Indonesia. 
    Quality and comfort
    it is also very guaranteed.`,
    image: [Balmut1, Balmut2, Balmut3, Balmut4],
  },
  {
    ranking: 3,
    title: "Surpet",
    points: ["Soft, Comfortable, and stylish", "High Quality Material"],
    description: `Surpet is one of the innovations of our products. We make it in such a way as 
    to ensure the satisfaction of our customers.`,
    image: [Surpet4, Surpet1, Surpet2, Surpet3],
  },
  {
    ranking: 4,
    title: "Rasfur",
    points: [
      "The best quality in Indonesia",
      "The Pioneer of inovative  Rasfur",
      "Custom Character or Design as you want",
    ],
    description: `Rasfur fabric itself is a characteristic of kurniasindo. Apart from the fur
    not easily dislodged, the material is soft and high quality.`,
    image: [Rasfur1, Rasfur2, Rasfur3, Rasfur4],
  },
  {
    ranking: 5,
    title: "Flecee Blanket",
    points: [
      "Soft and comfortable",
      "The first pioneer of fleece blanket in Indonesia",
    ],
    description: `Flecee Blanket is a innovation taken from Kurnia Blanket. We
    has been certified as the first pioneer 
    of fleece blankets in Indonesia. Quality and
    comfort is certainly very guaranteed.`,
    image: [FBlanket1, FBlanket2, FBlanket3, FBlanket4],
  },
  {
    ranking: 6,
    title: "Baby Blanket",
    points: [
      "Soft, Comfortable, and modist",
      "Custom Character or Design as you want",
    ],
    description: `Baby Blanket is one of the special products of Kurniasindo which is famous for its softness
    and warmth. Besides that, there are also many motives. And has been widely used in
    Indonesia`,
    image: [BBlanket1, BBlanket2, BBlanket3, BBlanket4],
  },
];

const Product = (props) => {
  const isComputer = useMediaQuery({
    query: "(min-device-width: 1200px)",
  });

  return (
    <Fragment>
      {isComputer ? (
        <section className="OtherProductContent">
          <div>
            {Products.filter((c) => c.ranking > 3)
              .filter((c) => (c.ranking + 1) % 2)
              .sort(function (a, b) {
                return a.ranking - b.ranking;
              })
              .map((c, i) => (
                <MoreProduct
                  key={i}
                  {...c}
                  left={true}
                  onClick={() => props.handleClickProduct({ ...c })}
                />
              ))}
          </div>

          <div className="DividerProduct" />

          <div>
            {Products.filter((c) => c.ranking > 3)
              .filter((c) => !((c.ranking + 1) % 2))
              .sort(function (a, b) {
                return a.ranking - b.ranking;
              })
              .map((c, i) => (
                <MoreProduct
                  key={i}
                  {...c}
                  onClick={() => props.handleClickProduct({ ...c })}
                />
              ))}
          </div>
        </section>
      ) : (
        <section className="OtherProductContent">
          <div>
            {Products.filter((c) => c.ranking > 3)
              .sort(function (a, b) {
                return a.ranking - b.ranking;
              })
              .map((c, i) => (
                <MoreProduct
                  key={i}
                  {...c}
                  onClick={() => props.handleClickProduct({ ...c })}
                />
              ))}
          </div>
        </section>
      )}
    </Fragment>
  );
};

class product extends Component {
  constructor(props) {
    super(props);

    if (Products) {
      this.state = {
        open: false,
        product: {
          points: [],
        },
      };
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.escPress, false);
    this.props.setNavbar({
      startingColor: "#383b3f",
    });
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.escPress, false);
  }

  escPress = (e) => {
    if (e.keyCode === 27 && this.state.open === true) {
      this.setState({ ...this.state, open: false });
      document.body.style.overflow = "overlay";
    }
  };

  handleKeyDown = (e, array) => {
    if (e.key === "Enter" && this.state.open === false) {
      this.setState({ product: array, open: true });
      document.body.style.overflow = "hidden";
    }
  };

  handleClickProduct = (array) => {
    this.setState({
      open: !this.state.open,
      product: array,
    });
    if (this.state.open === false) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "overlay";
    }
  };

  render() {
    return (
      <Fragment>
        <Suspense fallback={<div />}>
          {this.state.open && (
            <InfoProduct
              {...this.state.product}
              onClick={() => this.handleClickProduct(this.state.product)}
            />
          )}
        </Suspense>
        <section className="ProductHeader">
          <h1 className="TitleHeader">ANYTHING YOU WANTED</h1>
          <span className="SubtitleHeader">
            Choose Your Own Style. It's your Choice.
          </span>
        </section>
        <Suspense fallback={<div />}>
          <section className="ProductTitleBox">
            <h2 className="ProductTitle">Our Products</h2>
            <div className="underlineProduct" />
          </section>
          <section className="Top3Product">
            {Products.filter((c) => c.ranking < 4)
              .sort(function (a, b) {
                return a.ranking - b.ranking;
              })
              .map((c, i) => (
                <Top
                  key={i}
                  {...c}
                  onClick={() => this.handleClickProduct({ ...c })}
                  onKeyDown={this.handleKeyDown}
                />
              ))}
          </section>
        </Suspense>

        <Suspense fallback={<div />}>
          <Product handleClickProduct={this.handleClickProduct} />
        </Suspense>

        <Suspense fallback={<div />}>
          <section className="WhyUsWrapper">
            <img src={AboutImg} alt="Why Us" className="WhyUsImage" />
            <div className="WhyUsBoxWrapper">
              <h2 className="WhyUsTitle">Why Us</h2>
              <div className="WhyUsBoxContent">
                {WhyUsInfo.map((c, i) => (
                  <WhyUs key={i} {...c} />
                ))}
              </div>
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div />}>
          <ContactUs />
        </Suspense>

        <Suspense fallback={<div />}>
          <section>
            <div className="TitleBoxed">
              <h2 className="Title">Our Clients</h2>
            </div>
            <div className="Clients">
              {ClientImage.map((c, i) => (
                <ImageProduct key={i} {...c} />
              ))}
            </div>
          </section>
        </Suspense>

        <Suspense fallback={<div />}>
          <section>
            <h2 className="OurBrandsTitle">Our Brands</h2>
            <div className="OurBrandsBox">
              {BrandsImage.map((c, i) => (
                <ImageProduct key={i} {...c} />
              ))}
            </div>
          </section>
        </Suspense>
      </Fragment>
    );
  }
}

product.propTypes = {
  setNavbar: PropTypes.func.isRequired,
};

export default connect(null, { setNavbar })(product);
