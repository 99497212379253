/** @jsx jsx */
import { Component } from "react";
import { css, jsx, keyframes } from "@emotion/core";

import { connect } from "react-redux";
import { setNavbar } from "../redux/actions/dataActions";

import { useMediaQuery } from "react-responsive";

const DevelopCard = () => {
  const isRespond = useMediaQuery({
    query: "(min-device-width: 1026px)",
  });

  return (
    <div
      css={css`
        background: #ffc700;
        padding: 50px;
        padding-left: ${isRespond ? `550px` : `50px`};
        display: flex;
        flex-direction: column;
        border-radius: ${isRespond ? `83px` : `20px`};
        position: relative;
        text-transform: uppercase;
        height: 60vh;
        width: ${isRespond ? `unset` : `100%`};
        justify-content: ${isRespond ? `center` : `flex-start`};
        color: #fff;
        overflow: ${isRespond ? `unset` : `hidden`};
        margin: 2vh 2vw;
        box-shadow: ${isRespond ? `10px -10px` : `4px -4px`} 0px #937300;

        h2 {
          font-weight: 700 !important;
          font-size: ${isRespond ? `2em` : `1em`};
          margin-bottom: ${isRespond ? `5vh` : `2.5vh`};
        }

        h1 {
          font-size: ${isRespond ? `5em` : `2em`};
          width: min-content;
          font-weight: 700 !important;
        }
      `}
    >
      <div
        css={css`
          position: absolute;
          bottom: 0;
          left: ${isRespond ? `0` : `unset`};
          right: ${isRespond ? `unset` : `-50px`};
          display: flex;
          flex-direction: column;
        `}
      >
        <svg
          width="56"
          height="56"
          viewBox="0 0 76 76"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          css={css`
            transform: translate(150px, 0);
            opacity: 0;
            animation: ${keyframes`
                from {
                  opacity: 0;
                  transform: translate(150px, 0);
                  top: 130px;
                }
                45% {
                  opacity: 0.5;
                }
                to {
                  opacity: 0;
                  transform: translate(150px, -35px);
                  top: 95px;
                }
                `} 3s infinite 2s;
          `}
        >
          <circle cx="38" cy="38" r="38" fill="white" />
        </svg>

        <svg
          width="72"
          height="72"
          viewBox="0 0 92 92"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          css={css`
            transform: translate(210px, 0);
            opacity: 0;
            animation: ${keyframes`
                from {
                  opacity: 0;
                  transform: translate(210px, 0);
                }
                30% {
                  opacity: 0.5;
                }
                to {
                  opacity: 0;
                  transform: translate(210px, -25px);
                }
                `} 3s infinite 1s;
          `}
        >
          <circle cx="46" cy="46" r="46" fill="white" />
        </svg>

        <svg
          width="40"
          height="40"
          viewBox="0 0 60 60"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          css={css`
            transform: translate(135px, 0);
            opacity: 0;
            animation: ${keyframes`
                from {
                  opacity: 0;
                  transform: translate(135px, 0);
                }
                40% {
                  opacity: 0.5;
                }
                to {
                  opacity: 0;
                  transform: translate(135px, -50px);
                }
                `} 3s infinite;
          `}
        >
          <circle cx="30" cy="30" r="30" fill="white" />
        </svg>
        <svg
          width="400"
          height="400"
          viewBox="0 0 650 650"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          css={css`
            transform: translateY(2.41px);
            opacity: 0.72;
          `}
        >
          <path
            d="M416 113.918L633.782 476.651C665.08 528.748 649.708 597.291 599.43 629.726C582.555 640.652 562.886 646.475 542.782 646.496H107.185C48.0025 646.496 0 596.771 0 535.378C0 514.611 5.6225 494.298 16.185 476.651L234 113.918C234 36.3773 234 36.4162 234 0.410648C325.406 0.410212 328.238 0.410156 416 0.410212C416 31.1571 416 15.7837 416 113.918ZM325 516.496C333.62 516.496 341.886 513.072 347.981 506.977C354.076 500.882 357.5 492.615 357.5 483.996C357.5 475.376 354.076 467.109 347.981 461.015C341.886 454.92 333.62 451.496 325 451.496C316.38 451.496 308.114 454.92 302.019 461.015C295.924 467.109 292.5 475.376 292.5 483.996C292.5 492.615 295.924 500.882 302.019 506.977C308.114 513.072 316.38 516.496 325 516.496ZM325 223.995C316.38 223.995 308.114 227.419 302.019 233.514C295.924 239.609 292.5 247.875 292.5 256.495V386.495C292.5 395.115 295.924 403.381 302.019 409.476C308.114 415.571 316.38 418.995 325 418.995C333.62 418.995 341.886 415.571 347.981 409.476C354.076 403.381 357.5 395.115 357.5 386.495V256.495C357.5 247.875 354.076 239.609 347.981 233.514C341.886 227.419 333.62 223.995 325 223.995Z"
            fill="white"
          />
        </svg>
      </div>
      <h2>SORRY! WE’RE</h2>
      <h1>Under Development</h1>
    </div>
  );
};

class develop extends Component {
  componentDidMount() {
    this.props.setNavbar({
      StartBG:
        "linear-gradient(103.9deg, rgba(81, 81, 81, 0.68) 0%, rgba(88, 94, 99, 0.68) 100%)",
      noAnimation: true,
    });
  }

  render() {
    return (
      <section
        css={css`
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
      >
        <DevelopCard />
      </section>
    );
  }
}

export default connect(null, { setNavbar })(develop);
