import React, { Component, Fragment, lazy, Suspense } from "react";
import PropTypes from "prop-types";
import "../css/video.css";

import { connect } from "react-redux";
import { setNavbar, loadingData } from "../redux/actions/dataActions";

import { useMediaQuery } from "react-responsive";

import { withFirebase } from "../components/Firebase";
import { withAuthorization } from "../components/Sesion";

import { compose } from "recompose";

import VideoYT from "../components/videoYT";
import LoadingSkeleton from "../components/LoadingSkeleton";

const VideoBanner = lazy(() => import("../components/VideoBanner/Banner"));

// const VideoList = [
//   {
//     id: "1",
//     title: "1",
//     token: "AAAAAA",
//     videoId: "IcrbM1l_BoI",
//     aprove: false,
//     isVertical: false,
//     CreatedAt: new Date().toISOString(),
//   },
//   {
//     id: "2",
//     title: "2",
//     token: "AAAAAB",
//     videoId: "dXM-i__Oen8",
//     aprove: false,
//     isVertical: false,
//     CreatedAt: "2020-07-17T14:00:00Z",
//   },
//   {
//     id: "3",
//     title: "3",
//     token: "AAAABC",
//     videoId: "Tfaq4UTH7P0",
//     aprove: true,
//     isVertical: false,
//     CreatedAt: "2019-10-10T15:00:00Z",
//   },
//   {
//     id: `4`,
//     title: `4`,
//     token: "AAABCD",
//     videoId: "JgIIU2RLip4",
//     aprove: true,
//     isVertical: false,
//     CreatedAt: "2019-03-15T15:00:00Z",
//   },
//   {
//     id: `5`,
//     title: `5`,
//     token: "AABCDE",
//     videoId: "ro76b6i0IfY",
//     aprove: false,
//     isVertical: false,
//     CreatedAt: "2018-09-30T15:00:00Z",
//   },
//   {
//     id: `6`,
//     title: `6`,
//     token: "ABCDEF",
//     videoId: "csOT7oCKRmk",
//     aprove: false,
//     isVertical: false,
//     CreatedAt: "2017-05-01T15:00:00Z",
//   },
//   {
//     id: `7`,
//     title: `7`,
//     token: "ABCDEF",
//     videoId: "lsrw_C7I0s4",
//     aprove: false,
//     isVertical: false,
//     CreatedAt: "2025-12-25T1id00Z",
//   },
// ];

const VideoSec = ({ videoDatas }) => {
  const isComputer = useMediaQuery({
    query: "(min-device-width: 769px)",
  });

  const isHp = useMediaQuery({
    query: "(min-device-width: 426px)",
  });

  return (
    <Fragment>
      {isComputer ? (
        <Fragment>
          {videoDatas.filter((_, i) => (i + 1) % 3 === 1).length !== 0 && (
            <section className="sectionVidV">
              {videoDatas
                .filter((_, i) => (i + 1) % 3 === 1)
                .map((c, i) => (
                  <VideoYT key={i} {...c} />
                ))}
            </section>
          )}
          {videoDatas.filter((_, i) => (i + 1) % 3 === 2).length !== 0 && (
            <section className="sectionVidV">
              {videoDatas
                .filter((_, i) => (i + 1) % 3 === 2)
                .map((c, i) => (
                  <VideoYT key={i} {...c} />
                ))}
            </section>
          )}
          {videoDatas.filter((_, i) => (i + 1) % 3 === 0).length !== 0 && (
            <section className="sectionVidV">
              {videoDatas
                .filter((_, i) => (i + 1) % 3 === 0)
                .map((c, i) => (
                  <VideoYT key={i} {...c} />
                ))}
            </section>
          )}
        </Fragment>
      ) : !isHp ? (
        <Fragment>
          <section className="sectionVidV">
            {videoDatas.map((c, i) => (
              <VideoYT key={i} {...c} fullScreen={true} />
            ))}
          </section>
        </Fragment>
      ) : (
        <Fragment>
          {videoDatas.filter((_, i) => (i + 1) % 2).length !== 0 && (
            <section className="sectionVidV">
              {videoDatas
                .filter((_, i) => (i + 1) % 2)
                .map((c, i) => (
                  <VideoYT
                    key={i}
                    {...c}
                    notComputer={
                      videoDatas.filter((_, i) => !((i + 1) % 2)).length !== 0
                        ? true
                        : false
                    }
                    fullScreen={
                      videoDatas.filter((_, i) => !((i + 1) % 2)).length !== 0
                        ? false
                        : true
                    }
                  />
                ))}
            </section>
          )}
          {videoDatas.filter((_, i) => !((i + 1) % 2)).length !== 0 && (
            <section className="sectionVidV">
              {videoDatas
                .filter((_, i) => !((i + 1) % 2))
                .map((c, i) => (
                  <VideoYT key={i} {...c} notComputer={true} />
                ))}
            </section>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

class video extends Component {
  state = {
    videoDatas: [],
    videoDis: [],
  };

  componentDidMount() {
    this.props.setNavbar({
      startingColor: "#383b3f",
      hidden: false,
    });
    this.props.loadingData();
    this.props.firebase
      .videoRef()
      .orderBy("CreatedAt", "desc")
      .orderBy("title", "asc")
      .where("distributor", "==", true)
      .where("aprove", "==", true)
      .get()
      .then((data) => {
        let video = [];
        data.forEach((doc) => {
          video.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.setState({
          ...this.state,
          videoDis: video,
        });
      })
      .then(() => {
        this.props.firebase
          .videoRef()
          .orderBy("CreatedAt", "desc")
          .orderBy("title", "asc")
          .where("distributor", "==", false)
          .where("aprove", "==", true)
          .get()
          .then((data) => {
            let video = [];
            data.forEach((doc) => {
              video.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.setState({
              ...this.state,
              videoDatas: video,
            });
            this.props.loadingData();
          });
      })
      .catch((err) => {
        console.error(err);
        this.setState({
          ...this.state,
          videoDatas: [],
        });
        this.props.loadingData();
      });
  }

  render() {
    const { loading } = this.props.data;
    const { videoDatas, videoDis } = this.state;

    const Skeleton = () => {
      return (
        <Fragment>
          <LoadingSkeleton height="49.78vw" width="28vw" margin="0 0 5vh 0" />
          <LoadingSkeleton height="15.75vw" width="28vw" margin="0 0 5vh 0" />
          <LoadingSkeleton height="49.78vw" width="28vw" margin="0 0 5vh 0" />
        </Fragment>
      );
    };

    const Skeleton2 = () => {
      return (
        <Fragment>
          <LoadingSkeleton height="15.75vw" width="28vw" margin="0 0 5vh 0" />
          <LoadingSkeleton height="49.78vw" width="28vw" margin="0 0 5vh 0" />
          <LoadingSkeleton height="15.75vw" width="28vw" margin="0 0 5vh 0" />
        </Fragment>
      );
    };

    // const Skeleton3 = () => {
    //   return (
    //     <Fragment>
    //       <LoadingSkeleton
    //         height="15.75vw"
    //         width="28vw"
    //         margin="0 0 5vh 0"
    //         background1="rgba(255,255,255,1)"
    //         opacity="30%"
    //       />
    //       <LoadingSkeleton
    //         height="15.75vw"
    //         width="28vw"
    //         margin="0 0 5vh 0"
    //         background1="rgba(255,255,255,1)"
    //         opacity="30%"
    //       />
    //       <LoadingSkeleton
    //         height="15.75vw"
    //         width="28vw"
    //         margin="0 0 5vh 0"
    //         background1="rgba(255,255,255,1)"
    //         opacity="30%"
    //       />
    //     </Fragment>
    //   );
    // };

    const Videos = !loading ? (
      Object.keys(videoDatas).length !== 0 ? (
        <VideoSec videoDatas={videoDatas} />
      ) : Object.keys(videoDis).length !== 0 ? (
        <h1 style={{ textAlign: "center" }}>
          Be the first one to upload a video
        </h1>
      ) : (
        <h1 style={{ textAlign: "center" }}>
          Sorry, there’s no uploaded videos currently
        </h1>
      )
    ) : (
      <Fragment>
        <section className="sectionVidV">
          <Skeleton />
        </section>
        <section className="sectionVidV">
          <Skeleton2 />
        </section>
        <section className="sectionVidV">
          <Skeleton />
        </section>
      </Fragment>
    );

    const VideoDis = !loading
      ? Object.keys(videoDis).length !== 0 && (
          <section className="sectionDis">
            <h1 className="disTitle">HIGHLIGTED DISTRIBUTORS</h1>
            <section className="sectionVid dis">
              <VideoSec videoDatas={videoDis} />
            </section>
          </section>
        )
      : null;

    return (
      <Fragment>
        <Suspense fallback={<div />}>
          <VideoBanner oneLink={true} margin="15vh 0" />
          {VideoDis}
          <section className="sectionVid">{Videos}</section>
        </Suspense>
      </Fragment>
    );
  }
}

video.protoType = {
  setNavbar: PropTypes.func.isRequired,
  loadingData: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
});

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
  connect(mapStateToProps, { setNavbar, loadingData })
)(video);
