/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Fragment } from "react";

/**
 * @function Nav
 */

const Nav = (props) => {
  return (
    <Fragment>
      <div
        css={css`
          width: 70vw;
          height: 100vh;
          position: fixed;
          display: flex;
          flex-direction: column;
          top: 0;
          ${props.left
            ? `
        left: ${props.open ? `0` : `-100%`};
        `
            : `
        right: ${props.open ? `-15px` : `-100%`};
        `}
          background: ${props.background ? props.background : "#26262b"};
          color: #fff;
          transition: 0.5s;
          z-index: 101;
        `}
        className={props.className}
      >
        {props.children}
      </div>
      <div
        css={css`
          height: 100%;
          top: ${props.open ? `0` : `-100%`};
          bottom: 0;
          left: 0;
          right: -15px;
          position: fixed;
          background: rgba(0, 0, 0, 0.5);
          opacity: ${props.open ? `1` : `0`};
          transition: opacity 0.5s;
          z-index: 100;
        `}
      />
    </Fragment>
  );
};

export default Nav;
