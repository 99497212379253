/** @jsx jsx */
import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { css, jsx } from "@emotion/core";

import Section from "../../components/Dashboard/Section";
import WhiteDoc from "../../components/Dashboard/WhiteDoc";
import VideoCheck from "../../components/Dashboard/VideoCheck";

import { FlexRow, Splitter, WrapperList, numPerPage } from "./essentials";

import { connect } from "react-redux";
import {
  updateState,
  loadingVid,
  setVid,
  setIdVid,
} from "../../redux/actions/dbActions";

import { useMediaQuery } from "react-responsive";

import { withFirebase } from "../../components/Firebase";
import { compose } from "recompose";

const ManageVideoWD = ({
  videoDisplay,
  mobile,
  mobileClick,
  _handleChangeDis,
  choose,
  videoAll,
  videoAccept,
  videoDecline,
  videoDis,
  handleResetIdVideo,
  handleStatusVC,
  handleDisVC,
  handleDeleteVC,
  chooseVideoId,
  handleIdVC,
  handleDeleteIdVC,
  handleBackPageVC,
  handleNextPageVC,
  videoP,
}) => {
  const Responsive = useMediaQuery({
    query: "(max-width: 1440px)",
  });

  return (
    <WhiteDoc
      title="Manage Videos"
      subtitle="Videos that has been accepted or declined will show up here"
      styleContent={{
        textAlign: videoDisplay.length === 0 ? `center` : "left",
        justifyContent: videoDisplay.length === 0 ? `center` : "unset",
        minHeight: "20vw",
      }}
      mobile={mobile}
      mobileClick={mobileClick}
    >
      {videoDisplay.length === 0 ? (
        `No Aprove Video yet`
      ) : (
        <Fragment>
          <div css={WrapperList}>
            <button
              onClick={() => _handleChangeDis("")}
              css={css`
                color: ${choose === "" ? `#5599FF` : `unset`};
              `}
            >{`All(${videoAll})`}</button>
            <button
              onClick={() => _handleChangeDis("acc")}
              css={css`
                color: ${choose === "acc" ? `#5599FF` : `unset`};
              `}
            >{`Accepted(${videoAccept})`}</button>
            <button
              onClick={() => _handleChangeDis("dec")}
              css={css`
                color: ${choose === "dec" ? `#5599FF` : `unset`};
              `}
            >{`Declined(${videoDecline})`}</button>
            <button
              onClick={() => _handleChangeDis("dis")}
              css={css`
                color: ${choose === "dis" ? `#5599FF` : `unset`};
              `}
            >{`Distributor(${videoDis})`}</button>
          </div>
          <div css={Splitter} />

          <div css={FlexRow}>
            <svg
              onClick={() => {
                if (!(chooseVideoId.length === 0)) {
                  handleResetIdVideo();
                }
              }}
              role="button"
              tabIndex={0}
              viewBox="0 -6.5 10 16"
              fill="#47B2FF"
              xmlns="http://www.w3.org/2000/svg"
              css={css`
                border: 2px solid #383b3f;
                border-radius: 4px;
                margin: 0 1vw;
                height: 1em;
                width: 1em;
                background: ${!(chooseVideoId.length === 0)
                  ? `#646464`
                  : `transparent`};
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                content-visibility: ${!(chooseVideoId.length === 0)
                  ? `visible`
                  : `hidden`};
                transition: 0.1s;
              `}
            >
              <path
                d="M10 1C10 1.4424 9.76082 1.8002 9.31842 1.8002H0.680019C0.238419 1.8002 0 1.4424 0 1C0 0.5576 0.238419 0.200195 0.680019 0.200195H9.31922C9.76082 0.200195 10 0.5576 10 1Z"
                fill="white"
              />
            </svg>
            <span
              css={css`
                margin-left: calc(${mobile ? `100px + 2vw` : `160px + 1vw`});
                margin-right: 1vw;
                flex: 1;
                text-align: left;
              `}
            >
              TITLE
            </span>
            {!mobile && (
              <Fragment>
                <span
                  css={css`
                    margin-right: 1vw;
                    width: 100px;
                    text-align: center;
                  `}
                >
                  TOKEN
                </span>
                <span
                  css={css`
                    width: 100px;
                    margin-right: calc(2vw + 31px);
                    text-align: center;
                  `}
                >
                  STATUS
                </span>
              </Fragment>
            )}
          </div>
          <div css={Splitter} />
          <VideoCheck
            videos={
              choose === ""
                ? videoDisplay
                : choose === "acc"
                ? videoDisplay.filter((c) => c.aprove === true)
                : choose === "dec"
                ? videoDisplay.filter((c) => c.aprove === false)
                : videoDisplay.filter((c) => c.distributor === true)
            }
            handleStatus={handleStatusVC}
            handleDis={handleDisVC}
            handleDelete={handleDeleteVC}
            chooseVideoId={chooseVideoId}
            handleId={handleIdVC}
            mobile={mobile}
            Responsive={Responsive}
          />
          <div css={Splitter} />
          <div
            css={css`
              ${FlexRow};
              justify-content: center;
              align-items: center;
              font-size: 1em;
            `}
          >
            <svg
              tabIndex={0}
              onClick={() => {
                if (!(chooseVideoId.length === 0)) {
                  handleDeleteIdVC();
                }
              }}
              role="button"
              viewBox="0 0 20 23"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={css`
                margin: 0 1vw;
                height: 1.2em;
                width: 1.2em;
              `}
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.75 1.5C1.35218 1.5 0.970644 1.65804 0.68934 1.93934C0.408035 2.22064 0.25 2.60218 0.25 3V4.5C0.25 4.89782 0.408035 5.27936 0.68934 5.56066C0.970644 5.84196 1.35218 6 1.75 6H2.5V19.5C2.5 20.2956 2.81607 21.0587 3.37868 21.6213C3.94129 22.1839 4.70435 22.5 5.5 22.5H14.5C15.2956 22.5 16.0587 22.1839 16.6213 21.6213C17.1839 21.0587 17.5 20.2956 17.5 19.5V6H18.25C18.6478 6 19.0294 5.84196 19.3107 5.56066C19.592 5.27936 19.75 4.89782 19.75 4.5V3C19.75 2.60218 19.592 2.22064 19.3107 1.93934C19.0294 1.65804 18.6478 1.5 18.25 1.5H13C13 1.10218 12.842 0.720644 12.5607 0.43934C12.2794 0.158035 11.8978 0 11.5 0L8.5 0C8.10218 0 7.72064 0.158035 7.43934 0.43934C7.15804 0.720644 7 1.10218 7 1.5H1.75ZM6.25 7.5C6.44891 7.5 6.63968 7.57902 6.78033 7.71967C6.92098 7.86032 7 8.05109 7 8.25V18.75C7 18.9489 6.92098 19.1397 6.78033 19.2803C6.63968 19.421 6.44891 19.5 6.25 19.5C6.05109 19.5 5.86032 19.421 5.71967 19.2803C5.57902 19.1397 5.5 18.9489 5.5 18.75V8.25C5.5 8.05109 5.57902 7.86032 5.71967 7.71967C5.86032 7.57902 6.05109 7.5 6.25 7.5ZM10 7.5C10.1989 7.5 10.3897 7.57902 10.5303 7.71967C10.671 7.86032 10.75 8.05109 10.75 8.25V18.75C10.75 18.9489 10.671 19.1397 10.5303 19.2803C10.3897 19.421 10.1989 19.5 10 19.5C9.80109 19.5 9.61032 19.421 9.46967 19.2803C9.32902 19.1397 9.25 18.9489 9.25 18.75V8.25C9.25 8.05109 9.32902 7.86032 9.46967 7.71967C9.61032 7.57902 9.80109 7.5 10 7.5ZM14.5 8.25C14.5 8.05109 14.421 7.86032 14.2803 7.71967C14.1397 7.57902 13.9489 7.5 13.75 7.5C13.5511 7.5 13.3603 7.57902 13.2197 7.71967C13.079 7.86032 13 8.05109 13 8.25V18.75C13 18.9489 13.079 19.1397 13.2197 19.2803C13.3603 19.421 13.5511 19.5 13.75 19.5C13.9489 19.5 14.1397 19.421 14.2803 19.2803C14.421 19.1397 14.5 18.9489 14.5 18.75V8.25Z"
                fill={chooseVideoId.length === 0 ? "#A7A7A7" : "#FF2424"}
              />
            </svg>
            <div
              css={css`
                flex: 1;
              `}
            />
            <svg
              tabIndex={0}
              role="button"
              onClick={handleBackPageVC}
              viewBox="0 0 5 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={css`
                height: 0.8em;
                width: 0.8em;
              `}
            >
              <path
                d="M0.708837 3.745L3.0655 0.449167C3.26384 0.169167 3.58467 0 3.92884 0C4.798 0 5.29967 0.98 4.798 1.68583L3.08884 4.08333L4.798 6.48084C5.3055 7.18667 4.798 8.16667 3.92884 8.16667C3.58467 8.16667 3.258 7.9975 3.05967 7.7175L0.703003 4.42167C0.563004 4.2175 0.563004 3.94917 0.708837 3.745Z"
                fill="#535353"
              />
            </svg>
            <span
              css={css`
                margin: 0 16px;
              `}
            >
              {videoP}/
              {Math.ceil(videoAll / numPerPage) === 0
                ? "1"
                : Math.ceil(videoAll / numPerPage)}
            </span>
            <svg
              tabIndex={0}
              role="button"
              onClick={handleNextPageVC}
              viewBox="0 0 5 9"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              css={css`
                height: 0.8em;
                width: 0.8em;
              `}
            >
              <path
                d="M4.29116 3.745L1.9345 0.449167C1.73616 0.169167 1.41533 0 1.07116 0C0.201999 0 -0.299668 0.98 0.201998 1.68583L1.91116 4.08333L0.201998 6.48084C-0.305502 7.18667 0.201999 8.16667 1.07116 8.16667C1.41533 8.16667 1.742 7.9975 1.94033 7.7175L4.297 4.42167C4.437 4.2175 4.437 3.94917 4.29116 3.745Z"
                fill="#535353"
              />
            </svg>
          </div>
        </Fragment>
      )}
    </WhiteDoc>
  );
};

class ManageVideo extends Component {
  state = {
    videoP: 1,
    choose: "",
  };

  handleResetIdVideo = () => {
    this.props.setIdVid([]);
  };

  handleDeleteVC = (id, a, d) => {
    var arr = {};
    var array = [...this.props.db.videos];
    var i = array.findIndex((c) => c.id === id);
    array.splice(i, 1);
    arr = {
      videos: array,
      videoAll: this.props.db.videoAll - 1,
    };
    if (d) arr = { ...arr, videoDis: this.props.db.videoDis - 1 };
    if (a) {
      arr = { ...arr, videoAccept: this.props.db.videoAccept - 1 };
    } else {
      arr = { ...arr, videoDecline: this.props.db.videoDecline - 1 };
    }
    this.props.updateState(arr);
  };

  handleStatusVC = (id, a, t) => {
    var arr = {};
    var array = [...this.props.db.videos];
    var i = array.findIndex((c) => c.id === id);
    array.unshift({
      ...array[i],
      aprove: a,
      CreatedAt: t,
    });
    array.splice(i + 1, 1);
    arr = {
      videos: array,
    };
    if (a) {
      arr = {
        ...arr,
        videoAccept: this.props.db.videoAccept + 1,
        videoDecline: this.props.db.videoDecline - 1,
      };
    } else {
      arr = {
        ...arr,
        videoAccept: this.props.db.videoAccept - 1,
        videoDecline: this.props.db.videoDecline + 1,
      };
    }
    this.props.updateState(arr);
  };

  handleDisVC = (id, a) => {
    var arr = {};
    var array = [...this.props.db.videos];
    var i = array.findIndex((c) => c.id === id);
    array[i] = {
      ...array[i],
      distributor: a,
    };
    arr = {
      videos: array,
    };
    if (a) {
      arr = {
        ...arr,
        videoDis: this.props.db.videoDis + 1,
      };
    } else {
      arr = {
        ...arr,
        videoDis: this.props.db.videoDis - 1,
      };
    }
    this.props.updateState(arr);
  };

  handleBackPageVC = () => {
    if (this.state.videoP !== 1) {
      this.setState({
        videoP: this.state.videoP - 1,
      });
    }
  };

  handleNextPageVC = () => {
    const { videos, videoAll } = this.props.db;
    const { videoP } = this.state;
    if (videoP < Math.ceil(videoAll / numPerPage)) {
      var n = videos.length;
      if (n / numPerPage < videoP + 1 && n !== videoAll) {
        this.fetchMoreDataVC(numPerPage - (n % numPerPage));
      }
      this.setState({
        videoP: videoP + 1,
      });
    }
  };

  fetchMoreDataVC = (n) => {
    this.props.loadingVid();
    const { videos } = this.props.db;
    const lastVisible = videos[videos.length - 1];
    this.props.firebase
      .videoRef()
      .orderBy("CreatedAt", "desc")
      .orderBy("title", "asc")
      .startAfter(lastVisible.CreatedAt, lastVisible.title)
      .limit(n)
      .get()
      .then((data) => {
        let video = videos;
        data.forEach((doc) => {
          video.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.props.setVid(video);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  handleIdVC = (c) => {
    var a = this.props.db.chooseVideoId;
    var i = a.indexOf(c);
    if (i < 0) {
      a.push(c);
    } else {
      a.splice(i, 1);
    }
    this.props.setIdVid(a);
  };

  handleDeleteIdVC = () => {
    const {
      videos,
      chooseVideoId,
      videoAll,
      videoAccept,
      videoDecline,
      videoDis,
    } = this.props.db;
    const { videoRef, StatRef } = this.props.firebase;
    const batch = this.props.firebase.batch();
    var a = chooseVideoId;
    var arr = {};
    var array = videos;

    var size = a.length;
    var aprove = 0;
    var distributor = 0;

    a.forEach((c) => {
      var i = array
        .map((e) => {
          return e.id;
        })
        .indexOf(c);
      if (array[i].aprove === true) aprove++;
      if (array[i].distributor === true) distributor++;

      array.splice(i, 1);
      batch.delete(videoRef().doc(c));
    });

    var decline = size - (aprove + distributor);

    arr = {
      videos: array,
      videoAll: videoAll - size,
      videoAccept: videoAccept - aprove,
      videoDis: videoDis - distributor,
      videoDecline: videoDecline - decline,
    };

    StatRef()
      .doc("Video")
      .update({
        size: videoAll - size,
        accepted: videoAccept - aprove,
        decline: videoDecline - decline,
        distributor: videoDis - distributor,
      });

    batch.commit();

    this.props.updateState(arr);
    this.props.setIdVid([]);
  };

  _handleChangeDis = (c) => {
    this.setState({
      choose: c,
    });
  };

  render() {
    const {
      db: {
        videos,
        videoAll,
        videoAccept,
        videoDecline,
        videoDis,
        chooseVideoId,
      },
      mobile,
      mobileClick,
    } = this.props;
    const { videoP, choose } = this.state;

    const videoDisplay = videos
      .sort((a, b) => {
        return a.token < b.token ? -1 : a.token > b.token ? 1 : 0;
      })
      .sort((a, b) => {
        return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
      })
      .sort((a, b) => {
        return a.CreatedAt < b.CreatedAt
          ? -1
          : a.CreatedAt > b.CreatedAt
          ? 1
          : 0;
      })
      .sort((a, b) => {
        return b.distributor - a.distributor;
      })
      .filter((_, i) => i >= (videoP - 1) * numPerPage)
      .filter((_, i) => i <= numPerPage - 1 + (videoP - 1) * numPerPage);

    const WD = (
      <ManageVideoWD
        videoDisplay={videoDisplay}
        mobile={mobile}
        mobileClick={mobileClick}
        choose={choose}
        videoAll={videoAll}
        videoAccept={videoAccept}
        videoDecline={videoDecline}
        videoDis={videoDis}
        chooseVideoId={chooseVideoId}
        videoP={videoP}
        _handleChangeDis={this._handleChangeDis}
        handleResetIdVideo={this.handleResetIdVideo}
        handleStatusVC={this.handleStatusVC}
        handleDisVC={this.handleDisVC}
        handleDeleteVC={this.handleDeleteVC}
        handleIdVC={this.handleIdVC}
        handleDeleteIdVC={this.handleDeleteIdVC}
        handleBackPageVC={this.handleBackPageVC}
        handleNextPageVC={this.handleNextPageVC}
      />
    );

    return !mobile ? (
      <Section
        title="Manage Videos"
        style={{ width: `calc(85vw - 320px)`, minWidth: `50%` }}
      >
        {WD}
      </Section>
    ) : (
      WD
    );
  }
}

ManageVideo.propTypes = {
  updateState: PropTypes.func.isRequired,
  loadingVid: PropTypes.func.isRequired,
  setVid: PropTypes.func.isRequired,
  setIdVid: PropTypes.func.isRequired,
  db: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  db: state.db,
});

export default compose(
  withFirebase,
  connect(mapStateToProps, {
    updateState,
    loadingVid,
    setVid,
    setIdVid,
  })
)(ManageVideo);
