/** @jsx jsx */
import { useState } from "react";
import { css, jsx } from "@emotion/core";

const DropDown = ({
  list,
  error,
  width,
  zIndex,
  placeholder,
  maxH,
  handleChange,
  theme,
}) => {
  const [state, setState] = useState({
    open: false,
    selectedIndex: null,
  });
  const { selectedIndex, open } = state;

  const handleClickOpen = () => {
    setState({
      ...state,
      open: !open,
    });
  };

  const handleClickChoose = (i) => {
    if (selectedIndex !== i && open !== false) {
      setState({
        ...state,
        selectedIndex: i,
        open: false,
      });
    }
    handleChange(list[i]);
  };

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        flex-direction: column;
        margin-top: 1vh;
        color: #383b3f;
        width: ${width ? width : `15vw`};
      `}
    >
      <div
        css={css`
          padding: 10px 8px;
          border: 2px solid
            ${error
              ? `${theme === "light" ? "#FF7591" : "#af2239"}`
              : `#383b3f`};
          border-radius: ${open ? `8px 8px 0 0` : `8px`};
          transition: 0.5s;
          background: #fff;
          z-index: ${zIndex ? zIndex : "2"};
          color: ${selectedIndex === null ? "#8F8F8F" : "#383b3f"};
        `}
        onClick={handleClickOpen}
      >
        {selectedIndex !== null
          ? list[selectedIndex]
          : placeholder
          ? placeholder
          : "Choose"}
      </div>
      <ul
        css={css`
          position: absolute;
          padding: 8px 0;
          margin: 0;
          margin-top: calc(1em + ${open ? `24px` : `4px`});
          transition: 0.5s, border 0.75s, background 0.25s;
          list-style-type: none;
          border: 2px solid
            ${open
              ? `${
                  error
                    ? `${theme === "light" ? "#FF7591" : "#af2239"}`
                    : `#383b3f`
                }`
              : `transparent`};
          border-radius: 0 0 8px 8px;
          max-height: ${open ? (maxH ? maxH : `100px`) : `0px`};
          overflow-y: ${open ? `auto` : `hidden`};
          width: calc(${width ? width : `15vw`} - 4px);
          background: ${open ? `#fff` : `transparent`};
          z-index: ${zIndex ? zIndex - 1 : "1"};
        `}
      >
        {list.map((c, i) => (
          <li
            key={i}
            css={css`
              padding: 8px 0 8px 8px;
              transition: 0.4s;
              user-select: none;
              ${open &&
              `:hover {
                background: #e0e0e0;
              }`}
            `}
            onClick={() => handleClickChoose(i)}
          >
            {c}
          </li>
        ))}
      </ul>
    </div>
  );
};
export default DropDown;
