import {
  LOADING_DATA,
  SET_APPLY_JOB,
  SET_APPLY_FORM,
  SET_NAVBAR,
  RESET_NAVBAR,
  SET_FOOTER,
} from "../types";

const initialState = {
  jobs: [],
  job: {},
  apply: {},
  form: [],
  navbar: {
    // color: "",
    // startingColor: "",
    // StartBG: "",
    // background: "",
    // position: "",
    // noAnimation: false,
  },
  footer: true,
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: !state.loading,
      };
    case SET_APPLY_JOB:
      return {
        ...state,
        apply: action.payload,
      };
    case SET_APPLY_FORM:
      const form = {
        ...state.form,
        [action.name]: action.value,
      };
      return {
        ...state,
        form: form,
      };
    case SET_NAVBAR:
      return {
        ...state,
        navbar: action.payload,
      };
    case RESET_NAVBAR:
      return {
        ...state,
        navbar: initialState.navbar,
      };
    case SET_FOOTER:
      return {
        ...state,
        footer: action.payload,
      };
    default:
      return state;
  }
}
