/** @jsx jsx */
import { css, jsx } from "@emotion/core";

const Section = (props) => {
  return (
    <section
      style={props.style}
      css={css`
        margin: 2vh 1vw;
        position: relative;
        // width: 90%;
      `}
    >
      <h1
        css={css`
          font-size: 1.5em;
          color: #0064fa;
          padding-left: 10px;
          border-left: 2px solid #0064fa;
          margin-bottom: 2vh;
        `}
      >
        {props.title}
      </h1>
      {props.children}
    </section>
  );
};

export default Section;
