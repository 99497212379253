import React, { useState, useEffect } from "react";
import "./Navbar.css";
import Nav from "./Nav";

import Logo from "../../image/logo.webp";
import { Link } from "react-router-dom";

import { useMediaQuery } from "react-responsive";

const Navbar = (props) => {
  const [state, setState] = useState({
    open: false,
  });

  const { open } = state;

  var color = `${props.color ? props.color : "#fff"}`;
  var startingColor = `${props.startingColor ? props.startingColor : color}`;
  var StartBG = `${props.StartBG ? props.StartBG : "transparent"}`;
  var background = `${props.background ? props.background : "#26262b"}`;
  var position = `${props.position ? props.position : "fixed"}`;
  var transition = `all 0.5s, top 0.8s, width 0s`;

  useEffect(() => {
    if (!props.hidden) {
      var prevScrollpos = window.pageYOffset;
      var nav = document.querySelector(".Navbar").style;
      nav.background = `${StartBG}`;
      nav.boxShadow = `${
        StartBG === "transparent" ? "" : "0px 2px 4px rgba(0, 0, 0, 0.15)"
      }`;
      nav.color = `${startingColor}`;
      nav.top = "0";

      window.onscroll = function () {
        _closeMenu();
        if (!props.noAnimation) {
          var currentScrollPos = window.pageYOffset;
          if (prevScrollpos > currentScrollPos) {
            nav.transition = "all 0.5s, top 0.2s, width 0s";
            nav.top = "0";
            nav.color = color;
          } else {
            nav.transition = "all 0.5s, top 0.8s, width 0s";
            nav.top = "-100%";
            nav.color = color;
            nav.fill = color;
          }
          if (currentScrollPos > 100) {
            nav.background = `${background}`;
            nav.boxShadow = "0px 2px 4px rgba(0, 0, 0, 0.15)";
          } else {
            nav.background = `${StartBG}`;
            nav.boxShadow = `${
              StartBG === "transparent" ? "" : "0px 2px 4px rgba(0, 0, 0, 0.15)"
            }`;
            nav.color = `${startingColor}`;
            nav.top = "0";
          }
          prevScrollpos = currentScrollPos;
        }
      };
    }
  });

  const _closeMenu = () => {
    if (open === true) {
      setState({
        ...state,
        open: false,
      });
      document.removeEventListener("keydown", _escPress);
      document.removeEventListener("click", _closeMenu);
    }
  };

  const _handleClick = () => {
    setState({
      ...state,
      open: true,
    });
    document.addEventListener("keydown", _escPress);
    document.addEventListener("click", _closeMenu);
  };

  const _escPress = (e) => {
    if (e.keyCode === 27 && open === true) {
      setState({
        ...state,
        open: false,
      });
      document.removeEventListener("keydown", _escPress, false);
      document.removeEventListener("click", _closeMenu, false);
    }
  };

  const _handleKeyDown = (e) => {
    if (e.key === "Enter" && open === false) {
      setState({
        ...state,
        open: true,
      });
      document.addEventListener("keydown", _escPress, false);
      document.addEventListener("click", _closeMenu, false);
    }
  };

  const _handleKeyDownContent = (e) => {
    if (e.key === "Enter" && open === true) {
      setState({
        ...state,
        open: false,
      });
      document.removeEventListener("keydown", _escPress, false);
      document.removeEventListener("click", _closeMenu, false);
    }
  };

  const _handleClickContent = (e) => {
    if (open === true) {
      setState({
        ...state,
        open: false,
      });
      document.removeEventListener("keydown", _escPress, false);
      document.removeEventListener("click", _closeMenu, false);
    }
  };

  document.addEventListener("click", _closeMenu, false);
  document.addEventListener("keydown", _escPress, false);

  const isComputer = useMediaQuery({
    query: "(min-device-width: 769px)",
  });

  const NavDrawer = (
    <Nav open={open} onClick={_handleClick} className="NavDrawer">
      <Link
        to="/"
        onKeyDown={_handleKeyDownContent}
        onClick={_handleClickContent}
      >
        Home
      </Link>
      <Link
        to="/about"
        onKeyDown={_handleKeyDownContent}
        onClick={_handleClickContent}
      >
        About us
      </Link>
      <Link
        to="/product"
        onKeyDown={_handleKeyDownContent}
        onClick={_handleClickContent}
      >
        Product
      </Link>
      <Link
        to="/video"
        onKeyDown={_handleKeyDownContent}
        onClick={_handleClickContent}
      >
        Video
      </Link>
      <Link
        to="/job"
        onKeyDown={_handleKeyDownContent}
        onClick={_handleClickContent}
      >
        Job Vacancy
      </Link>
    </Nav>
  );

  const compView = (
    <div className="Nav">
      <Link to="/">Home</Link>
      <Link to="/about">About us</Link>
      <Link to="/product">Product</Link>
      <Link to="/video">Video</Link>
      <Link to="/job">Job Vacancy</Link>
    </div>
  );

  return (
    !props.hidden && (
      <section
        className="Navbar"
        style={{
          color: `${startingColor}`,
          fill: `${startingColor}`,
          background: `${StartBG}`,
          boxShadow: `${
            StartBG === "transparent" ? "" : "0px 2px 4px rgba(0, 0, 0, 0.15)"
          }`,
          position: `${position}`,
          transition: `${transition}`,
        }}
      >
        <div className="Navheader">
          <img src={Logo} alt="Logo Kurniasindo" className="logoNav" />
          <div className="TitleNavBox">
            <h1 className="TitleNav">KURNIASINDO</h1>
            <span className="SubtitleNav">Knitting & Garment</span>
          </div>
        </div>
        {isComputer ? (
          compView
        ) : (
          <section className="Nav">
            <svg
              onClick={_handleClick}
              onKeyDown={_handleKeyDown}
              focusable="false"
              className="navBars"
              role="button"
              alt="Pages"
              viewBox="0 0 448 512"
              xmlns="http://www.w3.org/2000/svg"
              tabIndex="1"
            >
              <path
                fill="currentColor"
                d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"
              />
            </svg>
            {NavDrawer}
          </section>
        )}
      </section>
    )
  );
};

export default Navbar;
