import React, { Component, lazy, Suspense, Fragment } from "react";
import PropTypes from "prop-types";
import "../css/about.css";

import { connect } from "react-redux";
import { setNavbar } from "../redux/actions/dataActions";

import about1 from "../image/About/about1.webp";
import about2 from "../image/About/about2.webp";

const Info = lazy(() => import("../components/InfoText/Info"));
const VisiMisi = lazy(() => import("./about/VisiMisi"));

const Infos = [
  {
    type: "left",
    text:
      "Our product is made as the best product that you only seen at our company. We are the first pioneer of fleece blanket and rasfur printing. We made our inovation by looking our customers needs. As an experienced company we know very well that customer satisfaction is the most important thing. We present products with various colors, patterns and attractive designs. We strive with hard work and committed to providing services and products with the best quality. ",
    background: about1,
    noButton: true,
    alt: "Quality Product",
  },
  {
    type: "right",
    text:
      "Supported by sophisticated machines and high-quality raw materials we do regular checks to maintain the quality of our products we also improved our management to improve the effectiveness of service to our customers. ",
    background: about2,
    noButton: true,
    alt: "Modern Machines",
  },
];

class about extends Component {
  componentDidMount() {
    this.props.setNavbar({
      color: "#fff",
      StartBG: "rgba(0,0,0,0.2)",
    });
  }

  render() {
    return (
      <Fragment>
        <section className="AboutHeader">
          <div role="img" aria-label="background" className="AboutImage">
            <div className="AboutHeaderContent">
              <svg
                focusable="false"
                data-icon="medal"
                role="img"
                alt="1st medal"
                viewBox="0 0 512 512"
                className="AboutImageContent"
              >
                <path
                  fill="currentColor"
                  d="M127.18 0H16A16 16 0 0 0 2.92 25.18l111.27 159a207 207 0 0 1 109.56-53.39L154.62 15.54A32 32 0 0 0 127.18 0zM496 0H384.82a32 32 0 0 0-27.44 15.54l-69.13 115.21a207 207 0 0 1 109.56 53.38L509.08 25.18A16 16 0 0 0 496 0z"
                />
                <path
                  fill="currentColor"
                  d="M256 160a176 176 0 1 0 176 176 176 176 0 0 0-176-176zm92.52 157.26l-37.93 37 9 52.22a11.48 11.48 0 0 1-16.65 12.09L256 393.88l-46.9 24.65a11.48 11.48 0 0 1-16.65-12.09l9-52.22-37.93-37a11.49 11.49 0 0 1 6.35-19.59l52.4-7.63 23.43-47.52a11.5 11.5 0 0 1 20.61 0L289.74 290l52.43 7.64a11.49 11.49 0 0 1 6.35 19.62z"
                />
              </svg>
              <h1 className="AboutHeaderTitle">
                Pioneer of fleece blanket and rasfur printing
              </h1>
            </div>
          </div>
        </section>

        <Suspense fallback={<div />}>
          <VisiMisi />
        </Suspense>

        <Suspense fallback={<div />}>
          {Infos.map((c, i) => (
            <Info key={i} {...c} />
          ))}
        </Suspense>
      </Fragment>
    );
  }
}

about.propTypes = {
  setNavbar: PropTypes.func.isRequired,
};

export default connect(null, { setNavbar })(about);
