import React, { Fragment } from "react";
import PropTypes from "prop-types";
import "../css/redeem.css";

import NumberedTitle from "../components/NumberedTitle/NumberedTitle";

import Input from "../components/Input";

import { withFirebase } from "../components/Firebase";
import { withAuthorization } from "../components/Sesion";

import { connect } from "react-redux";
import { setFooter, setNavbar } from "../redux/actions/dataActions";

import { compose } from "recompose";

import QrReader from "react-qr-scanner";

const steps = [
  {
    title: "Getting the Redeem Code",
    desc:
      "After you bought our product, you will be given a redeem code that you can exchange in here.",
  },
  {
    title: "Exchanging the Redeem Code",
    desc: "Exchange the redeem code in here at www.kurniasindo.com/redeem",
  },
  {
    title: "Uploading the Video",
    desc:
      "After you've successfully redeemed the code, paste the Youtube's video link in the space given to start sharing the video.",
  },
  {
    title: "Done",
    desc:
      'Once you think the data is corrent, upload the video and see yours at the "video" section',
  },
];

const API_KEY = "AIzaSyAtJz4OIhFODQM7oVC8J-SgL5AcBVf51Kk";

class Redeem extends React.Component {
  state = {
    token: "",
    videoId: "",
    videoLink: "",
    videoTitle: "",
    videoDuration: "",
    store: "",
    city: "",
    error: {
      videoId: "",
      token: "",
      store: "",
      city: "",
      scan: "",
    },
    loading: false,
    verify: false,

    QRdisplay: false,
  };

  componentDidMount() {
    if (this.props.match.params.tokenId) {
      this.setState({
        token: this.props.match.params.tokenId,
      });
    }
    this.props.setNavbar({
      position: "relative",
      StartBG:
        "linear-gradient(160.55deg, rgba(81, 81, 81, 0.68) 0%, rgba(88, 94, 99, 0.68) 100%)",
    });
    this.props.setFooter(false);
  }

  componentWillUnmount() {
    this.props.setFooter(true);
  }

  _handleChangeVideo = (value) => {
    this.setState({
      ...this.state,
      videoLink: value,
    });
  };

  Loading = (value) => {
    this.setState({
      loading: value,
    });
  };

  handleChangeVideo = () => {
    this.setState({
      verify: false,
      videoId: "",
    });
  };

  handleCheckVideo = () => {
    this.Loading(true);
    const { videoLink } = this.state;
    if (videoLink !== "" && videoLink !== undefined) {
      var regExp = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      var match = videoLink.match(regExp);
      if (match) {
        fetch(
          `https://www.googleapis.com/youtube/v3/videos?part=snippet%2CcontentDetails&fields=items(id%2Csnippet(title%2Cthumbnails%2CdefaultLanguage)%2CcontentDetails(duration))&key=${API_KEY}&id=${match[1]}`
        )
          .then((res) => res.json())
          .then((result) => {
            const res = result.items[0];
            var reg = /((\d+)H)?((\d+)M)?(\d+)S/;
            var resExp = res.contentDetails.duration.match(reg);

            var h = resExp[2];
            var m = resExp[4];
            var s = resExp[5];

            if (h !== undefined) if (h.length === 1) h = `0${h}`;
            if (m !== undefined) if (m.length === 1) m = `0${m}`;
            if (s !== undefined) if (s.length === 1) s = `0${s}`;

            var duration = [
              h ? h : `00`,
              m ? m : `00`,
              h ? s : m ? s : `${s} sec`,
            ];

            this.setState({
              verify: true,
              videoId: match[1],
              videoLink: match[0].split(match[1])[0] + match[1],
              videoTitle: res.snippet.title,
              videoDuration: `${duration[0]}:${duration[1]}:${duration[2]}`,
            });
            this.Loading(false);
          })
          .catch((err) => {
            console.error(err);
            this.setState({
              error: {
                ...this.state.error,
                videoId: "not a valid youtube video",
              },
            });
            this.Loading(false);
          });
      } else {
        this.setState({
          error: {
            ...this.state.error,
            videoId: "not valid youtube link",
          },
        });
        this.Loading(false);
      }
    } else {
      this.setState({
        error: {
          ...this.state.error,
          videoId: "Please enter your video link here",
        },
      });
      this.Loading(false);
    }
  };

  handleChangeToken = (value) => {
    this.setState({
      token: value,
    });
  };

  handleChangeStore = (value) => {
    this.setState({
      store: value,
    });
  };

  handleChangeCity = (value) => {
    this.setState({
      city: value,
    });
  };

  handleSumbitVideo = () => {
    this.Loading(true);
    let Time = new Date().toISOString();
    const { videoTitle, videoId, token, store, city } = this.state;
    const {
      videoReqRef,
      tokenRef,
      StatRef,
      videoStatRef,
    } = this.props.firebase;
    if (token !== "" && token !== undefined) {
      if (store !== "" && city !== "") {
        videoReqRef()
          .add({
            title: videoTitle,
            token,
            videoId,
            isVertical: false,
            CreatedAt: Time,
          })
          .then(() => {
            console.log("1");
            videoStatRef()
              .add({
                store,
                city,
                token,
                CreatedAt: Time,
              })
              .then(() => {
                console.log("2");
                tokenRef()
                  .doc(token)
                  .update({
                    used: true,
                  })
                  .catch((err) => {
                    console.error(err);
                  });
                StatRef()
                  .doc("Token")
                  .get()
                  .then((doc) => {
                    doc.ref.update({
                      used: doc.data().used + 1,
                      avaliable: doc.data().avaliable - 1,
                    });
                  })
                  .catch((err) => {
                    console.error(err);
                  });
                StatRef()
                  .doc("VideoReq")
                  .get()
                  .then((doc) => {
                    doc.ref.update({
                      request: doc.data().request + 1,
                      unreview: doc.data().unreview + 1,
                    });
                  })
                  .then(() => {
                    this.props.history.push(`/admin/test/video`);
                    this.Loading(false);
                  })
                  .catch((err) => {
                    console.error(err);
                  });
              });
          })
          .catch((err) => {
            console.error(err);
            if (store === "") {
              this.setState({
                error: {
                  ...this.state.error,
                  store: "Please enter the store name",
                },
              });
            }
            if (city === "") {
              this.setState({
                error: {
                  ...this.state.error,
                  city: "Please enter the city name",
                },
              });
            }
            this.setState({
              error: {
                ...this.state.error,
                token: "Redeem Code is not valid. Please Try again.",
              },
            });
            this.Loading(false);
          });
      } else {
        if (store === "") {
          this.setState({
            error: {
              ...this.state.error,
              store: "Please enter the store name",
            },
          });
        }
        if (city === "") {
          this.setState({
            error: {
              ...this.state.error,
              city: "Please enter the city name",
            },
          });
        }
        this.Loading(false);
      }
    } else {
      this.setState({
        error: {
          ...this.state.error,
          token: "Please enter your redeem code to sumbit video",
        },
      });
      this.Loading(false);
    }
  };

  handleOpenVideo = () => {
    this.setState({
      QRdisplay: !this.state.QRdisplay,
    });
  };

  handleScan = (data) => {
    if (data) {
      var regExp = /^(?:https?:\/\/)?(?:www\.)?(?:kurniasindo\.com(?:\/admin\/test\/redeem\/))(.{6})(?:\S+)?$/;
      const token = data.match(regExp);
      this.setState({
        token: token[1],
        QRdisplay: false,
      });
    }
  };

  handleError = (err) => {
    console.log(err);
    this.setState({
      error: {
        ...this.state.error,
        token: "There's something wrong with the QRCode",
      },
    });
  };

  render() {
    const {
      videoTitle,
      videoDuration,
      videoLink,
      videoId,
      error,
      loading,
      verify,
      token,
      city,
      store,
      QRdisplay,
    } = this.state;

    const ButtonSubmit = ({ onClick }) => {
      return (
        <button className="sumbitBottom" onClick={onClick} disabled={loading}>
          Submit
        </button>
      );
    };

    const Splitter = {
      height: "2px",
      width: "100%",
      backgroundColor: "#383b3f",
      opacity: "0.5",
      margin: "5vh 0",
    };

    const ButtonScan = ({ onClick }) => {
      return (
        <button
          className="sumbitBottom scanBottom"
          onClick={onClick}
          disabled={loading}
        >
          Scan For QR Code
        </button>
      );
    };

    return (
      <Fragment>
        <section className="header-wrapperRedeem">
          <h1 className="titleRedeem">REDEEM YOUR CODE</h1>
          <span className="subtitleRedeem">
            Exchange your redeem code after you bought our product here!
          </span>
        </section>
        <div className="content-wrapperRedeem">
          <section className="cardRedeem">
            {verify ? (
              <Fragment>
                <a
                  href={videoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="videoLink"
                >
                  <svg
                    viewBox="0 0 19 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ width: "1em", height: "1em", margin: "0 4px 0 0" }}
                  >
                    <path
                      d="M6.70215 8.94034C7.59911 8.04338 9.16344 8.04338 10.0604 8.94034L10.6201 9.50005L11.7395 8.38063L11.1798 7.82092C10.4333 7.07359 9.43895 6.66113 8.38128 6.66113C7.32361 6.66113 6.32928 7.07359 5.58274 7.82092L3.90282 9.50005C3.16205 10.2432 2.74609 11.2497 2.74609 12.299C2.74609 13.3483 3.16205 14.3548 3.90282 15.0979C4.27005 15.4657 4.70634 15.7573 5.1866 15.9559C5.66686 16.1545 6.18164 16.2563 6.70136 16.2553C7.22122 16.2564 7.73616 16.1547 8.21657 15.9561C8.69699 15.7575 9.13339 15.4658 9.5007 15.0979L10.0604 14.5382L8.94099 13.4188L8.38128 13.9785C7.93515 14.4226 7.33127 14.672 6.70176 14.672C6.07225 14.672 5.46836 14.4226 5.02224 13.9785C4.57772 13.5326 4.32811 12.9286 4.32811 12.299C4.32811 11.6693 4.57772 11.0654 5.02224 10.6195L6.70215 8.94034Z"
                      fill="#383b3f"
                    />
                    <path
                      d="M9.4986 3.90152L8.93889 4.46123L10.0583 5.58064L10.618 5.02094C11.0641 4.5768 11.668 4.32746 12.2975 4.32746C12.927 4.32746 13.5309 4.5768 13.9771 5.02094C14.4216 5.46686 14.6712 6.07082 14.6712 6.70046C14.6712 7.3301 14.4216 7.93405 13.9771 8.37998L12.2971 10.0591C11.4002 10.9561 9.83585 10.9561 8.93889 10.0591L8.37918 9.49939L7.25977 10.6188L7.81947 11.1785C8.56602 11.9259 9.56035 12.3383 10.618 12.3383C11.6757 12.3383 12.67 11.9259 13.4166 11.1785L15.0965 9.49939C15.8372 8.75624 16.2532 7.74975 16.2532 6.70046C16.2532 5.65117 15.8372 4.64467 15.0965 3.90152C14.3535 3.16037 13.347 2.74414 12.2975 2.74414C11.2481 2.74414 10.2415 3.16037 9.4986 3.90152Z"
                      fill="#383b3f"
                    />
                  </svg>
                  {videoLink}
                </a>
                <div className="videoDetails">
                  <img
                    src={`https://img.youtube.com/vi/${videoId}/hqdefault.jpg`}
                    alt={videoTitle}
                    width="300"
                    height="225"
                  />
                  <div>
                    <h2>{videoTitle}</h2>
                    <span>
                      <svg
                        viewBox="0 0 47 47"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{
                          width: "1em",
                          height: "1em",
                          margin: "0 4px 0 0",
                        }}
                      >
                        <path
                          d="M23.5 2.9375C12.1447 2.9375 2.9375 12.1447 2.9375 23.5C2.9375 34.8553 12.1447 44.0625 23.5 44.0625C34.8553 44.0625 44.0625 34.8553 44.0625 23.5C44.0625 12.1447 34.8553 2.9375 23.5 2.9375ZM23.5 40.5742C14.0725 40.5742 6.42578 32.9275 6.42578 23.5C6.42578 14.0725 14.0725 6.42578 23.5 6.42578C32.9275 6.42578 40.5742 14.0725 40.5742 23.5C40.5742 32.9275 32.9275 40.5742 23.5 40.5742Z"
                          fill="#383b3f"
                        />
                        <path
                          d="M31.5185 29.3107L24.9733 24.5786V13.2188C24.9733 13.0168 24.8081 12.8516 24.6062 12.8516H22.3984C22.1965 12.8516 22.0312 13.0168 22.0312 13.2188V25.8592C22.0312 25.9785 22.0863 26.0887 22.1827 26.1575L29.7743 31.6929C29.9396 31.8122 30.169 31.7755 30.2884 31.6148L31.6011 29.8248C31.7204 29.655 31.6837 29.4255 31.5185 29.3107Z"
                          fill="#383b3f"
                        />
                      </svg>
                      {videoDuration}
                    </span>
                    <div style={{ flex: 1 }} />
                    <span className="wrongVideo">
                      Wrong Video?{" "}
                      <span onClick={this.handleChangeVideo}>Change Video</span>
                    </span>
                  </div>
                </div>

                <div style={Splitter} />
                <Input
                  type="oneLetterInput"
                  length={6}
                  error={error.token}
                  title="Please type the redeem code to upload video."
                  alignAll="center"
                  margin="0 0 2vh 0"
                  handleChange={this.handleChangeToken}
                  width="90%"
                  value={token}
                />

                <ButtonScan onClick={() => this.handleOpenVideo()} />

                {QRdisplay && (
                  <QrReader
                    delay={500}
                    onError={this.handleError}
                    onScan={this.handleScan}
                    style={{ width: "100%" }}
                  />
                )}

                <div style={Splitter} />

                <section style={{ width: "70%" }}>
                  <Input
                    error={error.store}
                    title="Store name"
                    placeholder="Store"
                    margin="0 0 2vh 0"
                    handleChange={this.handleChangeStore}
                    width="100%"
                    value={store}
                    style={{
                      border: "none",
                      padding: "10px 20px",
                      borderRadius: "20px",
                    }}
                  />
                  <Input
                    error={error.city}
                    title="What city do you live in?"
                    placeholder="Bandung"
                    margin="0 0 2vh 0"
                    handleChange={this.handleChangeCity}
                    width="100%"
                    value={city}
                    style={{
                      border: "none",
                      padding: "10px 20px",
                      borderRadius: "20px",
                    }}
                  />
                </section>

                <ButtonSubmit onClick={() => this.handleSumbitVideo()} />
              </Fragment>
            ) : (
              <Fragment>
                <Input
                  error={error.videoId}
                  title="Enter the Youtube link that you want to share"
                  alignAll="center"
                  placeholder="https://www.youtube.com/watch?v=xxxxxxxxxxx"
                  margin="0 0 2vh 0"
                  width="90%"
                  handleChange={this._handleChangeVideo}
                />

                <ButtonSubmit onClick={() => this.handleCheckVideo()} />
              </Fragment>
            )}
          </section>
          <section className="stepsRedeem">
            <h4 className="step-headerRedeem">How it works?</h4>
            <NumberedTitle
              unClickable={true}
              items={steps}
              padding="0"
              margin="2vh 5vw 0 2vw"
            />
          </section>
        </div>
      </Fragment>
    );
  }
}

Redeem.protoType = {
  setFooter: PropTypes.func.isRequired,
  setNavbar: PropTypes.func.isRequired,
};

const condition = (authUser) => !!authUser;

export default compose(
  withAuthorization(condition),
  withFirebase,
  connect(null, { setFooter, setNavbar })
)(Redeem);
