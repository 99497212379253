/** @jsx jsx */
import { Component, createRef } from "react";
import { css, jsx } from "@emotion/core";

const LetterInput = ({ placeholder, error, content, theme }) => {
  return (
    <div
      css={css`
        background: none;
        border: transparent;
        border-bottom: 2px solid
          ${error ? `${theme === "light" ? "#FF7591" : "#af2239"}` : "#383b3f"};
        margin-right: 1vh;
        font-size: 3em;
        width: 1em;
        text-align: center;
        text-transform: uppercase;
        color: ${content ? "#383b3f" : "rgba(56, 59, 63, 0.5)"};
        user-select: ${content ? "unset" : "none"};
        &:last-child {
          margin-right: 0;
        }
      `}
    >
      {content ? content : placeholder}
    </div>
  );
};

const Aplhabeth = [...Array(26)]
  .map((_, y) => String.fromCharCode(y + 65))
  .join("");

class OneLetterInput extends Component {
  input = createRef();

  state = {
    value: "",
    focused: false,
  };

  componentDidMount() {
    if (this.props.value !== "") {
      this.setState({
        value: this.props.value,
      });
    } else {
      this.setState({
        value: "",
      });
    }
  }

  static getDerivedStateFromProps(nextProps, nextState) {
    if (nextProps.value !== nextState.value) {
      return {
        value: nextProps.value,
      };
    } else return null;
  }

  _handleClick = () => {
    this.input.current.focus();
  };

  _handleFocus = () => {
    this.setState({ focused: true });
  };

  _handleBlur = () => {
    this.setState({
      focused: false,
    });
  };

  _handleChangeInput = (e) => {
    const value = e.target.value;
    const { length } = this.props;

    if (!(this.state.value.length >= length)) {
      this.setState((state) => {
        return {
          value: (state.value + value).slice(0, length),
        };
      });
      this.props.handleChange(
        (this.state.value + value).slice(0, length).toUpperCase()
      );
    }
  };

  _handleOnKeyDown = (e) => {
    if (e.key === "Backspace" && this.state.value.length - 1 !== -1) {
      this.setState((state) => {
        return {
          value: state.value.slice(0, state.value.length - 1),
        };
      });
      this.props.handleChange(
        this.state.value.slice(0, this.state.value.length - 1)
      );
    }
  };

  render() {
    const { length, error, theme } = this.props;

    const { value } = this.state;
    const values = value.split("");

    const selectedIndex = values.length < length ? values.length : length - 1;

    const hideInput = !(values.length < length);

    return (
      <div
        css={css`
          display: inline-block;
        `}
      >
        <div
          css={css`
            position: relative;
            display: flex;
            flex-direction: row;
          `}
          onClick={this._handleClick}
        >
          <input
            ref={this.input}
            value=""
            onFocus={this._handleFocus}
            onBlur={this._handleBlur}
            onChange={this._handleChangeInput}
            onKeyDown={this._handleOnKeyDown}
            css={css`
              position: absolute;
              border: none;
              font-size: 3em;
              text-align: center;
              background-color: transparent;
              outline: none;
              width: 1em;
              top: 0px;
              bottom: 0px;
              left: calc(${selectedIndex}em + ${selectedIndex}vh);
              opacity: ${hideInput ? 0 : 1};
            `}
          />
          {Array.from(Array(length)).map((_, i) => (
            <LetterInput
              content={values[i]}
              key={i}
              error={error}
              placeholder={Aplhabeth[i]}
              theme={theme}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default OneLetterInput;
