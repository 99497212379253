import {
  // LOADING_UI,
  // STOP_LOADING_UI,
  LOADING_DATA,
  SET_APPLY_JOB,
  SET_APPLY_FORM,
  // SET_JOB,
  // ADD_JOB,
  // DELETE_JOB,
  // UPDATE_JOB,
  SET_NAVBAR,
  RESET_NAVBAR,
  SET_FOOTER,
} from "../types";

export const loadingData = () => (dispatch) => {
  dispatch({ type: LOADING_DATA });
};

export const applyJob = (data) => (dispatch) => {
  dispatch({ type: SET_APPLY_JOB, payload: data });
};

export const handleChangeApply = (name, value) => (dispatch) => {
  dispatch({ type: SET_APPLY_FORM, payload: { name: name, value: value } });
};

export const setNavbar = (value) => (dispatch) => {
  dispatch({ type: SET_NAVBAR, payload: value });
};

export const setFooter = (value) => (dispatch) => {
  dispatch({ type: SET_FOOTER, payload: value });
};

export const resetNavbar = () => (dispatch) => {
  dispatch({ type: RESET_NAVBAR });
};
