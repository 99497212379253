/** @jsx jsx */
import { Component } from "react";
import PropTypes from "prop-types";
import { css, jsx } from "@emotion/core";

import Section from "../../components/Dashboard/Section";
import WhiteDoc from "../../components/Dashboard/WhiteDoc";
import Input from "../../components/Input";

import { connect } from "react-redux";
import { setToken, updateState } from "../../redux/actions/dbActions";

import {
  FlexColumn,
  ButtonGenerate,
  maxRandom,
  handleRandoming,
  tokenLength,
} from "./essentials";

import { withFirebase } from "../../components/Firebase";
import { compose } from "recompose";

const GenWD = ({
  error,
  handleChangeAuto,
  randomGen,
  GenerateToken,
  manualToken,
  handleChangeManual,
  ManualGenToken,
  WrapperGenerate,
  mobile,
  mobileClick,
}) => {
  return (
    <WhiteDoc
      title="Generate Tokens"
      subtitle="Generate or make your own token(s) here"
      direction={mobile ? "column" : "row"}
      styleContent={{
        justifyContent: mobile ? `flex-start` : `space-between`,
      }}
      mobile={mobile}
      mobileClick={mobileClick}
    >
      <div css={WrapperGenerate}>
        <Input
          title="Random Generator"
          titleSize="1.2em"
          titleColor="#0064fa"
          width="90%"
          max={maxRandom}
          placeholder={`max. ${maxRandom}`}
          inputType="number"
          error={error.randomGen}
          theme="light"
          handleChange={handleChangeAuto}
          value={randomGen}
        />
        <button css={ButtonGenerate} onClick={GenerateToken}>
          GENERATE
        </button>
      </div>
      <div
        css={css`
          min-width: ${mobile ? `100%` : `2px`};
          height: ${mobile ? `2px` : `100%`};
          background: #383b3f;
          margin: ${mobile ? `5vh 0` : `unset`};
        `}
      />
      <div css={WrapperGenerate}>
        <Input
          title="Manual"
          titleSize="1.2em"
          titleColor="#0064fa"
          type="area"
          width="90%"
          maxWidth="10vw"
          height="10vh"
          maxHeight="10vh"
          placeholder="One token per line"
          error={error.randomGen}
          theme="light"
          value={manualToken}
          handleChange={handleChangeManual}
        />
        <button css={ButtonGenerate} onClick={ManualGenToken}>
          ADD
        </button>
      </div>
    </WhiteDoc>
  );
};

class GenTokenSec extends Component {
  state = {
    randomGen: "",
    manualToken: "",
    error: {
      randomGen: "",
      manualToken: "",
    },
  };

  handleChangeAuto = (e) => {
    var n = Number(e);
    if (n <= maxRandom && n >= 0) {
      this.setState({
        randomGen: n,
      });
    } else if (n > maxRandom && this.state.randomGen !== maxRandom) {
      this.setState({
        randomGen: maxRandom,
      });
    }
  };

  handleChangeManual = (e) => {
    this.setState({
      manualToken: e.toUpperCase(),
    });
  };

  handleAddToken = (a, s, N, repl) => {
    const { tokenRef } = this.props.firebase;
    const batch = this.props.firebase.batch();

    var set = handleRandoming(a, N);

    var n = 0;

    var arrTest = [];

    tokenRef()
      .get()
      .then((data) => {
        data.forEach((doc) => {
          arrTest.push(doc.id);
          if (set.has(doc.id)) {
            console.log(`there's seem to be a problem with token ${doc.id}`);
            set.delete(doc.id);
            if (repl) {
              var random = doc.id;
              while (arrTest.indexOf(random) < 0) {
                random = Array(N)
                  .join()
                  .split(",")
                  .map(function () {
                    return s.charAt(Math.floor(Math.random() * s.length));
                  })
                  .join("");
                if (arrTest.indexOf(random) < 0 && a.indexOf(random) < 0) {
                  set.add(random);
                }
              }
              console.log(`${doc.id} is remove and change with ${random}`);
            } else {
              console.log(
                `Token with the same id has been yeet out of existence`
              );
            }
            n++;
          }
        });

        console.log(`checked with firebase(${n} items)...`);

        if (set.size > 0) {
          const arr = this.props.db.tokens;
          var arr2 = [];

          arr.forEach((c) => {
            arr2.push(c.token);
          });

          set.forEach((c) => {
            if (arr2.indexOf(c) < 0) {
              var obj = {
                token: c,
                CreatedAt: new Date().toISOString(),
                used: false,
                disable: false,
              };
              var arrRef = tokenRef().doc(c);
              batch.set(arrRef, obj);
              arr.unshift(obj);
            }
          });

          var arraySortted = arr
            .sort((a, b) => {
              return a.token < b.token ? -1 : a.token > b.token ? 1 : 0;
            })
            .sort((a, b) => {
              return a.CreatedAt < b.CreatedAt
                ? -1
                : a.CreatedAt > b.CreatedAt
                ? 1
                : 0;
            });

          var updatedArr = {
            tokens: arraySortted,
            tokenAll: this.props.db.tokenAll + set.size,
            tokenAvaliable: this.props.db.tokenAvaliable + set.size,
          };

          this.props.updateState(updatedArr);

          batch.commit();

          this.props.firebase
            .StatRef()
            .doc("Token")
            .get()
            .then((doc) => {
              doc.ref.update({
                size: doc.data().size + set.size,
                avaliable: doc.data().avaliable + set.size,
              });
            });

          console.log("done...");
          console.log(arr);
        } else {
          console.log("there's nothing to update :/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  GenerateToken = () => {
    if (this.state.randomGen !== 0 && this.state.randomGen !== "") {
      const N = tokenLength;
      var s = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";

      var n = this.state.randomGen;
      if (this.state.randomGen > maxRandom) {
        n = maxRandom;
        this.setState({
          randomGen: maxRandom,
        });
      }

      console.log("starting...");

      var a = [...Array(n)].map(() =>
        Array(N)
          .join()
          .split(",")
          .map(function () {
            return s.charAt(Math.floor(Math.random() * s.length));
          })
          .join("")
      );

      console.log("Randoming...");

      this.handleAddToken(a, s, N, true);
    }
  };

  ManualGenToken = () => {
    var s = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    var a = this.state.manualToken.split(" ").join("").split("\n");
    a.forEach((c, i) => {
      if (c.length !== 6) {
        console.log(c, " is not a valid Token");
        a.splice(i, 1);
        this.setState({
          error: {
            ...this.state.error,
            manualToken: "There's some token with less than 6 letter",
          },
        });
      }
    });
    if (a.length > 0) {
      this.handleAddToken(a, s, tokenLength, false);
    }
  };

  render() {
    const { randomGen, manualToken, error } = this.state;
    const { mobile, mobileClick } = this.props;

    const WrapperGenerate = css`
      ${FlexColumn};
      justify-content: space-between;
      width: 95%;
    `;

    const WD = (
      <GenWD
        error={error}
        randomGen={randomGen}
        manualToken={manualToken}
        handleChangeAuto={this.handleChangeAuto}
        GenerateToken={this.GenerateToken}
        handleChangeManual={this.handleChangeManual}
        ManualGenToken={this.ManualGenToken}
        mobile={mobile}
        mobileClick={mobileClick}
        WrapperGenerate={WrapperGenerate}
      />
    );

    return !mobile ? <Section title="Generate Token">{WD}</Section> : WD;
  }
}

GenTokenSec.propTypes = {
  setToken: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
  db: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  db: state.db,
});

export default compose(
  withFirebase,
  connect(mapStateToProps, { setToken, updateState })
)(GenTokenSec);
