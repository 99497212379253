/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import { Fragment, useState } from "react";

const DropDown = ({ children, list, margin }) => {
  const [state, setState] = useState({
    open: false,
    x: null,
    y: null,
  });

  const { open, x, y } = state;

  const closeDropDown = () => {
    setState({
      ...state,
      open: false,
    });
    document.removeEventListener("click", closeDropDown);
  };

  const handleDropDown = (e) => {
    if (open === false) {
      setState({
        ...state,
        open: true,
        x: e.nativeEvent.clientX - 200,
        y: e.nativeEvent.clientY,
      });
      document.addEventListener("click", closeDropDown);
    }
  };

  return (
    <Fragment>
      <button
        onClick={handleDropDown}
        tabIndex="0"
        css={css`
          margin: ${margin};
        `}
      >
        {children}
      </button>
      {open && (
        <ul
          id="DropDown"
          css={css`
            list-style-type: none;
            padding: 0;
            margin: 0;
            width: 200px;
            background: #fff;
            overflow: overlay;
            position: fixed;
            box-shadow: 0px 1px 6px rgba(41, 46, 52, 0.5);
            border-radius: 8px 0 8px 8px;
            z-index: 101;
            top: ${y}px;
            left: ${x}px;
            overflow: hidden;
            box-sizing: border-box;

            li {
              margin: 7.5px 0;
            }

            li:first-of-type {
              margin: 7.5px 0;
            }

            li:last-child {
              margin: 7.5px 0;
            }
          `}
        >
          {list}
        </ul>
      )}
    </Fragment>
  );
};

export default DropDown;
