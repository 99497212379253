import React, { Component } from "react";
import PropTypes from "prop-types";
import "../css/apply.css";

import NumberedTitle from "../components/NumberedTitle/NumberedTitle";
import Input from "../components/Input";

import { connect } from "react-redux";
import { setNavbar } from "../redux/actions/dataActions";

const List = [
  { title: `Personal Data` },
  { title: `Contact Address` },
  { title: `Education Background` },
  { title: `Job History` },
  { title: `Other Information` },
  { title: `Done` },
];

const Page1 = [
  {
    title: "Fullname",
    type: "text",
  },
  {
    title: "Nama Lengkap",
    type: "text",
    error: "error",
  },
  {
    title: "Gender",
    type: "DropDown",
    list: ["Male", "Female", "Prefer not say", "WHAT WRONG WITH U"],
  },
  {
    title: "Jenis Kelamin",
    type: "DropDown",
    list: ["Male", "Female", "Prefer not say", "WHAT WRONG WITH U"],
    error: "error code bisa di ubah :v",
  },
  {
    title: "Birthday",
    type: "Date",
  },
  {
    title: "Tanggal Lahir",
    type: "Date",
    error: "dunt worrie",
  },
];

const HandleLength = (i, a) => {
  const L = a.length + 100;
  let n = 0;
  if (i === 0) return L;
  for (let j = 0; j < i; j++) {
    if (a[j].type !== "text") n++;
  }
  return L - i - n;
};

class apply extends Component {
  state = {};
  constructor(props) {
    super(props);

    this.props.setNavbar({
      position: "relative",
      StartBG:
        "linear-gradient(120deg, rgba(0, 113, 219, 0.68), rgba(0, 116, 224, 0.68)",
    });
  }

  handleReqChange = (i) => {
    this.props.history.push(`${i}`);
  };

  render() {
    const { apply } = this.props.data;
    return (
      <div className="apply">
        <div className="JobApply">
          <span>applied job</span>
          <h1>{apply.name}</h1>
        </div>

        <div className="ApplyBox">
          <section className="ApplyBox">
            <NumberedTitle
              items={List}
              currentIndex={this.props.match.params.dataId}
              handleReqChange={this.handleReqChange}
            />
            <div className="Split" />
          </section>
          <section className="form">
            {Page1.map((c, i, a) => (
              <Input key={i} {...c} zIndex={HandleLength(i, a)} />
            ))}
          </section>
        </div>
      </div>
    );
  }
}

apply.propTypes = {
  setNavbar: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
});

export default connect(mapStateToProps, { setNavbar })(apply);
