import {
  LOADING_STAT,
  LOADING_VID_REQ,
  LOADING_VID,
  LOADING_TOKEN,
  LOADING_VIDEO_STAT,
  FETCH_STAT_VID_REQ,
  FETCH_STAT_VID,
  FETCH_STAT_TOKEN,
  FETCH_VID_REQ,
  FETCH_VID,
  FETCH_TOKEN,
  FIRST_LOAD,
  FETCH_VIDEO_STAT,
  UPDATE_STATE,
  SET_TOKEN_ID,
  SET_VID_ID,
  SET_VID_STAT_ID,
} from "../types";

//First Load
export const firstLoad = () => (dispatch) => {
  dispatch({ type: FIRST_LOAD });
};

//Loading
export const loadingStat = () => (dispatch) => {
  dispatch({ type: LOADING_STAT });
};

export const loadingVidReq = () => (dispatch) => {
  dispatch({ type: LOADING_VID_REQ });
};

export const loadingVid = () => (dispatch) => {
  dispatch({ type: LOADING_VID });
};

export const loadingToken = () => (dispatch) => {
  dispatch({ type: LOADING_TOKEN });
};

export const loadingVideoStat = () => (dispatch) => {
  dispatch({ type: LOADING_VIDEO_STAT });
};

//Set Data
export const setStatVidReq = (request, unreview, accepted, decline) => (
  dispatch
) => {
  dispatch({
    type: FETCH_STAT_VID_REQ,
    payload: {
      request: request,
      unreview: unreview,
      accepted: accepted,
      decline: decline,
    },
  });
};

export const setStatVid = (size, acc, dec, dis) => (dispatch) => {
  dispatch({
    type: FETCH_STAT_VID,
    payload: {
      size: size,
      accepted: acc,
      decline: dec,
      distributor: dis,
    },
  });
};

export const setStatToken = (size, ava, used, dis) => (dispatch) => {
  dispatch({
    type: FETCH_STAT_TOKEN,
    payload: {
      size: size,
      avaliable: ava,
      used: used,
      disable: dis,
    },
  });
};

export const setVidReq = (video) => (dispatch) => {
  dispatch({ type: FETCH_VID_REQ, payload: video });
};

export const setVid = (video) => (dispatch) => {
  dispatch({ type: FETCH_VID, payload: video });
};

export const setToken = (token) => (dispatch) => {
  dispatch({ type: FETCH_TOKEN, payload: token });
};

export const setVideoStat = (stat) => (dispatch) => {
  dispatch({ type: FETCH_VIDEO_STAT, payload: stat });
};

//All
export const updateState = (arr) => (dispatch) => {
  dispatch({ type: UPDATE_STATE, payload: arr });
};

//Checked

export const setIdToken = (a) => (dispatch) => {
  dispatch({ type: SET_TOKEN_ID, payload: a });
};

export const setIdVid = (a) => (dispatch) => {
  dispatch({ type: SET_VID_ID, payload: a });
};

export const setIdVidStat = (a) => (dispatch) => {
  dispatch({ type: SET_VID_STAT_ID, payload: a });
};
