/** @jsx jsx */
import { useState } from "react";
import { css, jsx, keyframes } from "@emotion/core";
import { useMediaQuery } from "react-responsive";
import Slider from "../Slide/Slider";
import ContactText from "../Contact/ContactText";

const animation = keyframes`
from{
  left: 100px;
  opacity: 0;
}
to {
  left: 0;
  opacity: 1;
}
`;

const breakpoints = [1024, 768];

const mq = breakpoints.map(
  (bp) => `@media only screen and (max-width: ${bp}px)`
);

const New = ({ images }) => {
  const [state, setState] = useState({
    currentIndex: 0,
    transition: false,
  });

  const { currentIndex, transition } = state;
  const time = 500;

  const isCenter = useMediaQuery({
    query: "(max-width: 768px)",
  });

  const handleCurrentIndex = (currentIndex, nextIndex) => {
    setState({
      ...state,
      currentIndex: currentIndex,
      transition: true,
    });
    setTimeout(function () {
      setState({
        ...state,
        currentIndex: nextIndex,
        transition: false,
      });
    }, time);
  };

  return (
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        background: linear-gradient(
          99.6deg,
          rgba(255, 251, 159, 0.8) 4.49%,
          rgba(224, 144, 70, 0.8) 30.3%,
          rgba(234, 47, 47, 0.8) 58.31%,
          rgba(182, 13, 13, 0.8) 98.81%
        );
        box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.19);
        ${mq[1]} {
          flex-direction: column;
        }
      `}
    >
      <Slider images={images} handleCurrentIndex={handleCurrentIndex} />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          color: #fff;
          min-width: 40vw;
          max-width: 60vw;
          align-items: flex-start;
          ${mq[1]} {
            align-items: center;
          }
        `}
      >
        <h2
          css={css`
            font-size: 2.5em;
            font-weight: 600;
            transition: ${time / 2000}s;
            position: relative;
            left: ${transition ? "100px" : "0"};
            opacity: ${transition ? "0" : "1"};
            ${mq[0]} {
              font-size: 2em;
            }
            ${mq[1]} {
              font-size: 1.5em;
            }
          `}
        >
          {images[currentIndex].title}
        </h2>
        <ul
          css={css`
            font-size: 1.3em;
            font-weight: 400;
            list-style: none;
            ${mq[0]} {
              font-size: 1.1em;
            }
            ${mq[1]} {
              font-size: 1em;
            }
          `}
        >
          {images[currentIndex].points.map((c, i) => (
            <li
              key={i}
              css={css`
                position: relative;
                transition: calc(${time / 2000}s + ${i * 0.1}s);
                left: ${transition ? "100px" : "0"};
                opacity: ${transition ? "0" : "1"};
                animation: ${animation};
                animation-duration: calc(0.25s + ${i * 0.09}s);
                ::before {
                  content: "-";
                  position: absolute;
                  transform: translateX(-20px);
                  padding-right: 10px;
                  color: #fff;
                }
              `}
            >
              {c}
            </li>
          ))}
        </ul>
        <ContactText
          start={isCenter ? "center" : "start"}
          maxWidth={isCenter ? "90vw" : "40vw"}
          minWidth={isCenter ? "50vw" : "20vw"}
          Width={isCenter ? "70vw" : "30vw"}
        />
      </div>
    </div>
  );
};

export default New;
