/** @jsx jsx */
import { css, jsx } from "@emotion/core";
import DropDown from "./DropDown";
import DateInput from "./DateInput";
import OneLetterInput from "./oneLetterInput";
import { Fragment } from "react";

const Input = ({
  title,
  placeholder,
  error,
  type,
  list,
  width,
  zIndex,
  handleChange,
  alignTitle,
  alignAll,
  inputType,
  length,
  margin,
  theme,
  max,
  titleSize,
  titleColor,
  maxWidth,
  minWidth,
  maxHeight,
  minHeight,
  height,
  value,
  style,
}) => {
  const TitleCss = css`
    font-size: ${titleSize ? titleSize : `1.5em`};
    text-align: ${alignTitle ? alignTitle : "left"};
    color: ${titleColor ? titleColor : "inherit"};
  `;
  const textCss = css`
    padding: 10px 8px;
    border: 2px solid
      ${error ? `${theme === "light" ? "#FF7591" : "#af2239"}` : `#383b3f`};
    border-radius: 8px;
    width: ${width ? width : `25vw`};
    z-index: ${zIndex ? zIndex : "1"};
    font-size: 1em;
    margin-top: 1vh;
  `;
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        margin: ${margin ? margin : "2vh"};
        color: #383b3f;
        align-items: ${alignAll ? alignAll : "flex-start"};
        text-align: ${alignAll ? alignAll : "left"};
        ${alignAll === "center" &&
        `
        h2 {
          margin-bottom: 2vh;
        }
        `}
      `}
    >
      {type !== "area" ? (
        <Fragment>
          {title && <h2 css={TitleCss}>{title}</h2>}
          {type !== "DropDown" ? (
            type === "Date" ? (
              <DateInput
                zIndex={zIndex}
                theme={theme}
                error={error ? true : false}
                handleChange={handleChange}
                value={value}
                style={style}
              />
            ) : type === "oneLetterInput" ? (
              <OneLetterInput
                length={length}
                theme={theme}
                error={error ? true : false}
                handleChange={handleChange}
                value={value}
                style={style}
              />
            ) : (
              <input
                type={inputType ? inputType : "text"}
                min={0}
                max={max}
                onChange={(e) => handleChange(e.target.value)}
                placeholder={placeholder}
                css={textCss}
                value={value}
                style={style}
              />
            )
          ) : (
            <DropDown
              list={list}
              error={error ? true : false}
              width={width}
              zIndex={zIndex}
              theme={theme}
              placeholder={placeholder}
              handleChange={handleChange}
              value={value}
              style={style}
            />
          )}
        </Fragment>
      ) : (
        <Fragment>
          {title && <label css={TitleCss}>{title}</label>}
          <textarea
            placeholder={placeholder}
            onChange={(e) => handleChange(e.target.value)}
            value={value}
            css={css`
              ${textCss};
              min-width: ${minWidth ? minWidth : `${width ? width : `25vw`}`};
              max-width: ${maxWidth
                ? maxWidth
                : `${width ? `calc(${width} + 10vw)` : `40vw`}`};

              min-height: ${minHeight
                ? minHeight
                : `${height ? height : `calc(1em + 6px)`}`};
              max-height: ${maxHeight
                ? maxHeight
                : `${
                    height ? `calc(${height} + 10vh)` : `calc(1em + 6px + 10vh)`
                  }`};
              height: ${height ? height : `calc(1em + 6px)`};
            `}
          />
        </Fragment>
      )}
      <span
        css={css`
          font-size: 0.8em;
          color: ${theme === "light" ? "#FF7591" : "#af2239"};
          height: 1em;
          margin-top: 4px;
        `}
      >
        {error}
      </span>
    </div>
  );
};

export default Input;
