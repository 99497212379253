/** @jsx jsx */
import { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { css, jsx } from "@emotion/core";

import Section from "../../components/Dashboard/Section";
import WhiteDoc from "../../components/Dashboard/WhiteDoc";
import VideoUncheck from "../../components/Dashboard/VideoUncheck";

import { FlexRow, Splitter, numPerPage } from "./essentials";

import { connect } from "react-redux";
import {
  updateState,
  loadingVidReq,
  setVidReq,
} from "../../redux/actions/dbActions";

import { useMediaQuery } from "react-responsive";

import { withFirebase } from "../../components/Firebase";
import { compose } from "recompose";

// const VideoChoose = (
//   <div
//     css={css`
//       color: blue;
//       margin-left: 5vw;

//       button {
//         padding: 5px;
//         font-size: 1em;
//         border: 2px solid #383b3f;
//         background: transparent;
//         border-radius: 0 5px 5px 0;
//         transition: 0.2s;
//       }

//       button:first-of-type {
//         padding-right: 7px;
//         transform: translateX(2px);
//         border-radius: 5px 0 0 5px;
//       }

//       button:hover {
//         background: #f3f5f9;
//       }

//       button:active {
//         background: #f3f5f9;
//         transform: scale(0.9) translateX(-3px);
//       }

//       button:first-of-type:active {
//         background: #f3f5f9;
//         transform: scale(0.9) translateX(5px);
//       }
//     `}
//   >
//     <button>Latest</button>
//     <button>Oldest</button>
//   </div>
// );

const VidReqWD = ({
  videoReqUnreview,
  mobileClick,
  mobile,
  videoDisplay,
  videoReqP,
  handleChooseVR,
  handleBackPageVR,
  handleNextPageVR,
}) => {
  const Responsive = useMediaQuery({
    query: "(max-width: 1440px)",
  });

  const Responsive2 = useMediaQuery({
    query: "(max-width: 425px)",
  });

  return (
    <WhiteDoc
      // rightside={VideoChoose}
      title="Video Requests"
      subtitle={`${videoReqUnreview} new videos are queueing`}
      styleContent={{
        minHeight: "20vw",
      }}
      minWidth="45vw"
      mobile={mobile}
      mobileClick={mobileClick}
    >
      {videoDisplay.length === 0 ? (
        <div
          css={css`
            width: calc(100% + 4vw);
          `}
        >
          Yey! No Video Request
        </div>
      ) : (
        <Fragment>
          <VideoUncheck
            videos={videoDisplay}
            handleChoose={handleChooseVR}
            mobile={mobile}
            Responsive={Responsive}
            Responsive2={Responsive2}
          />

          <div css={Splitter} />
          <div css={FlexRow}>
            <div
              css={css`
                flex: 1;
              `}
            />
            <div
              css={css`
                ${FlexRow};
                align-items: center;
              `}
            >
              <svg
                tabIndex={0}
                role="button"
                onClick={handleBackPageVR}
                viewBox="0 0 5 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                css={css`
                  height: 0.8em;
                  width: 0.8em;
                `}
              >
                <path
                  d="M0.708837 3.745L3.0655 0.449167C3.26384 0.169167 3.58467 0 3.92884 0C4.798 0 5.29967 0.98 4.798 1.68583L3.08884 4.08333L4.798 6.48084C5.3055 7.18667 4.798 8.16667 3.92884 8.16667C3.58467 8.16667 3.258 7.9975 3.05967 7.7175L0.703003 4.42167C0.563004 4.2175 0.563004 3.94917 0.708837 3.745Z"
                  fill="#535353"
                />
              </svg>
              <span
                css={css`
                  margin: 0 16px;
                `}
              >
                {videoReqP}/
                {Math.ceil(videoReqUnreview / numPerPage) === 0
                  ? "1"
                  : Math.ceil(videoReqUnreview / numPerPage)}
              </span>
              <svg
                tabIndex={0}
                role="button"
                onClick={handleNextPageVR}
                viewBox="0 0 5 9"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                css={css`
                  height: 0.8em;
                  width: 0.8em;
                `}
              >
                <path
                  d="M4.29116 3.745L1.9345 0.449167C1.73616 0.169167 1.41533 0 1.07116 0C0.201999 0 -0.299668 0.98 0.201998 1.68583L1.91116 4.08333L0.201998 6.48084C-0.305502 7.18667 0.201999 8.16667 1.07116 8.16667C1.41533 8.16667 1.742 7.9975 1.94033 7.7175L4.297 4.42167C4.437 4.2175 4.437 3.94917 4.29116 3.745Z"
                  fill="#535353"
                />
              </svg>
            </div>
          </div>
        </Fragment>
      )}
    </WhiteDoc>
  );
};

class VideoReq extends Component {
  state = {
    videoReqP: 1,
  };

  handleChooseVR = (id, a, t) => {
    var arr = {};
    var array = [...this.props.db.videoReqs];
    var array2 = [...this.props.db.videos];
    var i = array.findIndex((c) => c.id === id);
    array2.unshift({
      ...array[i],
      aprove: a,
      CreatedAt: t,
    });
    array.splice(i, 1);
    arr = {
      videoReqs: array,
      videos: array2,
      videoReqUnreview: this.props.db.videoReqUnreview - 1,
      videoAll: this.props.db.videoAll + 1,
    };
    if (a) {
      arr = {
        ...arr,
        videoReqAccepted: this.props.db.videoReqAccepted + 1,
        videoAccept: this.props.db.videoAccept + 1,
      };
    } else {
      arr = {
        ...arr,
        videoReqDecline: this.props.db.videoReqDecline + 1,
        videoDecline: this.props.db.videoDecline + 1,
      };
    }
    this.props.updateState(arr);
  };

  handleBackPageVR = () => {
    if (this.state.videoReqP !== 1) {
      this.setState({
        videoReqP: this.state.videoReqP - 1,
      });
    }
  };

  handleNextPageVR = () => {
    const { VideoReqs, videoReqUnreview } = this.props.db;
    const { videoReqP } = this.state;
    if (videoReqP < Math.ceil(videoReqUnreview / numPerPage)) {
      var n = VideoReqs.length;
      if (n / numPerPage < videoReqP + 1 && n !== videoReqUnreview) {
        this.fetchMoreDataVR(numPerPage - (n % numPerPage));
      }
      this.setState({
        videoReqP: videoReqP + 1,
      });
    }
  };

  fetchMoreDataVR = (n) => {
    const { VideoReqs } = this.props.db;
    const lastVisible = VideoReqs[VideoReqs.length - 1];
    this.props.loadingVidReq();
    this.props.firebase
      .videoReqRef()
      .orderBy("CreatedAt", "asc")
      .orderBy("title", "asc")
      .startAfter(lastVisible.CreatedAt, lastVisible.title)
      .limit(n)
      .get()
      .then((data) => {
        let video = VideoReqs;
        data.forEach((doc) => {
          video.push({
            id: doc.id,
            ...doc.data(),
          });
        });
        this.props.setVidReq(video);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  render() {
    const {
      db: { videoReqs, videoReqUnreview },
      mobile,
      mobileClick,
    } = this.props;
    const { videoReqP } = this.state;

    const videoDisplay = videoReqs
      .sort((a, b) => {
        return a.token < b.token ? -1 : a.token > b.token ? 1 : 0;
      })
      .sort((a, b) => {
        return a.title < b.title ? -1 : a.title > b.title ? 1 : 0;
      })
      .sort((a, b) => {
        return a.CreatedAt > b.CreatedAt
          ? -1
          : a.CreatedAt < b.CreatedAt
          ? 1
          : 0;
      })
      .filter((_, i) => i >= (videoReqP - 1) * numPerPage)
      .filter((_, i) => i <= numPerPage - 1 + (videoReqP - 1) * numPerPage);

    const WD = (
      <VidReqWD
        videoReqUnreview={videoReqUnreview}
        mobileClick={mobileClick}
        mobile={mobile}
        videoDisplay={videoDisplay}
        videoReqP={videoReqP}
        handleChooseVR={this.handleChooseVR}
        handleBackPageVR={this.handleBackPageVR}
        handleNextPageVR={this.handleNextPageVR}
      />
    );
    return !mobile ? (
      <Section title="Video Requests" style={{ width: `calc(100% - 2vw)` }}>
        {WD}
      </Section>
    ) : (
      WD
    );
  }
}

VideoReq.propTypes = {
  loadingVidReq: PropTypes.func.isRequired,
  setVidReq: PropTypes.func.isRequired,
  updateState: PropTypes.func.isRequired,
  db: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  db: state.db,
});

export default compose(
  withFirebase,
  connect(mapStateToProps, {
    updateState,
    loadingVidReq,
    setVidReq,
  })
)(VideoReq);
