import React, { Component } from "react";
import PropTypes from "prop-types";
import "../css/login.css";
import Input from "../components/Input";

import { withFirebase } from "../components/Firebase";

import { compose } from "recompose";

import { connect } from "react-redux";
import { setFooter, resetNavbar } from "../redux/actions/dataActions";

import MediaQuery from "react-responsive";

const INITIAL_STATE = {
  email: "",
  password: "",
  error: {
    email: "",
    password: "",
  },
  loading: false,
};

const isEmail = (email) => {
  const regEx = /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  if (email.match(regEx)) return true;
  else return false;
};

const isEmpty = (string) => {
  if (string.trim() === "") return true;
  else return false;
};

class login extends Component {
  state = INITIAL_STATE;

  componentDidMount() {
    this.props.resetNavbar();
    this.props.setFooter(false);
  }

  handleChangeEmail = (e) => {
    this.setState({
      email: e,
    });
  };

  handleChangePassword = (e) => {
    this.setState({
      password: e,
    });
  };

  handleLoading = (e) => {
    this.setState({
      loading: e,
    });
  };

  handleLogin = () => {
    this.handleLoading(true);
    let n = 0;
    let error = {
      email: "",
      password: "",
    };
    const { email, password } = this.state;
    if (!isEmail(email)) {
      error.email = "it's not a valid email address";
      n++;
    }
    if (isEmpty(password)) {
      error.password = "Password is empty";
      n++;
    }
    if (n === 0) {
      this.props.firebase
        .doSignInWithEmailAndPassword(email, password)
        .then((user) => {
          console.log(user);
          this.setState(INITIAL_STATE);
          this.handleLoading(false);
          this.props.history.push("/admin/video");
        })
        .catch((err) => {
          console.error(err);
          this.setState({
            error: {
              email: "There's something wrong, Please try again",
              password: "There's something wrong, Please try again",
            },
          });
          this.handleLoading(false);
        });
    } else {
      this.setState({
        error: error,
      });
      this.handleLoading(false);
    }
  };

  render() {
    const { error, loading } = this.state;
    return (
      <div className="LoginWrapper">
        <div className="LoginContent">
          <div className="LoginTitle">ADMIN LOGIN</div>
          <MediaQuery query="(min-width: 1026px)">
            <Input
              placeholder="Email"
              inputType="email"
              margin="2vh 0 0 0"
              error={error.email}
              theme="light"
              handleChange={this.handleChangeEmail}
            />
            <Input
              placeholder="Password"
              inputType="password"
              margin="2vh 0 0 0"
              error={error.password}
              theme="light"
              handleChange={this.handleChangePassword}
            />
          </MediaQuery>
          <MediaQuery query="(max-width: 1025px)">
            <Input
              placeholder="Email"
              inputType="email"
              margin="2vh 0 0 0"
              error={error.email}
              theme="light"
              handleChange={this.handleChangeEmail}
              width="65vw"
            />
            <Input
              placeholder="Password"
              inputType="password"
              margin="2vh 0 0 0"
              error={error.password}
              theme="light"
              handleChange={this.handleChangePassword}
              width="65vw"
            />
          </MediaQuery>
          <button
            className="LoginSumbitButton"
            onClick={this.handleLogin}
            disabled={loading}
          >
            LOGIN
          </button>
        </div>
      </div>
    );
  }
}

login.protoType = {
  setFooter: PropTypes.func.isRequired,
  setNavbar: PropTypes.func.isRequired,
};

export default compose(
  connect(null, { setFooter, resetNavbar }),
  withFirebase
)(login);
