import app from "firebase/app";
import "firebase/auth";
import "firebase/firestore";

const config = {
  apiKey: "AIzaSyCTI5fSjPrWbjRHtqzEBe97kIKPbNMsMr0",
  authDomain: "kurniasindo.firebaseapp.com",
  databaseURL: "https://kurniasindo.firebaseio.com",
  projectId: "kurniasindo",
  storageBucket: "kurniasindo.appspot.com",
  messagingSenderId: "1018175009000",
  appId: "1:1018175009000:web:13fe066f2f35937ad4595d",
  measurementId: "G-PRMPV6CR9R",
};

// config jangan di sebar ini :v

class Firebase {
  constructor() {
    app.initializeApp(config);

    this.auth = app.auth();
    this.db = app.firestore();
  }

  // --- Auth API ---

  // doCreateUserWithEmailAndPassword = (email, password) =>
  //   this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);

  // doPasswordUpdate = (password) =>
  //   this.auth.currentUser.updatePassword(password);

  // --- Collection Ref ---
  applyRef = () => this.db.collection("Apply");
  jobRef = () => this.db.collection("Job");
  videoReqRef = () => this.db.collection("VideoReq");
  videoRef = () => this.db.collection("Video");
  tokenRef = () => this.db.collection("Token");
  StatRef = () => this.db.collection("Statistics");
  videoStatRef = () => this.db.collection("VideoStat");
  batch = () => this.db.batch();
}

export default Firebase;
